import axios from 'axios';
import secureStorage from '../utils/SecureStorage';

// Cria uma instância do Axios com a URL base da API e configurações padrão
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 20000, // 20 segundos
  headers: {
    'Content-Type': 'application/json' // Garante que todas as requisições enviem o header Content-Type como application/json
  }
});

// Interceptador de requisições para adicionar o token de autenticação no header e logar a requisição
api.interceptors.request.use(
  config => {
    // Tenta obter o token de autenticação do armazenamento local
    const token = secureStorage.getItem('authToken'); // Substitua 'authToken' pela chave onde seu token é armazenado
    if (token) {
      // Se o token existe, adiciona-o ao header Authorization
      config.headers['Authorization'] = `Bearer ${token}`;
      
    } else {
      
    }
    
    return config;
  },
  error => {
    console.error('Erro ao adicionar o token à requisição:', error); // Log de erro ao adicionar o token
    return Promise.reject(error);
  }
);

// Interceptador de respostas para tratar erros globalmente
api.interceptors.response.use(
  response => {
    // Qualquer status code dentro do intervalo 2xx causa esta função a ser acionada
    // Aqui você pode tratar a resposta como desejar
    return response;
  },
  error => {
    // Qualquer status code fora do intervalo 2xx causa esta função a ser acionada
    if (error.response) {
      // Você pode tratar diferentes códigos de status aqui
      switch (error.response.status) {
        case 400:
          alert('Solicitação inválida.');
          break;
        case 401:
          alert(error.response.data.message || 'Token não está autenticado.');
          window.location.href = '/';
          break;
        case 403:
          alert('Você não tem permissão para fazer isso.');
          break;
        case 404:
          alert('Recurso não encontrado.');
          break;
        case 500:
          alert(error.response.data.message  || 'Erro interno do servidor.');
          break;
        default:
          // Exibir uma mensagem de erro genérica
          alert('Houve um erro ao processar a sua solicitação.');
      }
    } else {
      // Tratamento para erros que não são resposta de uma requisição HTTP (por exemplo, problemas de rede)
      alert('Verifique sua conexão com a internet e tente novamente.');
    }
    console.error('Erro na resposta da requisição:', error); // Log do erro na resposta
    return Promise.reject(error);
  }
);

export default api;
