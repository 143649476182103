import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BuscarLicenca from '../../services/Licenca/BuscarLicenca';
import LicencaService from '../../services/Licenca/LicencaService';
import BuscaRedes from '../../services/Marketdo/BuscaRedes';

function CadastroLicenca() {
    const [redes, setRedes] = useState([]);
    const [redeSelecionada, setRedeSelecionada] = useState('');
    const [licenca, setLicenca] = useState({
        id_rede: '',
        _id: '',
        count_user_app: '',
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [licencasBusca, setLicencasBusca] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate();

    // Carrega as redes ao montar o componente
    useEffect(() => {
        const fetchRedes = async () => {
            try {
                const buscaRedes = new BuscaRedes();
                await buscaRedes.loadRede();
                const redesResultado = buscaRedes.getResultadoRede();
                setRedes(redesResultado);
            } catch (error) {
                console.error('Erro ao buscar redes:', error);
            }
        };

        fetchRedes();
    }, []);

    // Atualiza os dados da licença ao selecionar uma rede
    const handleSelectRede = async (e) => {
        const selectedId = e.target.value;
        setRedeSelecionada(selectedId);

        if (!selectedId) {
            setLicenca({ id_rede: '', _id: '', count_user_app: '' });
            return;
        }

        setLicenca((prevState) => ({
            ...prevState,
            id_rede: selectedId,
        }));

        try {
            const buscarLicenca = new BuscarLicenca();
            await buscarLicenca.loadLicenca(selectedId);
            const resultado = buscarLicenca.getResultadoLicenca();

            if (resultado) {
                setLicenca({
                    id_rede: selectedId,
                    _id: resultado._id?.$oid || resultado._id,
                    count_user_app: resultado.count_user_app || '',
                });
            } else {
                setLicenca({
                    id_rede: selectedId,
                    _id: '',
                    count_user_app: '',
                });
            }
        } catch (error) {
            console.error('Erro ao buscar licença:', error);
        }
    };

    // Abre o modal e carrega as licenças com os nomes das redes
    const handleOpenModal = async () => {
        setIsModalOpen(true);

        try {
            const licencas = await BuscarLicenca();
            const licencasComNome = licencas.map((licenca) => {
                const rede = redes.find((r) => r._id.$oid === licenca.id_rede || r._id === licenca.id_rede);
                return {
                    ...licenca,
                    nome_fantasia: rede ? rede.nome_fantasia : 'Rede Não Encontrada',
                };
            });
            setLicencasBusca(licencasComNome);
        } catch (error) {
            console.error('Erro ao buscar licenças:', error);
            alert('Erro ao carregar as licenças. Verifique o console para mais detalhes.');
            setLicencasBusca([]);
        }
    };

    // Fecha o modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSearchTerm('');
    };

    // Seleciona uma licença no modal e preenche o formulário principal
    const handleSelectLicenca = (licencaSelecionada) => {
        setLicenca({
            id_rede: licencaSelecionada.id_rede,
            _id: licencaSelecionada._id?.$oid || licencaSelecionada._id,
            count_user_app: licencaSelecionada.count_user_app,
        });
        setRedeSelecionada(licencaSelecionada.id_rede);
        setIsModalOpen(false);
    };

    // Salva ou atualiza a licença
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!redeSelecionada || !licenca.id_rede) {
            alert('Por favor, selecione uma rede antes de salvar.');
            return;
        }

        if (!licenca.count_user_app || parseInt(licenca.count_user_app, 10) <= 0) {
            alert('Por favor, insira um valor válido para a licença.');
            return;
        }

        try {
            if (licenca._id) {
                await LicencaService.updateLicenca(licenca._id, licenca);
                alert('Licença atualizada com sucesso!');
            } else {
                await LicencaService.saveLicenca(licenca);
                alert('Licença criada com sucesso!');
            }

            setLicenca({ id_rede: '', _id: '', count_user_app: '' });
            setRedeSelecionada('');
        } catch (error) {
            console.error('Erro ao salvar ou atualizar licença:', error);
            alert('Erro ao salvar ou atualizar licença.');
        }
    };

    return (
        <>
            <Header />
            <S.BackButton onClick={() => navigate('/Marketdo')}>
                <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
                Voltar
            </S.BackButton>
            <S.Container>
                <S.Form onSubmit={handleSubmit}>
                    <S.Title>Cadastro de Licença</S.Title>

                    <S.InputGroup>
                        <S.Label>Selecione uma Rede:</S.Label>
                        <S.Select value={redeSelecionada} onChange={handleSelectRede}>
                            <option value="">Selecione...</option>
                            {redes.map((rede) => (
                                <option key={rede._id.$oid || rede._id} value={rede._id.$oid || rede._id}>
                                    {rede.nome_fantasia}
                                </option>
                            ))}
                        </S.Select>
                    </S.InputGroup>

                    <S.InputGroup>
                        <S.Label>Licença :</S.Label>
                        <S.NumberInput
                            type="number"
                            value={licenca.count_user_app}
                            onChange={(e) =>
                                setLicenca({ ...licenca, count_user_app: e.target.value })
                            }
                        />
                    </S.InputGroup>

                    <S.ButtonGroup>
                        <S.Button type="submit">Salvar</S.Button>
                        <S.Button
                            type="button"
                            onClick={() => setLicenca({ id_rede: '', _id: '', count_user_app: '' })}
                        >
                            Limpar
                        </S.Button>
                        <S.Button type="button" onClick={handleOpenModal}>Buscar Licença</S.Button>
                    </S.ButtonGroup>
                </S.Form>
            </S.Container>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                ariaHideApp={false}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxHeight: '70vh',
                        overflowY: 'auto',
                    },
                }}
            >
                <S.ModalHeader>
                    <h2>Buscar Licença</h2>
                    <button onClick={handleCloseModal}>Fechar</button>
                </S.ModalHeader>
                <S.SearchInput
                    type="text"
                    placeholder="Digite o termo de busca..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <S.TableContainer>
                    <S.Table>
                        <thead>
                            <tr>
                                <th>Rede</th>
                                <th>Licença</th>
                            </tr>
                        </thead>
                        <tbody>
                            {licencasBusca
                                .filter((licenca) =>
                                    licenca.nome_fantasia.toLowerCase().includes(searchTerm.toLowerCase())
                                )
                                .map((licenca) => (
                                    <tr
                                        key={licenca._id}
                                        onClick={() => handleSelectLicenca(licenca)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <td>{licenca.nome_fantasia}</td>
                                        <td>{licenca.count_user_app}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </S.Table>
                </S.TableContainer>
            </Modal>
            <Footer />
        </>
    );
}

export default CadastroLicenca;
