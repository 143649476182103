import api from '../api';

const apiUrl = process.env.REACT_APP_API_BASE_45;
const apiUrl2 = process.env.REACT_APP_API_BASE_29;

// Função para criar uma nova rede (POST)
const saveRede = async (rede) => {
  try {
    const response = await api.post(apiUrl, rede,);

    return response.data; // Retorna os dados do backend
  } catch (error) {
    console.error('Erro ao salvar a rede:', error.response?.data || error.message);
    throw new Error(error.response?.data?.error || 'Erro ao salvar a rede');
  }
};

// Função para atualizar uma rede existente (PUT)
const updateRede = async (id, rede) => {
  try {
    const response = await api.put(`${apiUrl2}/${id}`, rede, );

    return response.data; // Retorna os dados do backend
  } catch (error) {
    console.error('Erro ao atualizar a rede:', error.response?.data || error.message);
    throw new Error(error.response?.data?.error || 'Erro ao atualizar a rede');
  }
};

export default { saveRede, updateRede };
