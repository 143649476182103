import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { FaCheckCircle, FaTimesCircle, FaMapMarkerAlt, FaPlus, FaTrash } from 'react-icons/fa';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BuscaPonto from '../../services/ponto/BuscaPonto';
import BuscaPontoMesAterior from '../../services/ponto/BuscaPontoMesAnterior';
import PontoService from '../../services/ponto/PontoService';
import RemoverPonto from '../../services/ponto/RemoverPontoService';
import FechamentoPonto from '../../services/ponto/FechamentoPonto';
import secureStorage from '../../utils/SecureStorage';
import GeraCsvPonto from '../../components/Exportar/GeraCsvPonto';
import GeraExcelPonto from '../../components/Exportar/GeraExcelPonto';
import GeraPdfPonto from '../../components/Exportar/GeraPdfPonto';
import { useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import Modal from 'react-modal';
import { format } from 'date-fns';

function ChangeMapView({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());
    return null;
}

Modal.setAppElement('#root');

function GestaoPonto() {
    const [colaboradores, setColaboradores] = useState([]);
    const [colaboradorAtivo, setColaboradorAtivo] = useState(null);
    const [localizacaoAtiva, setLocalizacaoAtiva] = useState(null);
    const [colaboradoresUnicos, setColaboradoresUnicos] = useState([]);
    const { loadregistros } = BuscaPonto([]);
    const { loadregistros: loadRegistrosMesAnterior } = BuscaPontoMesAterior();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalAjusteIsOpen, setModalAjusteIsOpen] = useState(false);
    const [registroSelecionado, setRegistroSelecionado] = useState(null);
    const [mostrarBotoes, setMostrarBotoes] = useState(false);
    const [modalAbonarIsOpen, setModalAbonarIsOpen] = useState(false);
    const [registroAbonar, setRegistroAbonar] = useState(null);
    const navigate = useNavigate();

    const loadregistrosponto = async () => {
        const id_rede = secureStorage.getItem("id_r");
        if (id_rede) {
            const error = await loadregistros(id_rede);
            if (error) {
                console.error("Erro ao carregar registros:", error);
            } else if (secureStorage.getItem("dataponto")) {
                let dataUser = secureStorage.getItem("dataponto");
                if (dataUser) {
                    dataUser = JSON.parse(dataUser);
                    const colaboradores = Object.keys(dataUser).map((colaborador) => ({
                        colaborador,
                        registros: dataUser[colaborador].registros,
                        total_horas_trabalhadas: dataUser[colaborador].total_horas_trabalhadas,
                        total_horas_extras: dataUser[colaborador].total_horas_extras,
                        total_atrasos: dataUser[colaborador].total_atrasos,
                        saldo_banco_horas: dataUser[colaborador].saldo_banco_horas,
                        id_colaborador: dataUser[colaborador].registros[0]?.id_colaborador,
                        empresa: dataUser[colaborador].empresa
                    }));

                    setColaboradores(colaboradores);
                    setColaboradoresUnicos(Object.keys(dataUser));

                    setColaboradorAtivo(prev => {
                        if (!prev || prev.colaborador !== colaboradores[0]?.colaborador) {
                            return colaboradores[0];
                        }
                        return prev;
                    });
                }
            }
        }
    };

    useEffect(() => {
        loadregistrosponto();
    }, []);

    const abrirMapa = (localizacao) => {
        if (localizacao.latitude && localizacao.longitude) {
            setLocalizacaoAtiva(localizacao);
            setModalIsOpen(true);
        } else {
            alert("Localização inválida. Não é possível abrir o mapa.");
        }
    };

    const fecharMapa = () => {
        setLocalizacaoAtiva(null);
        setModalIsOpen(false);
    };

    const abrirAjusteManual = (registro) => {
        setRegistroSelecionado(registro);
        setModalAjusteIsOpen(true);
    };

    const fecharAjusteManual = () => {
        setRegistroSelecionado(null);
        setModalAjusteIsOpen(false);
    };

    const salvarAjuste = async () => {
        const horario = document.getElementById('horarioBatida').value;

        if (!horario || horario === "--:--") {
            alert("Por favor, insira um horário válido antes de salvar.");
            return;
        }

        if (registroSelecionado) {
            const novaBatida = {
                timestamp: new Date(`${registroSelecionado.data}T${horario}:00`).toISOString(),
                localizacao: { latitude: 0, longitude: 0, precisao: 0 },
                Registromanual: new Date().toISOString()
            };

            const batidasAtualizadas = [...registroSelecionado.batidas, novaBatida].sort(
                (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
            );

            const clienteData = {
                id_rede: secureStorage.getItem("id_r"),
                empresa: colaboradorAtivo?.registros?.[0]?.empresa || "empresa_exemplo",
                colaborador: colaboradorAtivo?.colaborador,
                id_colaborador: colaboradorAtivo?.registros?.[0]?.id_colaborador,
                Processado: false,
                Solicitacao: false,
                Feriado: false,
                Ferias: false,
                data: registroSelecionado.data,
                batidas: batidasAtualizadas,
            };

            try {
                await PontoService.saveponto(clienteData);
                alert("Ajuste salvo com sucesso!");

                const registrosAtualizados = colaboradores.map((colaborador) => {
                    if (colaborador.colaborador === colaboradorAtivo?.colaborador) {
                        const registros = colaborador.registros.map((reg) => {
                            if (reg.data === registroSelecionado.data) {
                                return { ...reg, batidas: batidasAtualizadas };
                            }
                            return reg;
                        });
                        return { ...colaborador, registros };
                    }
                    return colaborador;
                });

                setColaboradores(registrosAtualizados);
                fecharAjusteManual();
                loadregistrosponto();
            } catch (error) {
                console.error("Erro ao salvar o ajuste:", error);
                alert("Erro ao salvar o ajuste. Tente novamente.");
            }
        }
    };

    useEffect(() => {
        if (colaboradorAtivo) {
           
        }
    }, [colaboradorAtivo]);


    const removerBatida = (registro, batida) => {
        if (!registro || !batida) {
            console.error("Erro: registro ou batida inválidos.", { registro, batida });
            alert("Erro: registro ou batida inválidos.");
            return;
        }

        const confirmarRemocao = window.confirm("Você realmente deseja remover este registro?");

        if (confirmarRemocao) {
            removerBatidaPonto(registro, batida);
        }
    };

    const removerBatidaPonto = async (registro, batida) => {
        if (!registro) {
            console.error("Erro: registro não fornecido.", registro);
            alert("Erro: registro não fornecido.");
            return;
        }

        if (!batida || !batida.timestamp) {
            console.error("Erro: batida inválida ou timestamp não encontrado.", batida);
            alert("Erro: batida inválida ou timestamp não encontrado.");
            return;
        }

        const batidasAtualizadas = registro.batidas.filter(b => b.timestamp !== batida.timestamp);

        const registrosAtualizados = colaboradores.map(colaborador => {
            if (colaborador.colaborador === colaboradorAtivo?.colaborador) {
                return {
                    ...colaborador,
                    registros: colaborador.registros.map(reg => {
                        if (reg.data === registro.data) {
                            return { ...reg, batidas: batidasAtualizadas };
                        }
                        return reg;
                    })
                };
            }
            return colaborador;
        });

        setColaboradores(registrosAtualizados);
        setColaboradorAtivo({
            ...colaboradorAtivo,
            registros: registrosAtualizados.find(c => c.colaborador === colaboradorAtivo?.colaborador)?.registros
        });



        try {
            const clienteData = {
                id_rede: secureStorage.getItem("id_r"),
                empresa: colaboradorAtivo?.registros?.[0]?.empresa || "empresa_exemplo",
                colaborador: colaboradorAtivo?.colaborador,
                id_colaborador: colaboradorAtivo?.registros?.[0]?.id_colaborador,
                data: registro.data,
                Processado: false,
                Solicitacao: false,
                Feriado: false,
                Ferias: false,
                Atraso: "00:00",
                Total_de_Horas: "00:00",
                Hora_Extra: "00:00",
                batidas: batidasAtualizadas,
                timestamp_removido: batida.timestamp
            };

            const resposta = await RemoverPonto.Removerponto(clienteData);
           
            alert("Batida removida com sucesso!");
        } catch (error) {
            console.error("Erro ao remover a batida:", error);
            alert("Erro ao remover a batida. Tente novamente.");
        }
    };

    const defaultIcon = L.icon({
        iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
        shadowSize: [41, 41],
    });

    const getDiaDaSemana = (data) => {
        const diasDaSemana = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
        const date = new Date(data + 'T00:00:00');
        return isNaN(date.getTime()) ? '-' : diasDaSemana[date.getDay()];
    };

    // Verifica se a data está entre os dias 1 e 5 do mês
    useEffect(() => {
        const hoje = new Date();
        const dia = hoje.getDate();
        setMostrarBotoes(dia >= 1 && dia <= 6); // Apenas do dia 1 ao 5
    }, []);

    // função para os botões
    const handleRegistroMesAnterior = async () => {
        const id_rede = secureStorage.getItem("id_r");
        if (id_rede) {
            try {
                await loadRegistrosMesAnterior(id_rede);
                const dataUser = secureStorage.getItem("dataponto");
                if (dataUser) {
                    const parsedData = JSON.parse(dataUser);
                    const colaboradores = Object.keys(parsedData).map((colaborador) => ({
                        colaborador,
                        registros: parsedData[colaborador].registros,
                        total_horas_trabalhadas: parsedData[colaborador].total_horas_trabalhadas,
                        total_horas_extras: parsedData[colaborador].total_horas_extras,
                        total_atrasos: parsedData[colaborador].total_atrasos,
                        saldo_banco_horas: parsedData[colaborador].saldo_banco_horas,
                        id_colaborador: parsedData[colaborador].registros[0]?.id_colaborador,
                        empresa: parsedData[colaborador].empresa,
                    }));

                    setColaboradores(colaboradores);
                    setColaboradorAtivo(colaboradores[0] || null);
                    alert("Registros do mês anterior carregados com sucesso!");
                } else {
                    alert("Nenhum dado encontrado para o mês anterior.");
                }
            } catch (error) {
                console.error("Erro ao carregar registros do mês anterior:", error);
                alert("Erro ao carregar registros do mês anterior. Veja o console para mais detalhes.");
            }
        } else {
            alert("ID da rede não encontrado.");
        }
    };

    const handleFecharMesAnterior = async () => {

        const idRede = secureStorage.getItem("id_r");

        try {
            alert("Iniciando o fechamento do mês anterior...");
            await FechamentoPonto(idRede);
        } catch (error) {
            console.error("Erro ao fechar o mês anterior:", error);
            alert("Erro ao realizar o fechamento. Verifique os logs para mais detalhes.");
        }
    };


    // Abre o modal
    const abrirModalAbonar = (registro) => {
        setRegistroAbonar(registro);
        setModalAbonarIsOpen(true);
    };

    // Fecha o modal
    const fecharModalAbonar = () => {
        setRegistroAbonar(null);
        setModalAbonarIsOpen(false);
    };

    // Salva o abono (feriado ou férias)
    const salvarAbono = async (tipo) => {
        if (!registroAbonar) return;

        // Garante que batidas seja um array
        const batidasExistentes = Array.isArray(registroAbonar.batidas) ? registroAbonar.batidas : [];

        // Cria 4 batidas vazias
        const criarBatida = (horario) => ({
            timestamp: new Date(`${registroAbonar.data}T${horario}:00`).toISOString(),
            localizacao: { latitude: 0, longitude: 0, precisao: 0 },
            Registromanual: new Date().toISOString(),
        });

        const horarios = ["00:00", "00:00", "00:00", "00:00"];
        const novasBatidas = horarios.map((horario) => criarBatida(horario));

        // Atualiza as batidas
        const batidasAtualizadas = [...batidasExistentes, ...novasBatidas].sort(
            (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        );

        const clienteData = {
            id_rede: secureStorage.getItem("id_r"),
            empresa: colaboradorAtivo?.registros?.[0]?.empresa || "empresa_exemplo",
            colaborador: colaboradorAtivo?.colaborador,
            id_colaborador: colaboradorAtivo?.registros?.[0]?.id_colaborador,
            data: registroAbonar.data,
            Processado: true,
            Solicitacao: false,
            Atraso: "00:00",
            Total_de_Horas: "00:00",
            Hora_Extra: "00:00",
            Feriado: tipo === "feriado" ? true : false,
            Ferias: tipo === "ferias" ? true : false,
            batidas: batidasAtualizadas,
        };

        try {
            await PontoService.saveponto(clienteData);
            alert(`${tipo === "feriado" ? "Feriado" : "Férias"} registrado com sucesso!`);

            const registrosAtualizados = colaboradores.map((colaborador) => {
                if (colaborador.colaborador === colaboradorAtivo?.colaborador) {
                    const registros = colaborador.registros.map((reg) => {
                        if (reg.data === registroAbonar.data) {
                            return { ...reg, batidas: batidasAtualizadas };
                        }
                        return reg;
                    });
                    return { ...colaborador, registros };
                }
                return colaborador;
            });

            setColaboradores(registrosAtualizados);
            fecharModalAbonar();
            loadregistrosponto();
        } catch (error) {
            console.error("Erro ao salvar o abono:", error);
            alert("Erro ao salvar o abono. Tente novamente.");
        }
    };



    return (
        <S.Container>
            <Header />
            <S.BackButton onClick={() => navigate('/home')}>
                <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
                Voltar
            </S.BackButton>
            <S.Content>
                {/* Campo de seleção de colaborador */}
                <S.SelectContainer>
                    {/* Seletor centralizado */}
                    <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                        <label htmlFor="colaborador-select" style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                            Selecionar Colaborador
                        </label>
                        <S.Select
                            id="colaborador-select"
                            value={colaboradorAtivo?.colaborador || ""}
                            onChange={(e) => {
                                const colaboradorSelecionado = colaboradores.find(c => c.colaborador === e.target.value);
                                if (colaboradorSelecionado) {
                                    setColaboradorAtivo(colaboradorSelecionado);
                                }
                            }}
                        >
                            <option value="" disabled>
                                Escolha um colaborador
                            </option>
                            {colaboradores.map((colaborador, index) => (
                                <option key={index} value={colaborador.colaborador}>
                                    {colaborador.colaborador}
                                </option>
                            ))}
                        </S.Select>
                    </div>

                    {/* Botões no lado esquerdo */}
                    <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-start' }}>
                        {mostrarBotoes && (
                            <>
                                <S.Button onClick={handleRegistroMesAnterior}>
                                    Registro Mês Anterior
                                </S.Button>
                                <S.Button onClick={handleFecharMesAnterior}>
                                    Fechar Mês Anterior
                                </S.Button>
                            </>
                        )}
                    </div>
                </S.SelectContainer>




                <S.SaldoTotal style={{ color: colaboradorAtivo?.saldo_banco_horas >= 0 ? '#4CAF50' : colaboradorAtivo?.saldo_banco_horas < 0 ? '#F44336' : 'black' }}>
                    <div>Total de horas: {colaboradorAtivo?.total_horas_trabalhadas || '00:00'}</div>
                    <div>Horas Extras: {colaboradorAtivo?.total_horas_extras || '00:00'}</div>
                    <div>Atraso: {colaboradorAtivo?.total_atrasos || '00:00'}</div>
                    <div>Banco Horas: {colaboradorAtivo?.saldo_banco_horas || '00:00'}</div>
                </S.SaldoTotal>



                <S.TableContainer>
                    <S.Table>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Dia da Semana</th>
                                {[...Array(colaboradorAtivo?.registros?.reduce((max, reg) => Math.max(max, reg.batidas.length), 0) || 0)].map((_, i) => (
                                    <th key={i}>{i + 1}º Registro</th>
                                ))}
                                <th>Total de Horas</th>
                                <th>Horas Extras</th>
                                <th>Atraso</th>
                                <th>Status</th>
                                <th>Ajustar Registro</th>
                            </tr>
                        </thead>
                        <tbody>
                            {colaboradorAtivo && colaboradorAtivo.registros
                                ?.sort((a, b) => new Date(b.data) - new Date(a.data))
                                .map((registro, index) => (
                                    <tr key={index}>
                                        <td>{format(new Date(registro.data + 'T00:00:00'), "dd-MM-yyyy")}</td>
                                        <td>{getDiaDaSemana(registro.data)}</td>
                                        {registro.Feriado === true ? (
                                            <>
                                                <td colSpan={colaboradorAtivo.registros.reduce((max, reg) => Math.max(max, reg.batidas.length), 0)} style={{ color: "#4caf50", fontWeight: "bold", textAlign: "center" }}>
                                                    Feriado Abonado
                                                </td>
                                            </>
                                        ) : registro.Ferias === true ? (
                                            <>
                                                <td colSpan={colaboradorAtivo.registros.reduce((max, reg) => Math.max(max, reg.batidas.length), 0)} style={{ color: "#ff9800", fontWeight: "bold", textAlign: "center" }}>
                                                    Férias
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                {registro.batidas.map((batida, idx) => (
                                                    <td key={idx}>
                                                        {new Date(batida.timestamp).toLocaleTimeString('pt-BR', {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                        })}
                                                        {registro.Feriado !== true && registro.Ferias !== true && (
                                                            batida.Registromanual ? (
                                                                <button onClick={() => removerBatida(registro, batida)} className="botao-remover" style={{ marginLeft: '10px' }}>
                                                                    <FaTrash />
                                                                </button>
                                                            ) : (
                                                                <button onClick={() => abrirMapa(batida.localizacao)} className="botao-mapa" style={{ marginLeft: '10px' }}>
                                                                    <FaMapMarkerAlt />
                                                                </button>
                                                            )
                                                        )}
                                                    </td>
                                                ))}
                                                {[...Array(colaboradorAtivo.registros.reduce((max, reg) => Math.max(max, reg.batidas.length), 0) - registro.batidas.length)].map((_, i) => (
                                                    <td key={i} className=""></td>
                                                ))}
                                            </>
                                        )}
                                        <td>{registro.Total_de_Horas || '00:00'}</td>
                                        <td>{registro.Hora_Extra || '00:00'}</td>
                                        <td>{registro.Atraso || '00:00'}</td>
                                        <td>
                                            {registro.Feriado === true || registro.Ferias === true ? (
                                                <FaCheckCircle color="green" />
                                            ) : (
                                                registro.batidas.length % 2 === 0 ? (
                                                    <FaCheckCircle color="green" />
                                                ) : (
                                                    <FaTimesCircle color="red" />
                                                )
                                            )}
                                        </td>
                                        <td>
                                            {registro.Feriado !== true && registro.Ferias !== true && (
                                                <>
                                                    <button onClick={() => abrirAjusteManual(registro)} className="botao-adicionar">
                                                        <FaPlus />
                                                    </button>
                                                    <button onClick={() => abrirModalAbonar(registro)} className="botao-abonar" style={{ marginLeft: '10px' }}>
                                                        Abonar
                                                    </button>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </S.Table>
                </S.TableContainer>


                <S.SaldoTotal>
                    <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                        {/* Componente para gerar o CSV */}
                        <GeraCsvPonto colaboradorAtivo={colaboradorAtivo} />
                        <GeraExcelPonto colaboradorAtivo={colaboradorAtivo} />
                        <GeraPdfPonto colaboradorAtivo={colaboradorAtivo} />

                    </div>
                </S.SaldoTotal>

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={fecharMapa}
                    contentLabel="Local onde o Registro de Ponto foi realizado"
                    style={S.mapModalStyle}
                >
                    {localizacaoAtiva && (
                        <>
                            <h3>Local onde o Registro de Ponto foi realizado</h3>
                            <S.MapContainerWrapper>
                                <MapContainer center={[localizacaoAtiva.latitude, localizacaoAtiva.longitude]} zoom={18} style={{ height: "100%", width: "100%" }}>
                                    <TileLayer attribution='&copy; OpenStreetMap contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                    <Marker position={[localizacaoAtiva.latitude, localizacaoAtiva.longitude]} icon={defaultIcon}>
                                        <Popup>
                                            Localização da batida: {localizacaoAtiva.latitude}, {localizacaoAtiva.longitude}
                                        </Popup>
                                    </Marker>
                                    <ChangeMapView coords={[localizacaoAtiva.latitude, localizacaoAtiva.longitude]} />
                                </MapContainer>
                            </S.MapContainerWrapper>
                            <button onClick={fecharMapa}>Fechar Mapa</button>
                        </>
                    )}
                </Modal>

                <Modal
                    isOpen={modalAjusteIsOpen}
                    onRequestClose={fecharAjusteManual}
                    contentLabel="Ajuste Manual"
                    style={S.modalStyle}
                >
                    {registroSelecionado && (
                        <S.ModalContent>
                            <h3>Ajustar Registro de Ponto - {registroSelecionado.data}</h3>
                            <div className="field">
                                <label>Horário: </label>
                                <input type="time" id="horarioBatida" />
                            </div>
                            <div className="actions">
                                <button onClick={salvarAjuste}>Salvar</button>
                                <button onClick={fecharAjusteManual}>Cancelar</button>
                            </div>
                        </S.ModalContent>
                    )}
                </Modal>
                <Modal
                    isOpen={modalAbonarIsOpen}
                    onRequestClose={fecharModalAbonar}
                    contentLabel="Abonar Registro"
                    style={S.modalStyle}
                >
                    {registroAbonar && (
                        <S.ModalContent>
                            <h3>Abonar Registro de Ponto - {registroAbonar.data}</h3>
                            <div className="actions">
                                <button
                                    onClick={() => salvarAbono("feriado")}
                                    style={{ backgroundColor: "#4caf50", color: "#fff", marginRight: "10px" }}
                                >
                                    Feriado
                                </button>
                                <button
                                    onClick={() => salvarAbono("ferias")}
                                    style={{ backgroundColor: "#ff9800", color: "#fff" }}
                                >
                                    Férias
                                </button>
                                <button
                                    onClick={fecharModalAbonar}
                                    style={{ backgroundColor: "#f44336", color: "#fff", marginLeft: "10px" }}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </S.ModalContent>
                    )}
                </Modal>

            </S.Content>
            <Footer />
        </S.Container>
    );
}

export default GestaoPonto;
