import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

// Componentes
import Header from '../../components/Header';
import Footer from '../../components/Footer';

// Serviços
import BuscaRedes from '../../services/Marketdo/BuscaRedes';
import Buscarredecnpj from '../../services/rede_cnpj/Buscarredecnpj';
import RedeCnpjService from '../../services/rede_cnpj/RedeCnpjService';
import sendUpload from '../../services/upload/Upload';
import ImageViewer from '../../services/Arquivos/ImageViewer';

function CNPJsRede() {
    const [redes, setRedes] = useState([]);
    const [redeSelecionada, setRedeSelecionada] = useState('');
    const [idRede, setIdRede] = useState('');
    const [resultados, setResultados] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        id_rede: '',
        nome_fantasia: '',
        razao_social: '',
        cpf_cnpj: '',
        endereco: '',
        telefone: '',
        logo: '',
    });

    const navigate = useNavigate();

    useEffect(() => {
        const fetchRedes = async () => {
            try {
                const buscaRedes = new BuscaRedes();
                await buscaRedes.loadRede();
                setRedes(buscaRedes.getResultadoRede());
            } catch (error) {
                console.error('Erro ao buscar redes:', error);
            }
        };

        fetchRedes();
    }, []);

    const handleSelectRede = (e) => {
        const selectedId = e.target.value;
        setRedeSelecionada(selectedId);

        const rede = redes.find((rede) => rede._id.$oid === selectedId);
        if (rede) {
            setIdRede(rede._id.$oid);
        } else {
            setIdRede('');
        }
    };

    const handleBuscarRedeCNPJ = async () => {
        if (!idRede) {
            alert('Por favor, selecione uma rede válida antes de buscar.');
            return;
        }

        try {
            const resultado = await Buscarredecnpj(idRede);
            if (resultado && resultado.cadastros) {
                setResultados(resultado.cadastros);
                setIsModalOpen(true);
            } else {
                alert('Nenhum dado encontrado para a rede selecionada.');
            }
        } catch (error) {
            console.error('Erro ao buscar Rede CNPJ:', error);
            alert('Erro ao buscar Rede CNPJ.');
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSelectCadastro = (cadastro) => {
        setFormData({
            id: cadastro._id.$oid,
            id_rede: cadastro.id_rede,
            nome_fantasia: cadastro.nome_fantasia,
            razao_social: cadastro.razao_social,
            cpf_cnpj: cadastro.cpf_cnpj,
            endereco: cadastro.endereco,
            telefone: cadastro.telefone,
            logo: cadastro.logo || '',
        });
        setIdRede(cadastro.id_rede);
        closeModal();
    };

    const handleLogoChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('id_rede', idRede);

            

            // Chama a função de upload
            const response = await sendUpload(idRede, formData);
            setFormData((prevData) => ({ ...prevData, logo: response.url }));
            alert('Logo enviado com sucesso!');
        } catch (error) {
            console.error('Erro ao fazer upload do logo:', error);
            alert('Erro ao enviar a imagem do logo.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!idRede) {
            alert('O campo id_rede é obrigatório. Certifique-se de selecionar uma rede.');
            return;
        }

        const payload = {
            id_rede: idRede,
            nome_fantasia: formData.nome_fantasia,
            razao_social: formData.razao_social,
            cpf_cnpj: formData.cpf_cnpj,
            endereco: formData.endereco,
            telefone: formData.telefone,
            logo: formData.logo,
        };

        try {
            if (formData.id) {
                await RedeCnpjService.updateRedeCnpj(formData.id, payload);
                alert('Cadastro atualizado com sucesso!');
            } else {
                const response = await RedeCnpjService.saveRedeCnpj(payload);
                alert('Cadastro salvo com sucesso!');
                setIdRede(response.id_rede);
            }

            setFormData({
                id: '',
                id_rede: '',
                nome_fantasia: '',
                razao_social: '',
                cpf_cnpj: '',
                endereco: '',
                telefone: '',
                logo: '',
            });
            setIdRede('');
            setRedeSelecionada('');
        } catch (error) {
            console.error('Erro ao salvar ou atualizar o cadastro:', error);
            alert('Erro ao salvar ou atualizar o cadastro.');
        }
    };

    return (
        <>
            <Header />
            <S.BackButton onClick={() => navigate('/Marketdo')}>
                <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
                Voltar
            </S.BackButton>
            <S.Container>
                <S.Form onSubmit={handleSubmit}>
                    <S.Title>Cadastro de CNPJs Rede</S.Title>

                    <S.InputGroup>
                        <S.Label>Selecione uma Rede:</S.Label>
                        <S.Select value={redeSelecionada} onChange={handleSelectRede}>
                            <option value="">Selecione...</option>
                            {redes.map((rede) => (
                                <option key={rede._id.$oid} value={rede._id.$oid}>
                                    {rede.nome_fantasia}
                                </option>
                            ))}
                        </S.Select>
                    </S.InputGroup>

                    <S.InputGroup>
                        <S.Label>Nome Fantasia:</S.Label>
                        <S.Input
                            type="text"
                            value={formData.nome_fantasia}
                            onChange={(e) => setFormData({ ...formData, nome_fantasia: e.target.value })}
                        />
                    </S.InputGroup>

                    <S.InputGroup>
                        <S.Label>Razão Social:</S.Label>
                        <S.Input
                            type="text"
                            value={formData.razao_social}
                            onChange={(e) => setFormData({ ...formData, razao_social: e.target.value })}
                        />
                    </S.InputGroup>

                    <S.InputGroup>
                        <S.Label>CPF/CNPJ:</S.Label>
                        <S.Input
                            type="text"
                            value={formData.cpf_cnpj}
                            onChange={(e) => setFormData({ ...formData, cpf_cnpj: e.target.value })}
                        />
                    </S.InputGroup>

                    <S.InputGroup>
                        <S.Label>Endereço:</S.Label>
                        <S.Input
                            type="text"
                            value={formData.endereco}
                            onChange={(e) => setFormData({ ...formData, endereco: e.target.value })}
                        />
                    </S.InputGroup>

                    <S.InputGroup>
                        <S.Label>Telefone:</S.Label>
                        <S.Input
                            type="text"
                            value={formData.telefone}
                            onChange={(e) => setFormData({ ...formData, telefone: e.target.value })}
                        />
                    </S.InputGroup>

                    <S.InputGroup>
                        <S.Label>Logo:</S.Label>
                        {formData.logo ? (
                            <div>
                                <ImageViewer fileId={formData.logo} idRede={idRede} />
                                <button onClick={() => setFormData((prevData) => ({ ...prevData, logo: '' }))}>
                                    Remover Logo
                                </button>
                            </div>
                        ) : (
                            <S.Input type="file" accept="image/*" onChange={handleLogoChange} />
                        )}
                    </S.InputGroup>

                    <S.ButtonGroup>
                        <S.Button type="submit">Salvar</S.Button>
                        <S.Button type="reset" onClick={() => setFormData({})}>
                            Limpar
                        </S.Button>
                        <S.Button type="button" onClick={handleBuscarRedeCNPJ}>
                            Buscar Rede CNPJ
                        </S.Button>
                    </S.ButtonGroup>
                </S.Form>
            </S.Container>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                ariaHideApp={false}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxHeight: '70vh',
                        overflowY: 'auto',
                    },
                }}
            >
                <h2>Resultados da Busca</h2>
                <button onClick={closeModal}>Fechar</button>
                <S.TableContainer>
                    <S.Table>
                        <thead>
                            <tr>
                                <th>Nome Fantasia</th>
                                <th>Razão Social</th>
                                <th>CPF/CNPJ</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resultados.map((cadastro) => (
                                <tr key={cadastro._id.$oid}>
                                    <td>{cadastro.nome_fantasia}</td>
                                    <td>{cadastro.razao_social}</td>
                                    <td>{cadastro.cpf_cnpj}</td>
                                    <td>
                                        <button onClick={() => handleSelectCadastro(cadastro)}>
                                            Selecionar
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </S.Table>
                </S.TableContainer>
            </Modal>
            <Footer />
        </>
    );
}

export default CNPJsRede;
