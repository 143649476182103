import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as S from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CabecarioIcon from '../../components/CabecarioIcon';
import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import useBuscaClientes from '../../services/Cliente/useBuscaClientes';
import ClienteService from '../../services/Cliente/ClienteService';
import ViaCepService from '../../services/Viacep/ViaCepService';
import Del from '../../services/Cliente/del';
import { MascaraCpfCnpj, ValidaCpf, ValidaCnpj, MascaraCep, MascaraTelefone, MascaraTelefoneFixo } from '../../utils/mask';
import secureStorage from '../../utils/SecureStorage';
import { useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

const CadastroCliente = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { searchResults, loadregistros } = useBuscaClientes([]);
  const [erro, setErro] = useState('');
  const intl = useIntl();
  const contentLabel = intl.formatMessage({ id: 'form.buscar_cadastros', defaultMessage: 'Buscar Cadastros' });
  const [nome, setNome] = useState('');
  const [cpf_cnpj, setCpfCnpj] = useState('');
  const [tabIndex, setTabIndex] = useState(0); // Estado para controlar a aba ativa
  const navigate = useNavigate();
  const [cliente, setCliente] = useState({
    id: '',
    id_rede: secureStorage.getItem("id_r"),
    cpf_cnpj: '',
    nome: '',
    sobrenome: '',
    blocked: false,
    inactive: false,
    endereco: {
      rua: '',
      numero: '',
      complemento: '',
      bairro: '',
      cep: '',
      cidade: '',
      estado: ''
    },
    contato: {
      telefone_fixo: '',
      celular: '',
      email: '',
      WhatsApp: '',
      observacoes: ''
    },
  });
  const clienteId = cliente._id?.$oid;

  const handleChange = (e) => {
    let { name, value } = e.target;

    // Aplica as máscaras baseadas no nome do campo
    switch (name) {
      case 'contato.celular':
      case 'contato.WhatsApp':
        value = MascaraTelefone(value);
        break;
      case 'contato.telefone_fixo':
        value = MascaraTelefoneFixo(value);
        break;
      case 'endereco.cep':
        value = MascaraCep(value);
        break;
      case 'cpf_cnpj':
        value = MascaraCpfCnpj(value);
        break;
      default:
        break;
    }

    // Atualiza o estado de forma dinâmica
    if (name.includes('endereco.') || name.includes('contato.')) {
      const [parentKey, childKey] = name.split('.');
      setCliente(prevState => ({
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          [childKey]: value
        }
      }));
    } else {
      setCliente(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  // Função para mover o foco para o próximo campo ou aba
  const moveFocus = (e, nextFieldName) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const nextField = document.getElementsByName(nextFieldName)[0];
      if (nextField) {
        nextField.focus();
      } else {
        // Se não houver próximo campo, muda para a próxima aba
        setTabIndex((prevTab) => {
          const nextTab = prevTab + 1;
          return nextTab >= 3 ? 0 : nextTab; // Cicla entre as abas
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Previne o comportamento padrão do formulário

    const cpf_cnpj = MascaraCpfCnpj(cliente.cpf_cnpj); // Aplica a máscara ao CPF/CNPJ

    // Valida o CPF ou CNPJ
    if (!ValidaCpf(cpf_cnpj) && !ValidaCnpj(cpf_cnpj)) {
      setErro(intl.formatMessage({ id: 'form.error.invalid_cpf_cnpj', defaultMessage: 'CPF/CNPJ inválido' }));
      return;
    }

    // Verifica se o nome do cliente foi informado
    if (!cliente.nome) {
      alert("Você precisa informar o Nome do Cliente");
      return false;
    }

    // Verifica se a rua do endereço foi informada
    if (!cliente.endereco.rua) {
      alert("Você precisa informar a Rua do Endereço");
      return false;
    }

    // Verifica se o número do endereço foi informado
    if (!cliente.endereco.numero) {
      alert("Você precisa informar o Número do Endereço");
      return false;
    }

    // Verifica se o bairro do endereço foi informado
    if (!cliente.endereco.bairro) {
      alert("Você precisa informar o Bairro do Endereço");
      return false;
    }

    // Verifica se o CEP do endereço foi informado
    if (!cliente.endereco.cep) {
      alert("Você precisa informar o CEP do Endereço");
      return false;
    }

    // Verifica se a cidade do endereço foi informada
    if (!cliente.endereco.cidade) {
      alert("Você precisa informar a Cidade do Endereço");
      return false;
    }

    // Verifica se o estado do endereço foi informado
    if (!cliente.endereco.estado) {
      alert("Você precisa informar o Estado do Endereço");
      return false;
    }

    // Após as validações, tenta salvar ou atualizar o cliente
    try {
      await ClienteService.saveCliente(cliente); // Chama o serviço para salvar o cliente
      handleResetForm(); // Reseta o formulário após sucesso
      setTabIndex(0); // Retorna para a primeira aba após salvar
    } catch (error) {
      // Caso ocorra um erro, é tratado pelos interceptadores de erro da API ou aqui
      console.error("Erro ao salvar o cliente:", error);
      setErro(intl.formatMessage({ id: 'form.error.saving', defaultMessage: 'Erro ao salvar o cadastro' }));
    }
  };

  const handleOpenModal = async () => {
    setIsModalOpen(true);
    const idrede = secureStorage.getItem("id_r");
    if (idrede) {
      await loadregistros(idrede); // Carrega os registros ao abrir o modal
    }
  };

  const handleSearchChange = (event, setSearchTerm) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectCliente = (clienteSelecionado) => {
    setCliente(clienteSelecionado); // Atualiza o estado com os dados do cliente selecionado
    handleCloseModal(); // Fecha o modal
    setErro(''); // Limpa mensagens de erro, se aplicável
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      await Del.excluirCliente(clienteId);
      handleResetForm();
      setTabIndex(0); // Retorna para a primeira aba após deletar
    } catch (error) {
      console.error('Erro ao excluir o cliente:', error);
      // Implemente a lógica de tratamento de erro, como mostrar uma mensagem para o usuário.
    }
  };

  const onBlurCep = (e) => {
    let { value } = e.target;
    // Remove caracteres não numéricos para garantir um CEP limpo
    const cep = value.replace(/\D/g, '');

    if (cep.length !== 8) { // Verifica se o CEP tem 8 dígitos, sem contar traços
      return;
    }

    ViaCepService.fetchAddress(cep)
      .then((data) => {
        if (data.erro) {
          alert('CEP não encontrado.');
          return;
        }
        setCliente((prevState) => ({
          ...prevState,
          endereco: {
            ...prevState.endereco,
            rua: data.logradouro,
            bairro: data.bairro,
            cidade: data.localidade,
            estado: data.uf,
            cep: value // Mantém a máscara aplicada pelo usuário
          }
        }));
      })
      .catch((error) => {
        alert('Erro ao buscar CEP: ' + error.message);
      });
  };

  const handleResetForm = () => {
    setCliente({
      id: '',
      id_rede: '',
      cpf_cnpj: '',
      nome: '',
      sobrenome: '',
      blocked: false,
      inactive: false,
      endereco: {
        rua: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        cidade: '',
        estado: ''
      },
      contato: {
        telefone_fixo: '',
        celular: '',
        email: '',
        WhatsApp: '',
        observacoes: ''
      },
    });
    setTabIndex(0); // Retorna para a primeira aba após resetar
  };

  return (
    <>
      <Header />
      <S.BackButton onClick={() => navigate('/home')}>
        <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
        Voltar
      </S.BackButton>

      <S.Container>
        <S.Form onSubmit={handleSubmit}>
          <S.Title>
            <FormattedMessage id="form.title" defaultMessage="Cadastro de Cliente" />
          </S.Title>
          <CabecarioIcon title="Cadastro de Cliente" />
          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <S.StyledTabList>
              <S.StyledTab>
                <FormattedMessage id="tabs.client" defaultMessage="Cliente" />
              </S.StyledTab>
              <S.StyledTab>
                <FormattedMessage id="tabs.address" defaultMessage="Endereço" />
              </S.StyledTab>
              <S.StyledTab>
                <FormattedMessage id="tabs.contact" defaultMessage="Contato" />
              </S.StyledTab>
            </S.StyledTabList>
            <TabPanel>
              {/* Campos básicos */}
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.name" defaultMessage="Nome:" />
                </S.Label>
                <S.Input
                  name="nome"
                  value={cliente.nome}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'cpf_cnpj')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cpf_cnpj" defaultMessage="CPF/CNPJ:" />
                </S.Label>
                <S.Input
                  name="cpf_cnpj"
                  value={cliente.cpf_cnpj}
                  onChange={handleChange}
                  maxLength={18}
                  onKeyDown={(e) => moveFocus(e, '')} // Último campo da aba, moverá para a próxima aba
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.CheckboxContainer>
                  <S.Label>Bloquear:</S.Label>
                  <S.Checkbox name="blocked" type="checkbox" checked={cliente.blocked} onChange={(e) => setCliente({ ...cliente, blocked: e.target.checked })} />
                </S.CheckboxContainer>
              </S.InputGroup>
            </TabPanel>

            <TabPanel>
              {/* Endereço */}
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cep" defaultMessage="Cep:" />
                </S.Label>
                <S.Input
                  name="endereco.cep"
                  value={cliente.endereco.cep}
                  onChange={handleChange}
                  onBlur={onBlurCep}
                  maxLength={9}
                  onKeyDown={(e) => moveFocus(e, 'endereco.rua')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.rua" defaultMessage="Rua:" />
                </S.Label>
                <S.Input
                  name="endereco.rua"
                  value={cliente.endereco.rua}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'endereco.numero')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.numero" defaultMessage="Numero:" />
                </S.Label>
                <S.Input
                  name="endereco.numero"
                  value={cliente.endereco.numero}
                  onChange={handleChange}
                  maxLength={10}
                  onKeyDown={(e) => moveFocus(e, 'endereco.complemento')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.complemento" defaultMessage="Complemento:" />
                </S.Label>
                <S.Input
                  name="endereco.complemento"
                  value={cliente.endereco.complemento}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'endereco.bairro')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.bairro" defaultMessage="Bairro:" />
                </S.Label>
                <S.Input
                  name="endereco.bairro"
                  value={cliente.endereco.bairro}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'endereco.cidade')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cidade" defaultMessage="Cidade:" />
                </S.Label>
                <S.Input
                  name="endereco.cidade"
                  value={cliente.endereco.cidade}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'endereco.estado')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.estado" defaultMessage="Estado:" />
                </S.Label>
                <S.Input
                  name="endereco.estado"
                  value={cliente.endereco.estado}
                  onChange={handleChange}
                  maxLength={2}
                  onKeyDown={(e) => moveFocus(e, '')} // Último campo da aba, moverá para a próxima aba
                />
              </S.InputGroup>
            </TabPanel>

            <TabPanel>
              {/* Contato */}
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.telefone_fixo" defaultMessage="Telefone fixo:" />
                </S.Label>
                <S.Input
                  name="contato.telefone_fixo"
                  value={cliente.contato.telefone_fixo}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'contato.celular')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.celular" defaultMessage="Celular:" />
                </S.Label>
                <S.Input
                  name="contato.celular"
                  value={cliente.contato.celular}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'contato.WhatsApp')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.whatsapp" defaultMessage="WhatsApp:" />
                </S.Label>
                <S.Input
                  name="contato.WhatsApp"
                  value={cliente.contato.WhatsApp}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'contato.email')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.email" defaultMessage="Email:" />
                </S.Label>
                <S.Input
                  name="contato.email"
                  value={cliente.contato.email}
                  onChange={handleChange}
                  maxLength={255}
                  onKeyDown={(e) => moveFocus(e, 'contato.observacoes')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.observacoes" defaultMessage="Observações:" />
                </S.Label>
                <S.TextArea
                  name="contato.observacoes"
                  value={cliente.contato.observacoes}
                  onChange={handleChange}
                  maxLength={250}
                  onKeyDown={(e) => moveFocus(e, '')} // Último campo da aba, moverá para a próxima aba
                />
              </S.InputGroup>
            </TabPanel>
          </Tabs>
          {erro && <S.Erro>{erro}</S.Erro>}
          <S.ButtonGroup>
            <S.Button type="button" onClick={handleOpenModal}>
              <FormattedMessage id="form.buscar_cadastros" defaultMessage="Buscar Cadastros" />
            </S.Button>
            {secureStorage.getItem("TipoCliente") !== 1 && (
              <S.Button type="submit">
                <FormattedMessage
                  id={cliente._id ? "form.salvar" : "form.cadastrar"}
                  defaultMessage={cliente._id ? "Salvar" : "Cadastrar"}
                />
              </S.Button>
            )}
            {cliente._id && secureStorage.getItem("TipoCliente") === 3 && (
              <S.Button type="button" onClick={handleDelete}>
                <FormattedMessage id="form.excluir" defaultMessage="Excluir Cadastro" />
              </S.Button>
            )}
            {secureStorage.getItem("TipoCliente") !== 1 && (
              <S.Button type="button" onClick={handleResetForm}>
                <FormattedMessage id="form.novo" defaultMessage="Novo" />
              </S.Button>
            )}
          </S.ButtonGroup>
        </S.Form>
        <S.StyledModal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel={contentLabel}
          ariaHideApp={false}
        >
          {/* Campo de busca por nome dentro do modal */}
          <S.Input
            type="text"
            placeholder="Digite o nome para buscar..."
            value={nome}
            onChange={(e) => handleSearchChange(e, setNome)}
            style={{ marginBottom: '10px' }}
          />

          {/* Campo de busca por cpf_cnpj dentro do modal */}
          <S.Input
            type="text"
            placeholder="Digite o CPF/CNPJ para buscar..."
            value={cpf_cnpj}
            onChange={(e) => handleSearchChange(e, setCpfCnpj)}
          />
          <S.ModalContent>
            {/* Lista filtrada de resultados da busca */}
            {Array.isArray(searchResults) && searchResults.length > 0 ? (
              <S.StyledTable>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>cpf_cnpj</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults
                    .filter(cliente =>
                      cliente.nome.toLowerCase().includes(nome.toLowerCase()) &&
                      cliente.cpf_cnpj.toLowerCase().includes(cpf_cnpj.toLowerCase())
                    )
                    .map((cliente) => (
                      <tr key={cliente.cpf_cnpj} onClick={() => handleSelectCliente(cliente)}>
                        <td>{cliente.nome}</td>
                        <td>{cliente.cpf_cnpj}</td>
                      </tr>
                    ))}
                </tbody>
              </S.StyledTable>
            ) : (
              <p>Nenhum resultado encontrado.</p>
            )}
          </S.ModalContent>
          <button onClick={handleCloseModal}>Fechar</button>
        </S.StyledModal>
      </S.Container>
      <Footer />
    </>
  );
};

export default CadastroCliente;
