import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 50px;
  background: #ffffff;
  border-top: 5px solid #cf0505;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 10px 20px;
  }
`;

export const SpanLeftAlign = styled.span`
  text-align: left;
  color: #cf0505;
  font-size: 14px;
  font-weight: bold;
  margin-left: 20px;

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 5px;
    margin-left: 0;
  }
`;

export const SpanCenterAlign = styled.span`
  text-align: center;
  color: #cf0505;
  font-size: 14px;
  font-weight: bold;
  visibility: visible; /* Garante visibilidade */
  display: block; /* Força exibição */
  margin: 5px 0; /* Espaçamento superior e inferior */

  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

export const SpanRightAlign = styled.span`
  text-align: right;
  color: #cf0505;
  font-size: 14px;
  font-weight: bold;
  margin-right: 20px;

  @media (max-width: 768px) {
    text-align: center;
    margin-right: 0;
  }
`;
