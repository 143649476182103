import { useState } from "react";
import api from "../api";
import secureStorage from "../../utils/SecureStorage";

export const fetchBatidas = async (id_rede, id_colaborador) => {
  try {

    const apiUrl = process.env.REACT_APP_API_BASE_31;

    const dadosParaBuscar = {
      id_rede,
      id_colaborador,
    };

    const response = await api.get(`${apiUrl}`, {
      params: dadosParaBuscar,
    });

    if (response.status === 200) {
      
      secureStorage.setItem("dataponto", JSON.stringify(response.data));
      return response.data; // Retorna os dados da API
    } else {
      console.error("ERRO: Falha ao buscar registros de ponto.");
      throw new Error("Erro ao buscar registros de ponto.");
    }
  } catch (error) {
    console.error("Erro na busca de batidas:", error);
    throw error;
  }
};

const useBuscarBatidas = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const BuscarBatidas = async (id_rede, id_colaborador) => {
    setLoading(true);
    setError(null);
    try {
      const data = await fetchBatidas(id_rede, id_colaborador);
      return data; // Retorna os dados para a função que o chamou
    } catch (err) {
      setError(err.message || "Erro desconhecido ao buscar registros.");
      return null; // Retorna nulo em caso de erro
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, BuscarBatidas };
};

export default useBuscarBatidas;
