import styled from 'styled-components';
import { TabList, Tab } from 'react-tabs';
import Modal from 'react-modal';

export const Container = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: hidden; // Esconde a barra de rolagem horizontal
  overflow-y: auto; // Mostra a barra de rolagem vertical apenas quando necessário
  padding-top: 60px; // Espaço no topo para não ficar sob o cabeçalho fixo
  padding-bottom: 30px; // Espaço no fundo para não ficar sob o footer fixo

  @media (max-width: 768px) {
    padding-top: 100px; // Aumenta o padding no topo em dispositivos menores, se necessário
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 600px;
  background: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #cf0505; // Adiciona a borda vermelha
  margin: 0 auto; // Centraliza o formulário na tela

  @media (max-width: 768px) {
    max-width: 90%; // Aumenta a largura para 90% da tela em dispositivos menores
    padding: 15px; // Reduz o padding para criar mais espaço
  }

  @media (max-width: 480px) {
    max-width: 100%; // Ocupa 100% da largura da tela em dispositivos muito pequenos
    padding: 10px; // Reduz ainda mais o padding
  }
`;

export const Title = styled.h1`
  color: #cf0505; // Cor vermelha para o texto
  margin-bottom: 20px; // Adiciona espaço abaixo do título
  text-align: center; // Alinha o texto ao centro
  font-size: 24px; // Tamanho do texto
`;

export const InputGroup = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #cf0505;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box; 
`;

export const Button = styled.button`
  flex: 1; // Faz com que os botões ocupem igualmente o espaço disponível
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #003366; // Azul marinho como cor de fundo
  color: white; // Texto branco para alto contraste
  font-size: 16px;
  cursor: pointer;
  margin: 5px; // Adiciona uma margem em torno dos botões para espaçamento

  &:hover {
    background-color: #002244; // Uma tonalidade mais escura para o efeito hover
  }

  // Se precisar de mais espaço entre os botões quando estiverem empilhados em dispositivos móveis
  @media (max-width: 768px) {
    width: 100%; // Faz com que cada botão ocupe a largura total do container
    margin-bottom: 10px; // Adiciona espaço abaixo de cada botão, exceto o último

    &:last-child {
      margin-bottom: 0; // Remove a margem do último botão
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap; // Permite que os botões quebrem linha se necessário
  justify-content: space-between; // Isso coloca espaço entre os botões
  width: 100%; // Isso garante que o ButtonGroup ocupe a largura total do container
  margin-top: 20px; // Adiciona um espaço acima dos botões
`;

export const Fieldset = styled.fieldset`
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
`;

export const Legend = styled.legend`
  padding: 0 5px;
  color: #333;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const Checkbox = styled.input`
  margin-left: 10px;
`;

export const StyledTabList = styled(TabList)`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  border-bottom: 1px solid #ccc;
`;

StyledTabList.tabsRole = 'TabList';

export const StyledTab = styled(Tab)`
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background: #f0f0f0;
  border: 1px solid #ccc;
  margin-right: -1px;
  &:last-of-type {
    margin-right: 0;
  }
  &.react-tabs__tab--selected {
    color: #cf0505;
    background-color: #f0f0f0;
    border-color: #ccc;
    border-bottom: none;
  }

  @media (max-width: 768px) {
    padding: 6px 12px;
  }

  @media (max-width: 480px) {
    padding: 4px 8px;
  }
`;

StyledTab.tabsRole = 'Tab';

export const TextArea = styled.textarea`
  width: 100%;
  height: 120px;
`;

export const Erro = styled.p`
  color: red;
`;

export const StyledModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  max-width: 800px;
  width: 90%;
  background: #FFF;
  border-radius: 10px;
  border: none;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  // Personalize o overlay (fundo) aqui, se necessário
  &:focus {
    outline: none;
  }

  // Para telas pequenas, você pode querer ajustar a largura ou padding
  @media (max-width: 768px) {
    width: 95%;
    padding: 10px;
  }
`;

// StyledTable é um componente de tabela estilizado
export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border-radius: 8px;

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f0f0f0;
    color: #333;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
`;

export const ModalContent = styled.div`
  max-height: 400px; // Ajuste para o valor que você deseja
  overflow-y: auto;
`;

export const StyledAddressModal = styled(Modal)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); // Fundo escuro semi-transparente
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 80%;
    height: 80%;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative; // Para posicionar o botão "Fechar"
    overflow: hidden; // Evita rolagem externa
  }

  @media (max-width: 768px) {
    .content {
      width: 95%;
      padding: 15px;
    }
  }
`;

export const ModalWrapper = styled.div`
  width: 80%;
  height: 80%;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  position: absolute;
  top: 50%; /* Centraliza verticalmente */
  left: 50%;
  transform: translate(-50%, -50%); /* Ajuste para centralizar no meio */
  overflow: hidden; /* Remove a rolagem externa */
`;

export const TableWrapper = styled.div`
  max-height: calc(100% - 100px); /* Espaço ajustado para título e botão */
  overflow-y: auto; /* Rolagem apenas interna */
  margin-top: 20px; /* Espaçamento entre o cabeçalho e a tabela */
`;

export const ModalHeader = styled.h3`
  margin-bottom: 20px;
  text-align: center;
  font-size: 18px;
  color: #333;
`;

export const StyledTable2 = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: #f9f9f9;
    border-bottom: 2px solid #ddd;

    th {
      padding: 10px;
      text-align: left;
      font-weight: bold;
      color: #555;
    }
  }

  tbody {
    tr {
      cursor: pointer;
      border-bottom: 1px solid #ddd;
      transition: background-color 0.3s;

      &:hover {
        background-color: #f1f1f1;
      }

      td {
        padding: 10px;
        color: #444;
      }
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px; /* Alterado para alinhar no canto superior direito */
  background: none;
  border: none;
  color: #d9534f;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: #c9302c; /* Cor mais escura no hover */
  }
`;

export const EmptyMessage = styled.p`
  text-align: center;
  color: #999;
  font-size: 16px;
  margin-top: 20px;
`;

export const BackButton = styled.button`
    position: absolute;
    top: 100px;
    left: 20px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #cf0505;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: #800000;
    }
`;            