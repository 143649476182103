import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-top: 100px; /* Aumenta o espaço no topo para o header */
  padding-bottom: 40px; /* Aumenta o espaço no fundo para o footer */
  background-color: #f7f7f7; /* Cor de fundo */
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center; /* Centraliza o conteúdo horizontalmente */
  width: 90%; /* Aumenta a largura do conteúdo principal */
  max-width: 1200px; /* Define um valor máximo para a largura */
  margin: 0 auto; /* Centraliza o conteúdo */
  background-color: #f7f7f7; /* Cor de fundo */
  border: 1px solid #B22222; /* Borda vermelha */
  border-radius: 8px; /* Borda arredondada */
  margin-top: 40px; /* Aumenta o espaçamento superior */
  margin-bottom: 40px; /* Aumenta o espaçamento inferior */
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Espalha os elementos horizontalmente */
  margin-bottom: 20px;
  width: 100%; /* Garantir que o container ocupe toda a largura disponível */
  background-color: #f7f7f7; /* Cor de fundo */
  border: 1px solid #B22222; /* Borda vermelha */
  border-radius: 8px; /* Borda arredondada */
  padding: 20px; /* Espaçamento interno */
  box-sizing: border-box; /* Inclui padding e border no tamanho total */
`;

export const DescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0; /* Adiciona espaço acima e abaixo do bloco de descrição */
  width: 100%; /* Garante que o bloco ocupe toda a largura disponível */
  align-items: center; /* Centraliza o conteúdo horizontalmente */
  background-color: #f7f7f7; /* Cor de fundo */
  border: 1px solid #B22222; /* Borda vermelha */
  border-radius: 8px; /* Borda arredondada */
  padding: 20px; /* Espaçamento interno */
  box-sizing: border-box; /* Inclui padding e border no tamanho total */
  text-align: center; /* Centraliza o texto */
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box; /* Inclui padding e border no tamanho total */
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  text-align: center; /* Centraliza o texto */
`;

export const Title = styled.span`
  font-weight: bold;
`;

export const Info = styled.span`
  margin-top: 5px;
`;

export const ClienteBlock = styled.div`
  width: 100%; /* Define uma largura maior para o bloco do cliente */
  margin-bottom: 20px;
  padding: 20px; /* Aumenta o padding para dar mais espaço interno */
  border: 1px solid #B22222; /* Borda vermelha */
  border-radius: 5px;
  background-color: #f7f7f7; /* Cor de fundo */
  box-sizing: border-box; /* Inclui padding e border no tamanho total */
`;

export const ClienteTitle = styled.h2`
  margin-bottom: 10px;
  text-align: left; /* Alinha o título à esquerda */
  color: #B22222; /* Cor vermelha para o título */
`;

export const Produtos = styled.div`
  margin-top: 10px;
`;

export const Produto = styled.div`
  margin-top: 10px;
`;

export const ProdutoImagemContainer = styled.div`
  display: flex;
  justify-content: center; /* Centraliza as imagens */
  flex-wrap: wrap;
  gap: 20px; /* Espaço entre as imagens */
`;

export const ProdutoImagemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProdutoImagem = styled.img`
  display: block;
  max-width: 200px; /* Aumenta a largura máxima da imagem */
  max-height: 200px; /* Aumenta a altura máxima da imagem */
`;

export const DownloadButton = styled.button`
  background-color:  #004aad; /* Cor do botão */
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 10px; /* Espaçamento superior para separar da imagem */
  
  &:hover {
    background-color: #002244; /* Cor do botão ao passar o mouse */
  }
`;

export const StatusIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;
export const GeneratePDFButton = styled.button`
  background-color: #4CAF50; /* Cor do botão */
  color: white;
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  
  &:hover {
    background-color: #45a049; /* Cor do botão ao passar o mouse */
  }
`;

export const BackButton = styled.button`
  position: absolute;
  top: 70px; /* Ajuste conforme necessário */
  left: 20px; /* Ajuste conforme necessário */
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #B22222;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: #800000;
  }
`;


export const BackIcon = styled.span`
  font-size: 18px;
  margin-right: 8px;
`;

