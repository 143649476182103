import api from '../api';

const DownloadEspelhorede = async ({ idRede, dataInicio, dataFim }) => {
    const apiUrl = process.env.REACT_APP_API_BASE_38 ;

    try {
        // Validação dos parâmetros
        if (!idRede || !dataInicio || !dataFim) {
            throw new Error("Todos os parâmetros (idRede, dataInicio, dataFim) são obrigatórios.");
        }

        // Construção da URL para o endpoint do backend
        const downloadUrl = `${apiUrl}?id_rede=${idRede}&data_inicio=${dataInicio}&data_fim=${dataFim}`;
        

        // Requisição ao backend para obter o ZIP
        const response = await api.get(downloadUrl, {
            responseType: 'blob', // Necessário para lidar com arquivos ZIP
        });

        // Cria um URL para o blob recebido
        const blobUrl = URL.createObjectURL(response.data);

        // Cria um link temporário para o download
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `EspelhoPonto_${dataInicio}_a_${dataFim}.zip`; // Nome do arquivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        
    } catch (error) {
        console.error("Erro ao tentar realizar o download do Espelho de Ponto:", error);

        // Tratamento de erros
        if (error.response) {
            throw new Error(error.response.data?.error || "Erro desconhecido no servidor.");
        } else {
            throw new Error("Erro ao conectar com o servidor. Verifique sua conexão.");
        }
    }
};

export default DownloadEspelhorede;
