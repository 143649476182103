import api from '../api'; // Substitua pelo arquivo correto da API
import secureStorage from '../../utils/SecureStorage';

async function BuscaPerfil() {
    try {
        const id_r = secureStorage.getItem("id_r");

        if (!id_r) {
            throw new Error("ID da rede não encontrado no SecureStorage");
        }

        const apiUrl = process.env.REACT_APP_API_BASE_34;

        const response = await api.get(`${apiUrl}${id_r}`);
        

        if (!response || !response.data) {
            throw new Error("Nenhum dado retornado da API");
        }

        // Garantir que o retorno seja sempre um array
        const data = Array.isArray(response.data.cadastros) ? response.data.cadastros : [response.data.cadastros];

        return data;
    } catch (error) {
        console.error("Erro ao buscar o perfil: ", error.message);
        throw error; // Propague o erro para ser tratado na chamada
    }
}


export default BuscaPerfil;
