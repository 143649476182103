import api from "../api";

// Função para enviar solicitação de fechamento manual para o servidor
const FechamentoPonto = async (id_rede) => {
  try {
    const apiUrl = process.env.REACT_APP_API_BASE_36;
    

    // Envia os dados para a API com o ID da rede
    const response = await api.post(`${apiUrl}`, { id_rede });
    

    // Alerta de sucesso no navegador
    window.alert("SUCESSO: Fechamento de ponto concluído com sucesso!");

  } catch (error) {
    console.error("Erro ao enviar a solicitação de fechamento de ponto:", error);

    // Alerta de erro no navegador
    const errorMessage = error.response?.data?.error || "Falha ao realizar fechamento de ponto. Tente novamente mais tarde.";
    window.alert(`ERRO: ${errorMessage}`);
  }
};

export default FechamentoPonto;
