import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as S from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import VisitaServices from '../../services/Visita/VisitaServices';
import ImageViewer from '../../services/Arquivos/ImageViewer';
import DownloadImage from '../../services/Arquivos/download_image';

import iconDefault from "../../assets/Default.png";
import iconIniciado from "../../assets/Iniciado.png";
import iconTerminado from "../../assets/Terminado.png";
import iconAtrasada from "../../assets/Atrasada.png";
import GeraPdfAtendimento from '../../components/Exportar/GeraPdfAtendimento';
import secureStorage from '../../utils/SecureStorage';
import { MdArrowBack } from 'react-icons/md'; // Ícone de voltar


const statusEnum = {
  "0": "Nao iniciado",
  "1": "Iniciado",
  "2": "Concluido",
  "3": "Atrasado"
};

const Visualizar = () => {
  const { id } = useParams();
  const [dados, setDados] = useState(null);
  const idrede = secureStorage.getItem("id_r");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchVisita = async () => {
      try {
        const response = await VisitaServices.getVisitaById(id);
        setDados(response);
      } catch (error) {
        console.error("Erro ao buscar visita:", error);
      }
    };

    fetchVisita();
  }, [id]);

  const formatDate = (dateObj) => {
    if (dateObj && dateObj.$date) {
      // Extrai os valores de ano, mês e dia diretamente da string ISO
      const date = new Date(dateObj.$date);

      const year = date.getUTCFullYear();
      const month = date.getUTCMonth(); // Mês é baseado em 0, então precisa somar +1
      const day = date.getUTCDate();

      // Formata a data para o padrão dia/mês/ano
      return `${day.toString().padStart(2, '0')} de ${monthToPortuguese(month)} de ${year}`;
    }
    return 'Data inválida';
  };

  // Função auxiliar para retornar o mês em português
  const monthToPortuguese = (month) => {
    const months = [
      'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
      'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
    ];
    return months[month];
  };


  const handleDownload = (base64, description) => {
    const link = document.createElement('a');
    link.href = `data:image/png;base64,${base64}`;
    link.download = `${description}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "0":
        return iconDefault;
      case "1":
        return iconIniciado;
      case "2":
        return iconTerminado;
      case "3":
        return iconAtrasada;
      default:
        return iconDefault;
    }
  };

  if (!dados) {
    return <div>Loading...</div>;
  }

  const handleBack = () => {
    navigate('/Quadrodeatendimentos');
  };

  return (
    <S.Container>
      <Header />
      <S.BackButton onClick={handleBack}>
        <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
        Voltar
      </S.BackButton>
      <S.MainContent>
        <S.HorizontalContainer>
          <S.InfoBlock>
            <S.Title>ID Atendimento</S.Title>
            <S.Info>{dados?.id || 'ID não disponível'}</S.Info>
          </S.InfoBlock>
          <S.InfoBlock>
            <S.Title>Data</S.Title>
            <S.Info>{dados?.data ? formatDate(dados.data) : 'Data não disponível'}</S.Info>
          </S.InfoBlock>
          <S.InfoBlock>
            <S.Title>Status</S.Title>
            <S.Info>
              <S.StatusIcon src={getStatusIcon(dados?.status || "0")} alt={statusEnum[dados?.status || "0"]} />
              {statusEnum[dados?.status || "0"]}
            </S.Info>
          </S.InfoBlock>
          <S.InfoBlock>
            <S.Title>Colaborador</S.Title>
            <S.Info>{dados?.colaborador?.nome || 'Nome não disponível'}</S.Info>
          </S.InfoBlock>
          <S.InfoBlock>
            <S.Title>Estabelecimento</S.Title>
            <S.Info>{dados?.estabelecimento?.nome_fantasia || 'Nome não disponível'}</S.Info>
          </S.InfoBlock>
        </S.HorizontalContainer>
        <S.HorizontalContainer>
          <S.InfoBlock>
            <S.Title>Descrição</S.Title>
            <S.Info>{dados?.descricao_visita || 'Descrição não disponível'}</S.Info>
          </S.InfoBlock>
        </S.HorizontalContainer>
        <S.VerticalContainer>
          {dados?.clientes?.length > 0 ? (
            dados.clientes.map((cliente, index) => (
              <S.ClienteBlock key={cliente.id || index}>
                <S.ClienteTitle>Cliente {index + 1}</S.ClienteTitle>
                <S.InfoBlock>
                  <S.Title>Nome</S.Title>
                  <S.Info>{cliente?.nome || 'Nome não disponível'}</S.Info>
                </S.InfoBlock>
                <S.InfoBlock>
                  <S.Title>CPF/CNPJ</S.Title>
                  <S.Info>{cliente?.cpf_cnpj || 'CPF/CNPJ não disponível'}</S.Info>
                </S.InfoBlock>
                <S.InfoBlock>
                  <S.Title>Endereço</S.Title>
                  <S.Info>
                    {cliente?.endereco
                      ? `${cliente.endereco.rua || ''}, ${cliente.endereco.numero || ''}, ${cliente.endereco.bairro || ''}, ${cliente.endereco.cidade || ''}-${cliente.endereco.estado || ''}`
                      : 'Endereço não disponível'}
                  </S.Info>
                </S.InfoBlock>
                <S.Produtos>
                  {cliente?.produtos?.length > 0 ? (
                    cliente.produtos.map((produto, idx) => (
                      <S.Produto key={produto?.id || idx}>
                        <S.InfoBlock>
                          <S.Title>Marca</S.Title>
                          <S.Info>{produto?.marca || 'Não informado'}</S.Info>
                        </S.InfoBlock>
                        <S.InfoBlock>
                          <S.Title>Produto</S.Title>
                          <S.Info>{produto?.descricao || 'Não informado'}</S.Info>
                        </S.InfoBlock>
                        <S.ProdutoImagemContainer>
                          {produto?.fotos?.length > 0 ? (
                            produto.fotos.map((foto, fotoIdx) => (
                              <S.ProdutoImagemWrapper key={fotoIdx}>
                                <ImageViewer fileId={foto.url} idRede={idrede} />
                                <DownloadImage fileId={foto.file_id} idRede={idrede} />
                              </S.ProdutoImagemWrapper>
                            ))
                          ) : (
                            <p>Sem fotos disponíveis</p>
                          )}
                        </S.ProdutoImagemContainer>
                        {produto?.faltaprodutos?.length > 0 &&
                          produto.faltaprodutos.map((falta, faltaIdx) => (
                            <div key={faltaIdx}>
                              <S.InfoBlock>
                                <S.Title>Falta de Produto</S.Title>
                                <S.Info>{falta?.observacao || 'Sem observação'}</S.Info>
                              </S.InfoBlock>
                            </div>
                          ))}
                      </S.Produto>
                    ))
                  ) : (
                    <p>Sem produtos disponíveis</p>
                  )}
                </S.Produtos>
                <GeraPdfAtendimento dados={dados} cliente={cliente} />
              </S.ClienteBlock>
            ))
          ) : (
            <p>Sem clientes disponíveis</p>
          )}
        </S.VerticalContainer>
      </S.MainContent>
      <Footer />
    </S.Container>
  );

}

export default Visualizar;
