import React, { useState, useEffect } from "react";
import * as S from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import useBuscarBatidas from "../../services/ponto/BuscarBatidas";
import enviarBatidas from "../../services/ponto/enviarBatidas";
import secureStorage from "../../utils/SecureStorage";
import { useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

function Ponto() {
    const [batidasHoje, setBatidasHoje] = useState([]);
    const [registros, setRegistros] = useState([]);
    const [horasTrabalhadas, setHorasTrabalhadas] = useState("00:00");
    const [saldoBancoHoras, setSaldoBancoHoras] = useState("00:00");
    const [totalAtrasos, setTotalAtrasos] = useState("00:00");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalRegistro, setModalRegistro] = useState(null);
    const navigate = useNavigate();

    const { BuscarBatidas } = useBuscarBatidas();

    const [horasHoje, setHorasHoje] = useState("00:00");

    const carregarBatidas = async () => {
        setError(null);
        setLoading(true);

        try {
            const id_rede = await secureStorage.getItem("id_r");
            const id_colaborador = await secureStorage.getItem("id_unico");

            const response = await BuscarBatidas(id_rede, id_colaborador);

            if (response && Array.isArray(response.registros)) {
                const registrosOrdenados = response.registros.sort(
                    (a, b) => new Date(b.data) - new Date(a.data)
                );

                setRegistros(registrosOrdenados);
                setHorasTrabalhadas(response.total_horas_trabalhadas || "00:00");
                setSaldoBancoHoras(response.saldo_banco_horas || "00:00");
                setTotalAtrasos(response.total_atrasos || "00:00");

                const agora = new Date();
                agora.setHours(agora.getHours() - 3); // Subtrai 3 horas para UTC-3
                const hoje = agora.toISOString().split("T")[0];

                const batidasDeHoje = registrosOrdenados.filter(
                    (registro) => registro.data === hoje
                );

                setBatidasHoje(batidasDeHoje);

                // Calcula horas trabalhadas de hoje
                if (batidasDeHoje.length > 0) {
                    const horasCalculadas = calcularHorasTrabalhadas(batidasDeHoje[0].batidas);
                    setHorasHoje(horasCalculadas);
                } else {
                    setHorasHoje("00:00");
                }
            } else {
                throw new Error("Estrutura de dados inválida: registros não encontrados.");
            }
        } catch (err) {
            console.error("Erro ao carregar registros:", err.message);
            setError("Erro ao carregar registros. Verifique sua conexão.");
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        carregarBatidas();
    }, []);


    const calcularHorasTrabalhadas = (batidas) => {
        if (!batidas || batidas.length < 2) return "00:00";

        let totalMinutos = 0;

        for (let i = 0; i < batidas.length; i += 2) {
            const entrada = new Date(batidas[i]?.timestamp);
            const saida = batidas[i + 1] ? new Date(batidas[i + 1]?.timestamp) : null;

            if (entrada && saida) {
                const diferencaMinutos = Math.floor((saida - entrada) / (1000 * 60));
                totalMinutos += diferencaMinutos;
            }
        }

        const horas = Math.floor(totalMinutos / 60).toString().padStart(2, "0");
        const minutos = (totalMinutos % 60).toString().padStart(2, "0");

        return `${horas}:${minutos}`;
    };



    const handleRegistrarPonto = async () => {
        setLoading(true);

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;

                    try {
                        // Obtém as informações necessárias do secureStorage
                        const id_rede = await secureStorage.getItem("id_r");
                        const empresa = await secureStorage.getItem("empresaponto");
                        const colaborador = await secureStorage.getItem("nomeUser");
                        const id_colaborador = await secureStorage.getItem("id_unico");
                        const ponto = JSON.parse(await secureStorage.getItem("ponto"));
                        const dataAtual = new Date().toISOString().split("T")[0];
                        const Processado = false;
                        const Solicitacao = false;
                        const Feriado = false;
                        const Ferias = false;
                        const Atraso = '00:00';
                        const Total_de_Horas = '00:00';
                        const Hora_Extra = '00:00';

                        if (!id_rede || !id_colaborador || !empresa || !ponto) {
                            throw new Error("Informações incompletas para enviar as batidas.");
                        }

                        // Cria o objeto `novaBatida` com o horário no formato ISO 8601
                        const novaBatida = {
                            horario: new Date().toISOString(), // Formato ISO 8601
                            localizacao: { latitude, longitude },
                        };

                        // Verificar diferença de tempo com a última batida
                        if (batidasHoje.length > 0) {
                            const ultimaBatida = new Date(
                                batidasHoje[0].batidas[batidasHoje[0].batidas.length - 1]?.timestamp
                            );
                            const diferencaMilissegundos = new Date(novaBatida.horario) - ultimaBatida;
                            const diferencaHoras = diferencaMilissegundos / (1000 * 60 * 60);

                            if (diferencaHoras < 1) {
                                // Alerta personalizado no navegador
                                const confirmar = window.confirm(
                                    "Foi identificado que o registro anterior foi feito há menos de uma hora. Tem certeza de que deseja registrar outro ponto?"
                                );
                                if (!confirmar) {
                                    setLoading(false); // Finaliza o carregamento se o usuário cancelar
                                    return; // Interrompe o fluxo caso o usuário escolha "Não"
                                }
                            }
                        }

                        const dadosParaEnviar = {
                            id_rede,
                            empresa,
                            colaborador,
                            id_colaborador,
                            Hora_Extra,
                            Total_de_Horas,
                            Atraso,
                            Processado,
                            Solicitacao,
                            Feriado,
                            Ferias,
                            ponto,
                            data: dataAtual,
                            batidas: [novaBatida],
                        };

                       

                        // Envia as batidas ao servidor
                        await enviarBatidas(dadosParaEnviar);

                        alert("Ponto registrado com sucesso!");
                        await carregarBatidas();
                    } catch (error) {
                        console.error("Erro ao registrar ponto:", error.message);
                        alert("Erro ao registrar ponto. Verifique suas informações e tente novamente.");
                    } finally {
                        setLoading(false);
                    }
                },
                (error) => {
                    console.error("Erro ao obter localização:", error);
                    alert("Erro ao obter localização. Verifique as permissões do navegador.");
                    setLoading(false);
                }
            );
        } else {
            alert("Geolocalização não suportada pelo navegador.");
            setLoading(false);
        }
    };



    return (
        <S.Container>
            <Header />
            <S.BackButton onClick={() => navigate('/home')}>
                <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
                Voltar
            </S.BackButton>
            {/* Card Principal */}
            <S.MainCard>
                <h2>Ponto Eletrônico</h2>
                <p>
                    <strong>Data Atual:</strong> {new Date().toLocaleDateString("pt-BR")}
                </p>
                <p>
                    <strong>Horas Trabalhadas Hoje:</strong> {horasHoje}
                </p>

                {/* Registros do Dia */}
                {batidasHoje.length > 0 ? (
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <h4>Entrada</h4>
                                {batidasHoje[0].batidas
                                    .filter((_, i) => i % 2 === 0)
                                    .map((batida, index) => (
                                        <p key={index}>
                                            {new Date(batida.timestamp).toLocaleTimeString("pt-BR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            })}
                                        </p>
                                    ))}
                            </div>
                            <div>
                                <h4>Saída</h4>
                                {batidasHoje[0].batidas
                                    .filter((_, i) => i % 2 !== 0)
                                    .map((batida, index) => (
                                        <p key={index}>
                                            {new Date(batida.timestamp).toLocaleTimeString("pt-BR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            })}
                                        </p>
                                    ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>Nenhum registro para hoje.</p>
                )}
            </S.MainCard>



            {/* Botão Registrar Ponto */}
            <S.Button onClick={handleRegistrarPonto} disabled={loading}>
                {loading ? "Registrando..." : "Registrar Ponto"}
            </S.Button>

            {/* Cards Informativos */}
            <S.InfoCardsContainer>
                <S.InfoCard>
                    <h3>Horas Trabalhadas </h3>
                    <p>{horasTrabalhadas}</p>
                </S.InfoCard>
                <S.InfoCard>
                    <h3>Total Atrasos</h3>
                    <p>{totalAtrasos}</p>
                </S.InfoCard>
                <S.InfoCard>
                    <h3>Saldo Banco de Horas</h3>
                    <p>{saldoBancoHoras}</p>
                </S.InfoCard>
            </S.InfoCardsContainer>

            {loading ? (
                <p>Carregando registros...</p>
            ) : (
                registros.map((registro, index) => {
                    const dataRegistro = new Date(`${registro.data}T00:00:00-03:00`); // Força UTC-3

                    return (
                        <S.Card key={index} onClick={() => setModalRegistro(registro)}>
                            <div className="card-header">
                                <h4>{`Data: ${dataRegistro.toLocaleDateString("pt-BR")} (${registro.dia_da_semana || ''})`}</h4>
                                <p>
                                    <strong>Horas Trabalhadas:</strong> {registro.horas_trabalhadas || "00:00"}
                                </p>
                            </div>
                        </S.Card>
                    );
                })
            )}

            {modalRegistro && (
                <S.ModalOverlay onClick={() => setModalRegistro(null)}>
                    <S.ModalContent onClick={(e) => e.stopPropagation()}>
                        <h3>Detalhes do Registro</h3>
                        <p>Data: {new Date(modalRegistro.data).toLocaleDateString("pt-BR")}</p>
                        <p>Horas Trabalhadas: {modalRegistro.horas_trabalhadas || "00:00"}</p>

                        <h4>Batidas:</h4>
                        {/* Organização de Entrada e Saída */}
                        <div style={{ display: "flex", justifyContent: "space-around", gap: "30px" }}>
                            {/* Coluna Entrada */}
                            <div style={{ textAlign: "center" }}>
                                <h4>Entrada</h4>
                                {modalRegistro.batidas
                                    .filter((_, i) => i % 2 === 0) // Filtra as entradas
                                    .map((batida, index) => (
                                        <p key={`entrada-${index}`}>
                                            {new Date(batida.timestamp).toLocaleTimeString("pt-BR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            })}
                                        </p>
                                    ))}
                            </div>

                            {/* Coluna Saída */}
                            <div style={{ textAlign: "center" }}>
                                <h4>Saída</h4>
                                {modalRegistro.batidas
                                    .filter((_, i) => i % 2 !== 0) // Filtra as saídas
                                    .map((batida, index) => (
                                        <p key={`saida-${index}`}>
                                            {new Date(batida.timestamp).toLocaleTimeString("pt-BR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            })}
                                        </p>
                                    ))}
                            </div>
                        </div>

                        {/* Botão de fechar */}
                        <button onClick={() => setModalRegistro(null)}>Fechar</button>
                    </S.ModalContent>
                </S.ModalOverlay>
            )}




            <Footer />
        </S.Container>
    );
}

export default Ponto;
