import React, { useState, useRef } from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import logi from '../../assets/lOGIN.png';
import loginserver from '../../services/ServerLogin/Loginserver';
import perfil from '../../services/ServerLogin/Perfil';
import BuscarModulo from '../../services/ServerLogin/BuscarModulo';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth } from '../../AuthContext';
import Header from '../../components/Header_login';
import secureStorage from '../../utils/SecureStorage'; // Atualize o caminho conforme necessário
import Footer_login from '../../components/Footer_login';
import './Login.css';

function Login() {
    sessionStorage.clear(); // Alterado para sessionStorage para segurança adicional
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const tipo_app = process.env.REACT_APP_API_TIPO;
    const Focuspassword = useRef(null);
    const Focusnext = useRef(null);
    const Focusemail = useRef(null);
    const navigate = useNavigate();
    const intl = useIntl();
    const { login } = useAuth();

    const handleClick = () => {
        navigate('/home');
    };

    const handleClick2 = () => {
        navigate('/');
    };

    // const validateEmail = (email) => {
    //     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     return re.test(email);
    // };

    // const validatePassword = (password) => {
    //     const re = new RegExp(process.env.REACT_APP_X);
    //     return re.test(password);
    // };

    async function Next() {
        if (!username) return alert(intl.formatMessage({ id: 'login.email_required', defaultMessage: 'O username é Obrigatório' }));
        // if (!validateEmail(username)) return alert(intl.formatMessage({ id: 'login.email_invalid', defaultMessage: 'O e-mail é inválido' }));
        if (!password) return alert(intl.formatMessage({ id: 'login.password_required', defaultMessage: 'O Password é Obrigatório' }));
        // if (!validatePassword(password)) {
        //     return alert(intl.formatMessage({ id: 'login.password_invalid', defaultMessage: 'A senha deve ter entre 8 e 32 caracteres, incluindo letras, números e caracteres especiais' }));
        // }
        
        try {
            const data = await loginserver(username, password, tipo_app);

            secureStorage.setItem("authToken", data.token);
            secureStorage.setItem("id_l", data.id_l);
            secureStorage.setItem("id_r", data.id_r);
            secureStorage.setItem("tipo_app", data.tipo_app);
            secureStorage.setItem("id_unico", data.id_unico);

            try {
                const perfilData = await perfil();

                if (!perfilData) {
                    alert('Erro: a resposta do servidor erfilData é nula');
                    handleClick2(); // Redirecionar para a rota raiz
                    return;
                }

                secureStorage.setItem("_id", perfilData._id);
                secureStorage.setItem("id_ligacao", perfilData.id_ligacao);
                secureStorage.setItem("nomeUser", perfilData.nomeUser);
                secureStorage.setItem("blocked", perfilData.blocked);
                secureStorage.setItem("isVisibleCliente", perfilData.isVisibleCliente);
                secureStorage.setItem("TipoCliente", perfilData.TipoCliente);
                secureStorage.setItem("isVisibleColaborador", perfilData.isVisibleColaborador);
                secureStorage.setItem("TipoColaborador", perfilData.TipoColaborador);
                secureStorage.setItem("isVisibleVisita", perfilData.isVisibleVisita);
                secureStorage.setItem("TipoVisita", perfilData.TipoVisita);
                secureStorage.setItem("isVisibleEstabelecimento", perfilData.isVisibleEstabelecimento);
                secureStorage.setItem("TipoEstabelecimento", perfilData.TipoEstabelecimento);
                secureStorage.setItem("isVisibleCockpit", perfilData.isVisibleCockpit);
                secureStorage.setItem("TipoCockpit", perfilData.TipoCockpit);
                secureStorage.setItem("isVisibleProduto", perfilData.isVisibleProduto);
                secureStorage.setItem("TipoProduto", perfilData.TipoProduto);
                secureStorage.setItem("isVisibleRelatorio", perfilData.isVisibleRelatorio);
                secureStorage.setItem("TipoRelatorio", perfilData.TipoRelatorio);
                secureStorage.setItem("id_ligacao", perfilData.id_ligacao);
                secureStorage.setItem("tipo_emp", perfilData.tipo_emp);
                secureStorage.setItem("isVisiblePonto", perfilData.isVisiblePonto);
                secureStorage.setItem("empresaponto", perfilData.empresa);
                secureStorage.setItem("isVisiblemarketdo", perfilData.isVisiblemarketdo);
                secureStorage.setItem("isVisiblegestaoponto", perfilData.isVisiblegestaoponto);
                secureStorage.setItem("isRede", perfilData.isRede);
                secureStorage.setItem("ponto", JSON.stringify(perfilData.ponto));

                const ModuloData = await BuscarModulo();
                
                
                if (!ModuloData) {
                    alert('Erro: a resposta do servidor ModuloData é nula');
                    handleClick2(); // Redirecionar para a rota raiz
                    return;
                }
                
                // Salvar cada módulo no secureStorage com logs
                const saveModuloToSecureStorage = async (key, value) => {
                    try {
                        await secureStorage.setItem(key, value);
                        const storedValue = await secureStorage.getItem(key);
                        
                    } catch (error) {
                        console.error(`Erro ao salvar ${key}:`, error);
                    }
                };
                
                await saveModuloToSecureStorage("isVisibleModuloCliente", ModuloData.ModuloCliente);
                await saveModuloToSecureStorage("isVisibleModuloColaborador", ModuloData.ModuloColaborador);
                await saveModuloToSecureStorage("isVisibleModuloEstabelecimento", ModuloData.ModuloEstabelecimento);
                await saveModuloToSecureStorage("isVisibleModuloPonto", ModuloData.ModuloPonto);
                await saveModuloToSecureStorage("isVisibleModuloProduto", ModuloData.ModuloProduto);
                await saveModuloToSecureStorage("isVisibleModuloQuadroatendimentos", ModuloData.ModuloQuadroatendimentos);
                await saveModuloToSecureStorage("isVisibleModuloRelatorio", ModuloData.ModuloRelatorio);
                await saveModuloToSecureStorage("isVisibleModuloAtendimentos", ModuloData.ModuloAtendimentos);
                
                

                // Chamar o método de login do contexto
                login();

                handleClick();
            } catch (error) {
                console.error("Erro ao buscar perfil: ", error);
                alert('Erro ao buscar perfil: ' + error.message);
                handleClick2(); // Redirecionar para a rota raiz
            }
        } catch (error) {
            console.error("Erro no login: ", error);

            if (error.response && error.response.status === 401) {
                alert('Usuário ou senha incorretos.');
            } else {
                alert('Erro no login: ' + error.message);
            }
            handleClick2(); // Redirecionar para a rota raiz
        }
    }

    return (
        <S.Container>
             <Header />
            <S.Container2>
{/* 
                <img src={logi} alt="Logo" />
                 */}
                <S.Input>
                    <input name='email' ref={Focusemail} value={username} type="text" placeholder={intl.formatMessage({ id: 'login.email', defaultMessage: 'E-mail' })}
                        maxLength={254}
                        onChange={e => setUsername(e.target.value)}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                Focuspassword.current.focus();
                            }
                        }}
                    />
                </S.Input>
                <S.Input>
                    <input name='password' ref={Focuspassword} value={password} type="password" placeholder={intl.formatMessage({ id: 'login.password', defaultMessage: 'Password' })}
                        onChange={e => setPassword(e.target.value)}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                Next();
                            }
                        }}
                    />
                </S.Input>
                <S.Save>
                    <button type="button" ref={Focusnext} onClick={Next} >
                        <FormattedMessage id="login.enter" defaultMessage="Entrar" />
                    </button>
                </S.Save>
            </S.Container2>
            <Footer_login />
        </S.Container>
    );
}

export default Login;
