import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const BuscarModulo = async () => {
  const apiUrl = process.env.REACT_APP_API_BASE_52;
  const id_r = secureStorage.getItem("id_r");
  try {
    const response = await api.get(`${apiUrl}${id_r}`);
    

    if (response.data?.cadastros) {
      secureStorage.setItem("datarede", JSON.stringify(response.data.cadastros));
      return response.data.cadastros[0]; // Retorna o array de cadastros
    } else {
      console.warn("Nenhum cadastro encontrado.");
      return []; // Retorna um array vazio em caso de ausência de dados
    }
  } catch (error) {
    console.error("Profile fetch error: ", error);
    throw error; // Opcional, caso você queira propagar o erro para o chamador
  }
};

export default BuscarModulo;
