import api from '../api';

const apiUrl = process.env.REACT_APP_API_BASE_54; // Base URL para criar módulos
const apiUrl2 = process.env.REACT_APP_API_BASE_55; // Base URL para atualizar módulos

// Função para criar um novo módulo (POST)
const saveLicenca = async (Licenca) => {
  try {
    const response = await api.post(apiUrl, Licenca);
    return response.data; // Retorna os dados do backend
  } catch (error) {
    console.error('Erro ao salvar o módulo:', error.response?.data || error.message);
    throw new Error(error.response?.data?.error || 'Erro ao salvar o módulo');
  }
};

// Função para atualizar um módulo existente (PUT)
const updateLicenca = async (id, Licenca) => {
  try {
    const response = await api.put(`${apiUrl2}${id}`, Licenca);
    return response.data; // Retorna os dados do backend
  } catch (error) {
    console.error('Erro ao atualizar o módulo:', error.response?.data || error.message);
    throw new Error(error.response?.data?.error || 'Erro ao atualizar o módulo');
  }
};

export default { saveLicenca, updateLicenca };
