import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const BuscarRotinaAtendimento = async () => {
  const apiUrl = process.env.REACT_APP_API_BASE_57; // Certifique-se de que esta variável está definida corretamente

  try {
    const response = await api.get(apiUrl); // Faz a requisição à API
    

    if (response.data && Array.isArray(response.data.cadastros)) {
      // Verifica se o campo "cadastros" existe e é um array
      secureStorage.setItem("datarede", JSON.stringify(response.data.cadastros));
      return response.data.cadastros;
    } else {
      console.warn("Nenhum cadastro encontrado ou formato inválido.");
      return [];
    }
  } catch (error) {
    console.error("Erro ao buscar cadastros:", error.message);
    throw new Error("Erro ao buscar cadastros da API."); // Lança um erro para tratamento externo
  }
};

export default BuscarRotinaAtendimento;
