import api from "../api";
import secureStorage from "../../utils/SecureStorage";

// Função para enviar batidas para o servidor
const enviarBatidas = async (dadosParaEnviar) => {
  const apiUrl = process.env.REACT_APP_API_BASE_32;

  try {
    

    // Envia os dados para a API
    const response = await api.post(`${apiUrl}`, dadosParaEnviar);
    

    // Alerta de sucesso no navegador
    window.alert("SUCESSO: Registros de Ponto enviados com sucesso!");

    // Salva as batidas localmente no SecureStorage (opcional)
    const batidasAtuais = (await secureStorage.getItem("batidas")) || "[]";
    const batidasParseadas = JSON.parse(batidasAtuais);
    batidasParseadas.push(dadosParaEnviar);
    await secureStorage.setItem("batidas", JSON.stringify(batidasParseadas));
  } catch (error) {
    console.error("Erro ao enviar as batidas:", error);

    // Alerta de erro no navegador
    window.alert("ERRO: Falha ao enviar Registros de Ponto. Tente novamente mais tarde.");
  }
};

export default enviarBatidas;
