import api from '../api';

const apiUrl = process.env.REACT_APP_API_BASE_48;
const apiUrl2 = process.env.REACT_APP_API_BASE_49;

// Função para criar uma nova rede (POST)
const saveRedeCnpj = async (rede) => {
  try {
    const response = await api.post(apiUrl, rede,);

    return response.data; // Retorna os dados do backend
  } catch (error) {
    console.error('Erro ao salvar a rede:', error.response?.data || error.message);
    throw new Error(error.response?.data?.error || 'Erro ao salvar a rede');
  }
};

// Função para atualizar uma rede existente (PUT)
const updateRedeCnpj = async (id, payload) => {
  try {
    const response = await api.put(`${apiUrl2}/${id}`, payload, );

    return response.data; // Retorna os dados do backend
  } catch (error) {
    console.error('Erro ao atualizar a rede:', error.response?.data || error.message);
    throw new Error(error.response?.data?.error || 'Erro ao atualizar a rede');
  }
};

export default { saveRedeCnpj,  updateRedeCnpj };
