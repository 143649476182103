import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as S from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CabecarioIcon from '../../components/CabecarioIcon';
import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { MapContainer, TileLayer, Polygon, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Modal from 'react-modal';
import useBuscaEstabelecimento from '../../services/Estabelecimento/useBuscaEstabelecimento';
import EstabelecimentoService from '../../services/Estabelecimento/EstabelecimentoService';
import ViaCepService from '../../services/Viacep/ViaCepService';
import AddressService from '../../services/Viacep/AddressService';
import Del from '../../services/Estabelecimento/del';
import { MascaraCpfCnpj, ValidaCpf, ValidaCnpj, MascaraCep, MascaraTelefone, MascaraTelefoneFixo, ValidaEmail } from '../../utils/mask';
import secureStorage from '../../utils/SecureStorage';
import { useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

Modal.setAppElement('#root');

const CadastroEstabelecimento = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMapModal, setShowMapModal] = useState(false);
  const [polygonPoints, setPolygonPoints] = useState([]);
  const { searchResults, loadregistros } = useBuscaEstabelecimento([]);
  const [erro, setErro] = useState('');
  const intl = useIntl();
  const contentLabel = intl.formatMessage({ id: 'form.buscar_cadastros', defaultMessage: 'Buscar Cadastros' });
  const [nome_fantasia, setNome_fantasia] = useState('');
  const [cpf_cnpj, setCpfCnpj] = useState('');
  const apiUrl = process.env.REACT_APP_API_open;
  const [mapCenter, setMapCenter] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [enderecos, setEnderecos] = useState([]);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const navigate = useNavigate();
  const [estabelecimento, setEstabelecimento] = useState({
    id: '',
    id_rede: secureStorage.getItem("id_r"),
    cpf_cnpj: '',
    nome_fantasia: '',
    Razao_Social: '',
    blocked: false,
    inactive: false,
    endereco: {
      rua: '',
      numero: '',
      complemento: '',
      bairro: '',
      cep: '',
      cidade: '',
      estado: '',
      latitude: '',
      longitude: '',
      poligonoEstabelecimento: []
    },
    contato: {
      telefone_fixo: '',
      celular: '',
      email: '',
      WhatsApp: '',
      observacoes: ''
    },
  });

  const [addressFetched, setAddressFetched] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState(null);

  const handleChange = (e) => {
    let { name, value } = e.target;

    switch (name) {
      case 'contato.celular':
      case 'contato.WhatsApp':
        value = MascaraTelefone(value);
        break;
      case 'contato.telefone_fixo':
        value = MascaraTelefoneFixo(value);
        break;
      case 'endereco.cep':
        value = MascaraCep(value);
        break;
      case 'cpf_cnpj':
        value = MascaraCpfCnpj(value);
        break;
      default:
        break;
    }

    if (name.includes('endereco.') || name.includes('contato.')) {
      const [parentKey, childKey] = name.split('.');
      setEstabelecimento(prevState => ({
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          [childKey]: value
        }
      }));
    } else {
      setEstabelecimento(prevState => ({
        ...prevState,
        [name]: value
      }));
    }

    if (name === 'endereco.numero') {
      setAddressFetched(false);
      setFetchError(false);
    }
  };

  // Função para mover o foco para o próximo campo ou aba
  const moveFocus = (e, nextFieldName) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const nextField = document.getElementsByName(nextFieldName)[0];
      if (nextField) {
        nextField.focus();
      } else {
        // Se não houver próximo campo, muda para a próxima aba
        setActiveTab((prevTab) => {
          const nextTab = prevTab + 1;
          return nextTab >= 3 ? 0 : nextTab; // Cicla entre as abas
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const cpf_cnpj = MascaraCpfCnpj(estabelecimento.cpf_cnpj);

    if (!ValidaCpf(cpf_cnpj) && !ValidaCnpj(cpf_cnpj)) {
      setErro(intl.formatMessage({ id: 'form.error.invalid_cpf_cnpj', defaultMessage: 'CPF/CNPJ inválido' }));
      return;
    }

    if (estabelecimento.contato.email && !ValidaEmail(estabelecimento.contato.email)) {
      setErro(intl.formatMessage({ id: 'form.error.invalid_email', defaultMessage: 'Email inválido' }));
      return;
    }

    if (!estabelecimento.nome_fantasia) {
      alert("Você precisa informar o Nome do Cliente");
      return false;
    }

    // Verifica se a rua do endereço foi informada
    if (!estabelecimento.endereco.rua) {
      alert("Você precisa informar a Rua do Endereço");
      return false;
    }

    // Verifica se o número do endereço foi informado
    if (!estabelecimento.endereco.numero) {
      alert("Você precisa informar o Número do Endereço");
      return false;
    }

    // Verifica se o bairro do endereço foi informado
    if (!estabelecimento.endereco.bairro) {
      alert("Você precisa informar o Bairro do Endereço");
      return false;
    }

    // Verifica se o CEP do endereço foi informado
    if (!estabelecimento.endereco.cep) {
      alert("Você precisa informar o CEP do Endereço");
      return false;
    }

    // Verifica se a cidade do endereço foi informada
    if (!estabelecimento.endereco.cidade) {
      alert("Você precisa informar a Cidade do Endereço");
      return false;
    }

    // Verifica se o estado do endereço foi informado
    if (!estabelecimento.endereco.estado) {
      alert("Você precisa informar o Estado do Endereço");
      return false;
    }

    try {
      await EstabelecimentoService.saveEstabelecimento(estabelecimento);
      handleResetForm();
      setActiveTab(0);
    } catch (error) {
      console.error("Erro ao salvar o estabelecimento:", error);
      setErro(intl.formatMessage({ id: 'form.error.saving', defaultMessage: 'Erro ao salvar o cadastro' }));
    }
  };

  const handleOpenModal = async () => {
    setIsModalOpen(true);
    const idrede = secureStorage.getItem("id_r");
    if (idrede) {
      await loadregistros(idrede);
    }
  };

  const buscarCepEComparar = async () => {
    const { rua, cidade, estado } = estabelecimento.endereco;
  
    // Validação para campos obrigatórios
    if (!rua || !cidade || !estado) {
      const missingFields = [];
      if (!rua) missingFields.push('rua');
      if (!cidade) missingFields.push('cidade');
      if (!estado) missingFields.push('estado');
      alert(`Preencha os seguintes campos antes de buscar: ${missingFields.join(', ')}`);
      return;
    }
  
    try {
      
  
      // Busca os endereços usando o serviço AddressService
      const enderecosEncontrados = await AddressService.fetchCepViaCEP(estado, cidade, rua);
      
  
      if (enderecosEncontrados.length > 0) {
        setEnderecos(enderecosEncontrados); // Armazena os endereços
        setIsAddressModalOpen(true); // Abre o modal
        
      } else {
        alert('Nenhum endereço encontrado para os dados fornecidos.');
      }
    } catch (error) {
      console.error('Erro ao buscar ou comparar o CEP:', error.message);
      alert('Erro verifique o campo rua nao pode comter AV. ');
    }
  };


  const handleOpenMap = () => {
    const { rua, numero, bairro, cidade, estado, poligonoEstabelecimento, latitude, longitude } = estabelecimento.endereco;

    if (latitude && longitude) {
      setPolygonPoints(
        poligonoEstabelecimento && poligonoEstabelecimento.length > 0
          ? poligonoEstabelecimento.map(point => [parseFloat(point[1]), parseFloat(point[0])])
          : []
      );
      setMapCenter({ latitude: parseFloat(latitude), longitude: parseFloat(longitude) });
      setShowMapModal(true);
    } else if (rua && numero && bairro && cidade && estado) {
      fetchLatLong(`${rua}, ${numero}, ${bairro}, ${cidade}, ${estado}`).then(() => {
        if (poligonoEstabelecimento && poligonoEstabelecimento.length > 0) {
          setPolygonPoints(poligonoEstabelecimento.map(point => [parseFloat(point[1]), parseFloat(point[0])]));
        } else {
          setPolygonPoints([]);
        }
        setShowMapModal(true);
      });
    } else {
      alert("Por favor, preencha o endereço completo ou forneça a latitude e longitude antes de abrir o mapa.");
    }
  };



  const handleCloseMap = () => {
    setShowMapModal(false);
  };

  const handleAddPoint = (point) => {
    if (polygonPoints.length < 4) {
      setPolygonPoints((prevPoints) => [...prevPoints, point]);
    }
  };

  const customIcon = new L.Icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconSize: [25, 41], // tamanho do ícone
    iconAnchor: [12, 41], // ponto de ancoragem do ícone
    popupAnchor: [1, -34], // ponto de ancoragem do popup em relação ao ícone
  });

  const handleSavePolygon = () => {
    if (polygonPoints.length === 4) {
      const formattedPolygon = polygonPoints.map(point => [
        parseFloat(point[1]), // Latitude
        parseFloat(point[0])  // Longitude
      ]);

      // Adiciona o ponto inicial ao final para fechar o polígono
      formattedPolygon.push([formattedPolygon[0][0], formattedPolygon[0][1]]);

      setEstabelecimento(prevState => ({
        ...prevState,
        endereco: {
          ...prevState.endereco,
          poligonoEstabelecimento: formattedPolygon
        }
      }));
      setShowMapModal(false);
    } else {
      alert("Por favor, selecione exatamente quatro pontos para formar o polígono.");
    }
  };

  const handleClearPolygon = () => {
    setPolygonPoints([]);
  };

  const handleSearchChange = (event, setSearchTerm) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectEstabelecimento = (estabelecimentoSelecionado) => {
    setEstabelecimento(estabelecimentoSelecionado);
    handleCloseModal();
    setErro('');
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = () => {
    const estabelecimentoId = estabelecimento._id?.$oid;
    Del.excluirEstabelecimento(estabelecimentoId)
      .then(estabelecimentoVazio => {
        setEstabelecimento(estabelecimentoVazio);
        setActiveTab(0);
      })
      .catch(error => {
        console.error('Erro ao excluir o estabelecimento:', error);
      });
  };

  const fetchLatLong = async (address, cep) => {
    const query = cep || address; // Prioriza o CEP
    const url = `${apiUrl}${query}`;
    

    if (!query) {
      console.error('Nenhum endereço ou CEP fornecido.');
      setFetchError('Por favor, insira um endereço ou CEP válido.');
      return;
    }

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Erro na API: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      

      if (data.length > 0 && data[0].lat && data[0].lon) {
        const { lat, lon } = data[0];
        

        setEstabelecimento(prevState => ({
          ...prevState,
          endereco: {
            ...prevState.endereco,
            latitude: lat,
            longitude: lon,
          }
        }));
        setAddressFetched(true);
        setFetchError(false);
      } else {
        console.warn('Nenhum resultado encontrado ou resposta inválida:', data);
        setFetchError('Geolocalização não encontrada. Verifique os dados fornecidos.');
      }
    } catch (error) {
      console.error('Erro na busca por geolocalização:', error.message);
      setFetchError('Erro ao buscar geolocalização. Verifique sua conexão e tente novamente.');
    }
  };



  useEffect(() => {
    const { rua, numero, bairro, cidade, estado, cep, latitude, longitude } = estabelecimento.endereco;

    // Só dispara a busca se latitude e longitude estiverem vazios ou nulos
    if (!latitude && !longitude && rua && numero && bairro && cidade && estado && cep && !addressFetched && !fetchError) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      // Inclui o CEP na composição do endereço para maior precisão
      const address = `${rua}, ${numero}, ${bairro}, ${cidade}, ${estado}`;
      setDebounceTimer(setTimeout(() => fetchLatLong(address), 800)); // Ajuste no delay para 500ms
    }
  }, [estabelecimento.endereco, addressFetched, fetchError]);


  const onBlurCep = async (e) => {
    let { value } = e.target;
    const cep = value.replace(/\D/g, '');

    if (cep.length !== 8) {
      return;
    }

    try {
      const data = await ViaCepService.fetchAddress(cep);
      if (data.erro) {
        alert('CEP não encontrado.');
        return;
      }
      setEstabelecimento(prevState => ({
        ...prevState,
        endereco: {
          ...prevState.endereco,
          rua: data.logradouro,
          bairro: data.bairro,
          cidade: data.localidade,
          estado: data.uf,
          cep: value,
        }
      }));
      setAddressFetched(false);
      setFetchError(false);
    } catch (error) {
      alert('Erro ao buscar CEP: ' + error.message);
    }
  };

  const handleResetForm = () => {
    setEstabelecimento({
      id: '',
      id_rede: secureStorage.getItem("id_r"),
      cpf_cnpj: '',
      nome_fantasia: '',
      Razao_Social: '',
      blocked: false,
      inactive: false,
      endereco: {
        rua: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        cidade: '',
        estado: '',
        latitude: '',
        longitude: '',
        poligonoEstabelecimento: []
      },
      contato: {
        telefone_fixo: '',
        celular: '',
        email: '',
        WhatsApp: '',
        observacoes: ''
      },
    });
    setActiveTab(0);
    setAddressFetched(false);
    setFetchError(false);
    setDebounceTimer(null);
  };

  const handleSelectEndereco = (enderecoSelecionado) => {
   

    setEstabelecimento((prevState) => ({
      ...prevState,
      endereco: {
        ...prevState.endereco,
        rua: enderecoSelecionado.logradouro,
        bairro: enderecoSelecionado.bairro,
        cidade: enderecoSelecionado.localidade,
        estado: enderecoSelecionado.uf,
        cep: enderecoSelecionado.cep,
      },
    }));

    setIsAddressModalOpen(false); // Fecha o modal
  };


  const ModalEndereco = ({ isOpen, enderecos, onClose, onSelect }) => (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Selecione um endereço"
      style={{
        content: {
          width: '80%',
          height: '80%',
          margin: 'auto',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          position: 'relative', // Necessário para posicionar o botão
          overflow: 'hidden', // Remove a barra de rolagem externa
          top: '10%', // Ajuste da posição vertical
        },
      }}
    >
      <S.ModalWrapper>
        <S.CloseButton onClick={onClose}>Fechar</S.CloseButton>
        <S.ModalHeader>Selecione o endereço correto</S.ModalHeader>
        {enderecos.length > 0 ? (
          <S.TableWrapper>
            <S.StyledTable2>
              <thead>
                <tr>
                  <th>Logradouro</th>
                  <th>Bairro</th>
                  <th>Cidade</th>
                  <th>CEP</th>
                  <th>Complemento</th>
                </tr>
              </thead>
              <tbody>
                {enderecos.map((endereco, index) => (
                  <tr
                    key={index}
                    onClick={() => onSelect(endereco)}
                    style={{
                      cursor: 'pointer',
                      transition: 'background-color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f1f1f1')}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
                  >
                    <td>{endereco.logradouro || '-'}</td>
                    <td>{endereco.bairro || '-'}</td>
                    <td>{endereco.localidade || '-'}</td>
                    <td>{endereco.cep || '-'}</td>
                    <td>{endereco.complemento || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </S.StyledTable2>
          </S.TableWrapper>
        ) : (
          <S.EmptyMessage>Nenhum endereço encontrado.</S.EmptyMessage>
        )}
      </S.ModalWrapper>
    </Modal>
  );

  return (
    <>
      <Header />
      <S.BackButton onClick={() => navigate('/home')}>
                <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
                Voltar
            </S.BackButton>
      <S.Container>
        <S.Form onSubmit={handleSubmit}>
          <S.Title>
            <FormattedMessage id="form.titleEsta" defaultMessage="Cadastro de Estabelecimento" />
          </S.Title>
          <CabecarioIcon titleEsta="Cadastro de Estabelecimento" />
          <Tabs selectedIndex={activeTab} onSelect={(index) => setActiveTab(index)}>
            <S.StyledTabList>
              <S.StyledTab>
                <FormattedMessage id="tabs.Estabelecimento" defaultMessage="Estabelecimento" />
              </S.StyledTab>
              <S.StyledTab>
                <FormattedMessage id="tabs.address" defaultMessage="Endereço" />
              </S.StyledTab>
              <S.StyledTab>
                <FormattedMessage id="tabs.contact" defaultMessage="Contato" />
              </S.StyledTab>
            </S.StyledTabList>
            <TabPanel>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.nameFantasia" defaultMessage="Nome Fantasia:*" />
                </S.Label>
                <S.Input
                  name="nome_fantasia"
                  value={estabelecimento.nome_fantasia}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'Razao_Social')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.Razao_Social" defaultMessage="Razao Social:" />
                </S.Label>
                <S.Input
                  name="Razao_Social"
                  value={estabelecimento.Razao_Social}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'cpf_cnpj')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cpf_cnpj" defaultMessage="CPF/CNPJ:" />
                </S.Label>
                <S.Input
                  name="cpf_cnpj"
                  value={estabelecimento.cpf_cnpj}
                  onChange={handleChange}
                  maxLength={18}
                  onKeyDown={(e) => moveFocus(e, '')} // Último campo da aba, moverá para a próxima aba
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.CheckboxContainer>
                  <S.Label>Bloquear:</S.Label>
                  <S.Checkbox name="blocked" type="checkbox" checked={estabelecimento.blocked} onChange={(e) => setEstabelecimento({ ...estabelecimento, blocked: e.target.checked })} />
                </S.CheckboxContainer>
              </S.InputGroup>
            </TabPanel>

            <TabPanel>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cep" defaultMessage="Cep:" />
                </S.Label>
                <S.Input
                  name="endereco.cep"
                  value={estabelecimento.endereco.cep}
                  onChange={handleChange}
                  onBlur={onBlurCep}
                  maxLength={9}
                  onKeyDown={(e) => moveFocus(e, 'endereco.rua')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.rua" defaultMessage="Rua:" />
                </S.Label>
                <S.Input
                  name="endereco.rua"
                  value={estabelecimento.endereco.rua}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'endereco.numero')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.numero" defaultMessage="Numero:" />
                </S.Label>
                <S.Input
                  name="endereco.numero"
                  value={estabelecimento.endereco.numero}
                  onChange={handleChange}
                  maxLength={10}
                  onBlur={() => {
                    setAddressFetched(false);

                  }}
                  onKeyDown={(e) => moveFocus(e, 'endereco.complemento')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.complemento" defaultMessage="Complemento:" />
                </S.Label>
                <S.Input
                  name="endereco.complemento"
                  value={estabelecimento.endereco.complemento}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'endereco.bairro')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.bairro" defaultMessage="Bairro:" />
                </S.Label>
                <S.Input
                  name="endereco.bairro"
                  value={estabelecimento.endereco.bairro}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'endereco.cidade')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cidade" defaultMessage="Cidade:" />
                </S.Label>
                <S.Input
                  name="endereco.cidade"
                  value={estabelecimento.endereco.cidade}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'endereco.estado')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.estado" defaultMessage="Estado:" />
                </S.Label>
                <S.Input
                  name="endereco.estado"
                  value={estabelecimento.endereco.estado}
                  onChange={handleChange}
                  maxLength={2}
                  onKeyDown={(e) => moveFocus(e, 'endereco.latitude')} // Último campo da aba, moverá para a próxima aba
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.latitude" defaultMessage="Latitude:" />
                </S.Label>
                <S.Input
                  name="endereco.latitude"
                  value={estabelecimento.endereco.latitude}
                  onChange={handleChange}
                  pattern="^-?[0-9]*[.,]?[0-9]*$"
                  onKeyDown={(e) => moveFocus(e, 'endereco.longitude')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.longitude" defaultMessage="Longitude:" />
                </S.Label>
                <S.Input
                  name="endereco.longitude"
                  value={estabelecimento.endereco.longitude}
                  onChange={handleChange}
                  pattern="^-?[0-9]*[.,]?[0-9]*$"
                  onKeyDown={(e) => moveFocus(e, '')}
                />
              </S.InputGroup>
              <S.Button type="button" onClick={handleOpenMap}>
                Abrir Mapeamento
              </S.Button>
              <S.Button
                type="button"
                onClick={() => {
                  // Garante que o estado está sincronizado antes de chamar a função
                  setEstabelecimento((prevState) => ({
                    ...prevState,
                    endereco: {
                      ...prevState.endereco,
                      rua: prevState.endereco.rua.trim(),
                      cidade: prevState.endereco.cidade.trim(),
                      estado: prevState.endereco.estado.trim(),
                    },
                  }));

                  // Garante a execução com os valores atualizados
                  setTimeout(() => buscarCepEComparar(), 100);
                }}
                style={{ marginLeft: '10px' }}
              >
                Selecionar Endereço
              </S.Button>

            </TabPanel>

            <TabPanel>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.telefone_fixo" defaultMessage="Telefone fixo:" />
                </S.Label>
                <S.Input
                  name="contato.telefone_fixo"
                  value={estabelecimento.contato.telefone_fixo}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'contato.celular')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.celular" defaultMessage="Celular:" />
                </S.Label>
                <S.Input
                  name="contato.celular"
                  value={estabelecimento.contato.celular}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'contato.WhatsApp')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.whatsapp" defaultMessage="WhatsApp:" />
                </S.Label>
                <S.Input
                  name="contato.WhatsApp"
                  value={estabelecimento.contato.WhatsApp}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'contato.email')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.email" defaultMessage="Email:" />
                </S.Label>
                <S.Input
                  name="contato.email"
                  value={estabelecimento.contato.email}
                  onChange={handleChange}
                  maxLength={255}
                  onKeyDown={(e) => moveFocus(e, 'contato.observacoes')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.observacoes" defaultMessage="Observações:" />
                </S.Label>
                <S.TextArea
                  name="contato.observacoes"
                  value={estabelecimento.contato.observacoes}
                  onChange={handleChange}
                  maxLength={250}
                  onKeyDown={(e) => moveFocus(e, '')}
                />
              </S.InputGroup>
            </TabPanel>
          </Tabs>
          {erro && <S.Erro>{erro}</S.Erro>}
          <S.ButtonGroup>
            <S.Button type="button" onClick={handleOpenModal}>
              <FormattedMessage id="form.buscar_cadastros" defaultMessage="Buscar Cadastros" />
            </S.Button>
            {secureStorage.getItem("TipoEstabelecimento") !== 1 && (
              <S.Button type="submit">
                <FormattedMessage
                  id={estabelecimento._id ? "form.salvar" : "form.cadastrar"}
                  defaultMessage={estabelecimento._id ? "Salvar" : "Cadastrar"}
                />
              </S.Button>
            )}
            {estabelecimento._id && secureStorage.getItem("TipoEstabelecimento") === 3 && (
              <S.Button type="button" onClick={handleDelete}>
                <FormattedMessage id="form.excluir" defaultMessage="Excluir Cadastro" />
              </S.Button>
            )}
            {secureStorage.getItem("TipoEstabelecimento") !== 1 && (
              <S.Button type="button" onClick={handleResetForm}>
                <FormattedMessage id="form.novo" defaultMessage="Novo" />
              </S.Button>
            )}
          </S.ButtonGroup>
        </S.Form>
        <S.StyledModal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel={contentLabel}
          ariaHideApp={false}
        >
          <S.Input
            type="text"
            placeholder="Digite o nome para buscar..."
            value={nome_fantasia}
            onChange={(e) => handleSearchChange(e, setNome_fantasia)}
            style={{ marginBottom: '10px' }}
          />
          <S.Input
            type="text"
            placeholder="Digite o CPF/CNPJ para buscar..."
            value={cpf_cnpj}
            onChange={(e) => handleSearchChange(e, setCpfCnpj)}
          />
          <S.ModalContent>
            {Array.isArray(searchResults) && searchResults.length > 0 ? (
              <S.StyledTable>
                <thead>
                  <tr>
                    <th>Nome_fantasia</th>
                    <th>Razao_Social</th>
                    <th>cpf_cnpj</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults
                    .filter(estabelecimento =>
                      estabelecimento.nome_fantasia.toLowerCase().includes(nome_fantasia.toLowerCase()) &&
                      estabelecimento.cpf_cnpj.toLowerCase().includes(cpf_cnpj.toLowerCase())
                    )
                    .map((estabelecimento) => (
                      <tr key={estabelecimento.cpf_cnpj} onClick={() => handleSelectEstabelecimento(estabelecimento)}>
                        <td>{estabelecimento.nome_fantasia}</td>
                        <td>{estabelecimento.Razao_Social}</td>
                        <td>{estabelecimento.cpf_cnpj}</td>
                      </tr>
                    ))}
                </tbody>
              </S.StyledTable>
            ) : (
              <p>Nenhum resultado encontrado.</p>
            )}
          </S.ModalContent>
          <button onClick={handleCloseModal}>Fechar</button>
        </S.StyledModal>

        {/* Modal com o mapa */}
        <Modal
          isOpen={showMapModal}
          onRequestClose={handleCloseMap}
          contentLabel="Selecione o Polígono"
          style={{ content: { width: '80%', height: '80%', margin: 'auto' } }}
        >
          <h3>Selecione quatro pontos no mapa para definir a área do estabelecimento</h3>
          <MapContainer
            center={[estabelecimento.endereco.latitude || -24.954257, estabelecimento.endereco.longitude || -53.467380]}
            zoom={20}
            style={{ height: '400px', width: '100%' }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Polygon positions={polygonPoints} />
            {polygonPoints.map((point, index) => (
              <Marker key={index} position={point} icon={customIcon}>
                <Popup>Ponto {index + 1}</Popup>
              </Marker>
            ))}
            <MapClickHandler onAddPoint={handleAddPoint} />
          </MapContainer>
          <S.Button onClick={handleSavePolygon}>Salvar Mapeamento</S.Button>
          <S.Button onClick={handleClearPolygon}>Limpar Mapeamento</S.Button>
          <S.Button onClick={handleCloseMap}>Fechar</S.Button>
        </Modal>

        <ModalEndereco
          isOpen={isAddressModalOpen}
          enderecos={enderecos}
          onClose={() => setIsAddressModalOpen(false)}
          onSelect={handleSelectEndereco}
        />

      </S.Container>
      <Footer />
    </>
  );
};

const MapClickHandler = ({ onAddPoint }) => {
  useMapEvents({
    click: (e) => {
      onAddPoint([e.latlng.lat, e.latlng.lng]);
    }
  });
  return null;
};

export default CadastroEstabelecimento;
