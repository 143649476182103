import { useState } from 'react';
import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const BuscaEmpreCnpj = () => {
  const [loading, setLoading] = useState(false);
  const [searchResults4, setSearchResults4] = useState([]);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_BASE_25;

  const loadregistros4 = async (id_rede) => {
    setLoading(true);
    try {
      const response = await api.get(`${apiUrl}${id_rede}`);
      if (response.data && response.data.cadastros) {
        // Atualiza o estado com os novos dados
        secureStorage.setItem("dataredecnpj", JSON.stringify(response.data.cadastros));
        setSearchResults4(response.data.cadastros); // Define corretamente os resultados
        
      } else {
        setError("Erro ao buscar cadastros. Veja o console para mais detalhes.");
        setSearchResults4([]); // Limpa os resultados
      }
    } catch (error) {
      setError("Erro ao buscar cadastros. Veja o console para mais detalhes.");
      setSearchResults4([]); // Limpa os resultados anteriores
    } finally {
      setLoading(false);
    }
  };

  return { loading, searchResults4, error, loadregistros4 };
};

export default BuscaEmpreCnpj;
