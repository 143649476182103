import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 150px;

    @media (max-width: 768px) {
        padding-top: 120px;
    }
`;

export const Form = styled.form`
    width: 100%;
    max-width: 600px;
    background: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #cf0505;
    margin: 0 auto;

    @media (max-width: 768px) {
        max-width: 90%;
        padding: 15px;
    }

    @media (max-width: 480px) {
        max-width: 100%;
        padding: 10px;
    }
`;

export const Title = styled.h1`
    color: #cf0505;
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
`;

export const InputGroup = styled.div`
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
`;

export const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    color: #cf0505;
`;

export const Select = styled.select`
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;

    &:focus {
        border-color: #003366;
        outline: none;
    }
`;

export const NumberInput = styled.input`
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    -moz-appearance: textfield;

    &:focus {
        border-color: #003366;
        outline: none;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px; /* Espaçamento entre os botões */
    width: 100%;
    margin-top: 20px;

    @media (max-width: 768px) {
        flex-direction: column; /* Empilha os botões em telas menores */
        align-items: center;
    }
`;

export const Button = styled.button`
    flex: 1; /* Faz os botões terem o mesmo tamanho */
    max-width: 150px; /* Define uma largura máxima para evitar botões muito largos */
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #003366; /* Mantém a cor original */
    color: white;
    font-size: 16px;
    cursor: pointer;
    text-align: center;

    &:hover {
        background-color: #002244; /* Mantém a cor de hover original */
    }

    @media (max-width: 768px) {
        max-width: 100%; /* Em telas menores, ocupa 100% da largura */
    }
`;

export const BackButton = styled.button`
    position: absolute;
    top: 80px;
    left: 20px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #B22222;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: #800000;
    }
`;

export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 10px 20px;

    h2 {
        margin: 0;
        font-size: 18px;
        color: #cf0505;
    }

    button {
        background: none;
        border: none;
        color: #cf0505;
        font-size: 16px;
        cursor: pointer;

        &:hover {
            color: #800000;
        }
    }
`;

export const SearchInput = styled.input`
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;

    &:focus {
        border-color: #003366;
        outline: none;
    }
`;

export const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;

    thead {
        background-color: #f5f5f5;

        tr {
            border-bottom: 2px solid #ddd;
        }

        th {
            text-align: left;
            padding: 10px;
            color: #333;
            font-size: 14px;
        }
    }

    tbody {
        tr {
            cursor: pointer;
            transition: background-color 0.2s;

            &:hover {
                background-color: #f9f9f9;
            }
        }

        td {
            padding: 10px;
            border-bottom: 1px solid #ddd;
            font-size: 14px;
        }
    }
`;

export const ListContainer = styled.div`
    width: 100%; /* Garante que ocupe toda a largura do container pai */
    margin-top: 20px;
    padding: 10px;
    background: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 300px; /* Define a altura máxima da lista */
    overflow-y: auto; /* Adiciona a barra de rolagem vertical se necessário */

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            display: grid; /* Usa grid para separar nome e botão */
            grid-template-columns: 1fr auto; /* Nome ocupa a maior parte, botão ajusta automaticamente */
            align-items: center; /* Centraliza os itens verticalmente */
            padding: 10px; /* Espaçamento interno */
            border-bottom: 1px solid #ddd; /* Linha divisória */

            span {
                text-align: left; /* Alinha o texto à esquerda */
                word-wrap: break-word; /* Quebra linhas se necessário */
            }

            button {
                padding: 5px 15px; /* Ajusta o tamanho do botão */
                font-size: 14px; /* Ajusta o tamanho do texto no botão */
                cursor: pointer;
                background-color: #003366;
                color: white;
                border: none;
                border-radius: 5px;

                &:hover {
                    background-color: #002244;
                }
            }

            &:last-child {
                border-bottom: none; /* Remove a linha divisória do último item */
            }
        }
    }
`;
