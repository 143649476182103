import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;  
`;

export const FilterArea = styled.div`
  display: flex;
  justify-content: center;  
  margin-top: 70px;
  flex-wrap: wrap;

  button {
    background: none;
    border: none;
    margin: 5px;
  }
  img {
    width: 25px;
    height: 25px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FilterCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  background: ${props => props.actived === 'true' ? '#B22222' : '#20295F'}; // Azul escuro para não ativos
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;

  span {
    color: #FFF;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    word-wrap: break-word;
  }

  @media (max-width: 768px) {
    width: 90%;
    margin: 5px auto;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 70px;

  a {
    text-decoration: none;  
    color: #000;
  }

  .input {
    padding: 5px 15px;
    width: 150px;
    margin: 5px;
  }

  @media (max-width: 768px) {
    .input {
      width: 90%;
      margin: 5px auto;
    }
  }
`;

export const Content2 = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 70px;

  a {
    text-decoration: none;  
    color: #000;
  }

  .input {
    padding: 5px 15px;
    width: 200px;
    margin: 5px;
  }

  @media (max-width: 768px) {
    .input {
      width: 90%;
      margin: 5px auto;
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  border-bottom: 1px solid #FF0000;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  h3 {
    color: #20295F;
    position: relative;
    top: 30px;
    background: #FFF;
    padding: 0 20px;
  }
  img {
    width: 25px;
    height: 25px;
  }

  @media (max-width: 768px) {
    h3 {
      top: 10px;
      font-size: 18px;
    }
  }
`;

export const Title2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  span {
    display: flex;
    top: 20px;
    padding: 0 10px;
  }

  img {
    width: 20px;
    height: 20px;
  }

  a {
    top: 20px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    a {
      top: 10px;
      padding: 5px 0;
    }

    span {
      top: 10px;
      padding: 5px 0;
    }
  }
`;

export const VisitaCard = styled.div`
  width: 300px;
  height: 250px;
  background: ${props => {
    switch (props.type) {
      case 0:
        return "#FFFFFF"; // não iniciado
      case 1:
        return "#87CEFA"; // iniciado
      case 2:
        return "#81C784"; // concluído
      case 3:
        return "#FF8A80"; // atrasado
      default:
        return "#FFF";
    }
  }};
  border: 1px solid #20295F;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const VisitaCardTitle = styled.h4`
  margin: 0;
  padding: 0;
  color: #20295F;
`;

export const VisitaCardParagraph = styled.p`
  margin: 5px 0;
  color: #20295F;
  font-size: 14px;
`;

export const VisitaCardIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const EditButton = styled.button`
  background-color: #FFA500;
  color: #FFF;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  align-self: flex-end;

  &:hover {
    background-color: #FF8C00;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const ViewButton = styled.button`
  background-color: #4CAF50;
  color: #FFF;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  align-self: flex-end;
  margin-left: 10px;

  &:hover {
    background-color: #45A049;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;
export const BackButton = styled.button`
    position: absolute;
    top: 100px;
    left: 20px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #cf0505;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: #800000;
    }
`;         