import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

// Componentes
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ModuloService from '../../services/Modulo/ModuloService';
import BuscarModulo from '../../services/Modulo/BuscarModulo';

// Serviço
import BuscaRedes from '../../services/Marketdo/BuscaRedes';

function CadastroRede() {
    const [redes, setRedes] = useState([]);
    const [redeSelecionada, setRedeSelecionada] = useState('');
    const [modulos, setModulos] = useState({
        id_rede: '',
        _id: '', // Adicionado para capturar o _id do registro
        ModuloAtendimentos: false,
        ModuloCliente: false,
        ModuloColaborador: false,
        ModuloEstabelecimento: false,
        ModuloPonto: false,
        ModuloProduto: false,
        ModuloQuadroatendimentos: false,
        ModuloRelatorio: false,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [modulosBusca, setModulosBusca] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchRedes = async () => {
            try {
                const buscaRedes = new BuscaRedes();
                await buscaRedes.loadRede();
                const redesResultado = buscaRedes.getResultadoRede();
               
                setRedes(redesResultado);
            } catch (error) {
                console.error('Erro ao buscar redes:', error);
            }
        };

        fetchRedes();
    }, []);

    const handleSelectRede = (e) => {
        const selectedId = e.target.value;
        
        setRedeSelecionada(selectedId);

        setModulos((prevState) => ({
            ...prevState,
            id_rede: selectedId,
        }));
    };

    const toggleModulo = (modulo) => {
       
        setModulos((prevState) => ({
            ...prevState,
            [modulo]: !prevState[modulo],
        }));
    };

    const openModal = async () => {
        setIsModalOpen(true);
       

        try {
            const response = await BuscarModulo();
            
            const modulos = response || [];

            setModulosBusca(modulos);
        } catch (error) {
            console.error('Erro ao buscar módulos:', error);
            alert('Erro ao carregar os módulos. Tente novamente.');
            setModulosBusca([]);
        }
    };

    const closeModal = () => {
        
        setIsModalOpen(false);
        setSearchTerm('');
    };

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        
        setSearchTerm(term);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!redeSelecionada) {
            alert('Por favor, selecione uma rede antes de salvar.');
            return;
        }
    
        try {
            let response;
            if (modulos._id) {
                // Atualiza o módulo existente (PUT)
                response = await ModuloService.updateModulo(modulos._id, modulos);
                
                alert('Módulo atualizado com sucesso!');
            } else {
                // Cria um novo módulo (POST)
                response = await ModuloService.saveModulo(modulos);
                
                alert('Módulo salvo com sucesso!');
            }
    
            // Limpa os dados do formulário após salvar ou atualizar
            setModulos({
                id_rede: '',
                _id: '',
                ModuloAtendimentos: false,
                ModuloCliente: false,
                ModuloColaborador: false,
                ModuloEstabelecimento: false,
                ModuloPonto: false,
                ModuloProduto: false,
                ModuloQuadroatendimentos: false,
                ModuloRelatorio: false,
            });
            setRedeSelecionada('');
        } catch (error) {
            console.error('Erro ao salvar ou atualizar os dados:', error.message);
            if (error.message.includes('Já existe um cadastro com esse id_rede')) {
                alert('Erro: Já existe um cadastro com esse ID de rede.');
            } else {
                alert('Erro ao salvar ou atualizar os dados. Verifique o console para mais detalhes.');
            }
        }
    };
    
      

    const handleBack = () => {
        navigate('/Marketdo');
    };

    const handleSelectModule = (modulo) => {
       
    
        // Atualiza os módulos
        setModulos({
            id_rede: modulo.id_rede,
            _id: modulo._id?.$oid, // Salva o _id do registro
            ModuloAtendimentos: modulo.ModuloAtendimentos,
            ModuloCliente: modulo.ModuloCliente,
            ModuloColaborador: modulo.ModuloColaborador,
            ModuloEstabelecimento: modulo.ModuloEstabelecimento,
            ModuloPonto: modulo.ModuloPonto,
            ModuloProduto: modulo.ModuloProduto,
            ModuloQuadroatendimentos: modulo.ModuloQuadroatendimentos,
            ModuloRelatorio: modulo.ModuloRelatorio,
        });
    
        // Atualiza a rede selecionada para exibir no Select
        setRedeSelecionada(modulo.id_rede);
    
        closeModal();
    };

    const getNomeRede = (idRede) => {
        const rede = redes.find((r) => r._id.$oid === idRede);
        return rede ? rede.nome_fantasia : 'N/A';
    };

    return (
        <>
            <Header />
            <S.BackButton onClick={handleBack}>
                <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
                Voltar
            </S.BackButton>
            <S.Container>
                <S.Form onSubmit={handleSubmit}>
                    <S.Title>Cadastro de Módulos</S.Title>

                    <S.InputGroup>
                        <S.Label>Selecione uma Rede:</S.Label>
                        <S.Select value={redeSelecionada} onChange={handleSelectRede}>
                            <option value="">Selecione...</option>
                            {redes.map((rede) => (
                                <option key={rede._id.$oid} value={rede._id.$oid}>
                                    {rede.nome_fantasia}
                                </option>
                            ))}
                        </S.Select>
                    </S.InputGroup>

                    <S.Fieldset>
                        <S.Legend>Módulos Disponíveis</S.Legend>
                        {Object.keys(modulos)
                            .filter((modulo) => modulo !== 'id_rede' && modulo !== '_id')
                            .map((modulo) => (
                                <S.InputGroup key={modulo}>
                                    <S.ToggleContainer>
                                        <S.ToggleSwitch
                                            isActive={modulos[modulo]}
                                            onClick={() => toggleModulo(modulo)}
                                        />
                                        <S.ToggleLabel>
                                            {modulo.replace(/Modulo/, '').replace(/_/g, ' ').toUpperCase()}
                                        </S.ToggleLabel>
                                    </S.ToggleContainer>
                                </S.InputGroup>
                            ))}
                    </S.Fieldset>

                    <S.ButtonGroup style={{ gap: '10px' }}>
                        <S.Button type="submit">Salvar</S.Button>
                        <S.Button
                            type="reset"
                            onClick={() =>
                                setModulos({
                                    id_rede: redeSelecionada,
                                    _id: '',
                                    ModuloAtendimentos: false,
                                    ModuloCliente: false,
                                    ModuloColaborador: false,
                                    ModuloEstabelecimento: false,
                                    ModuloPonto: false,
                                    ModuloProduto: false,
                                    ModuloQuadroatendimentos: false,
                                    ModuloRelatorio: false,
                                })
                            }
                        >
                            Limpar
                        </S.Button>
                        <S.Button type="button" onClick={openModal}>Buscar Módulo</S.Button>
                    </S.ButtonGroup>
                </S.Form>
            </S.Container>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                ariaHideApp={false}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxHeight: '70vh',
                        overflowY: 'auto',
                    },
                }}
            >
                <S.ModalHeader>
                    <h2>Módulos</h2>
                    <button onClick={closeModal}>Fechar</button>
                </S.ModalHeader>
                <S.SearchInput
                    type="text"
                    placeholder="Digite o nome do módulo para buscar..."
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <S.TableContainer>
                    <S.Table>
                        <thead>
                            <tr>
                                <th>Nome da Rede</th>
                                <th>Atendimentos</th>
                                <th>Cliente</th>
                                <th>Colaborador</th>
                                <th>Estabelecimento</th>
                                <th>Ponto</th>
                                <th>Produto</th>
                                <th>Quadro Atendimentos</th>
                                <th>Relatório</th>
                            </tr>
                        </thead>
                        <tbody>
                            {modulosBusca.length > 0 ? (
                                modulosBusca.map((modulo) => (
                                    <tr
                                        key={modulo._id?.$oid}
                                        onClick={() => handleSelectModule(modulo)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <td>{getNomeRede(modulo.id_rede)}</td>
                                        <td>{modulo.ModuloAtendimentos ? 'Ativo' : 'Inativo'}</td>
                                        <td>{modulo.ModuloCliente ? 'Ativo' : 'Inativo'}</td>
                                        <td>{modulo.ModuloColaborador ? 'Ativo' : 'Inativo'}</td>
                                        <td>{modulo.ModuloEstabelecimento ? 'Ativo' : 'Inativo'}</td>
                                        <td>{modulo.ModuloPonto ? 'Ativo' : 'Inativo'}</td>
                                        <td>{modulo.ModuloProduto ? 'Ativo' : 'Inativo'}</td>
                                        <td>{modulo.ModuloQuadroatendimentos ? 'Ativo' : 'Inativo'}</td>
                                        <td>{modulo.ModuloRelatorio ? 'Ativo' : 'Inativo'}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="9">Nenhum módulo encontrado.</td>
                                </tr>
                            )}
                        </tbody>
                    </S.Table>
                </S.TableContainer>
            </Modal>

            <Footer />
        </>
    );
}

export default CadastroRede;
