import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import BuscaRedes from '../../services/Marketdo/BuscaRedes';
import { MdArrowBack } from 'react-icons/md';

// Componentes
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HomeCard from '../../components/HomeCard';
import HomeCabecario from '../../components/HomeCabecario';
import Cliente from '../../assets/business_tech.png';
import rede from '../../assets/finance.png';
import Modulos from '../../assets/tools.png';
import CNPJs from '../../assets/network_and.png';
import phone from '../../assets/phone_mobile.png';
import system  from '../../assets/system.png';

// Utilitários
import secureStorage from '../../utils/SecureStorage';

function Marketdo() {
    const navigate = useNavigate();
    const [redes, setRedes] = useState([]);
    const [selectedRede, setSelectedRede] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Função para buscar as redes
    const fetchRedes = async () => {
        setIsLoading(true); // Inicia o carregamento
        const buscaRedes = new BuscaRedes();
        try {
            await buscaRedes.loadRede();
            const resultado = buscaRedes.getResultadoRede();
            setRedes(resultado);
            
        } catch (error) {
            console.error("Erro ao buscar redes:", error);
        } finally {
            setIsLoading(false); // Finaliza o carregamento
        }
    };

    // Função para abrir o modal
    const openModal = () => {
        fetchRedes();
        setModalOpen(true);
    };

    // Função para fechar o modal
    const closeModal = () => {
        setModalOpen(false);
    };

    // Função para setar a rede selecionada e atualizar a tela
    const setRede = () => {
        if (selectedRede) {
            secureStorage.removeItem("id_r"); // Remove o ID antigo
            secureStorage.setItem("id_r", selectedRede); // Define o novo ID
           
            closeModal();
            navigate(0); // Recarrega a página
        } else {
            console.warn("Nenhuma rede selecionada!");
        }
    };
    
    const handleBack = () => {
        navigate('/home');
    };

    return (
        <S.Container>
            <Header />
            <HomeCabecario title="MarketDo ADM" />
            <S.BackButton onClick={handleBack}>
                            <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
                            Voltar
                        </S.BackButton>
            <S.HomeCardArea>
                <button type="button" onClick={openModal}>
                    <HomeCard
                        title={<FormattedMessage id="home.setar_rede" defaultMessage="Rede" />}
                        icon={Cliente}
                    />
                </button>
                
                    <button type="button" onClick={() => navigate('/Rede')}>
                        <HomeCard title={<FormattedMessage id="home.cadRede" defaultMessage="Cadastro Rede" />} icon={rede} type="button" />
                    </button>
                    <button type="button" onClick={() => navigate('/Modulos')}>
                        <HomeCard title={<FormattedMessage id="home.modeRede" defaultMessage="Acessos Modulos" />} icon={Modulos} type="button" />
                    </button>
                    <button type="button" onClick={() => navigate('/CNPJsRede')}>
                        <HomeCard title={<FormattedMessage id="home.CNPJsRede" defaultMessage="CNPJs rede" />} icon={CNPJs} type="button" />
                    </button>
                    <button type="button" onClick={() => navigate('/Licenca')}>
                        <HomeCard title={<FormattedMessage id="home.Licenca" defaultMessage="Licenças rede" />} icon={phone} type="button" />
                    </button>

                    <button type="button" onClick={() => navigate('/RotinaPonto')}>
                        <HomeCard title={<FormattedMessage id="home.RotinaPonto" defaultMessage="Rotina Ponto" />} icon={system} type="button" />
                    </button>
                    <button type="button" onClick={() => navigate('/RotinaAtendimento')}>
                        <HomeCard title={<FormattedMessage id="home.RotinaAtendimento" defaultMessage="Rotina Atendimento" />} icon={system} type="button" />
                    </button>
                
            </S.HomeCardArea>

            {/* Modal Inline */}
            {modalOpen && (
                <S.ModalOverlay>
                    <S.ModalContent>
                        <h2><FormattedMessage id="modal.selecione_rede" defaultMessage="Selecione uma Rede" /></h2>
                        
                        {isLoading ? (
                            <p><FormattedMessage id="modal.loading" defaultMessage="Carregando redes..." /></p>
                        ) : (
                            <select
                                value={selectedRede}
                                onChange={(e) => setSelectedRede(e.target.value)}
                            >
                                <option value="">Selecione...</option>
                                {redes.length > 0 ? (
                                    redes.map((rede) => (
                                        <option key={rede._id?.$oid || rede._id} value={rede._id?.$oid || rede._id}>
                                            {rede.nome_fantasia}
                                        </option>
                                    ))
                                ) : (
                                    <option disabled>Nenhuma rede disponível</option>
                                )}
                            </select>
                        )}
                        <div>
                            <button type="button" onClick={setRede} disabled={isLoading || !selectedRede}>
                                <FormattedMessage id="modal.setar_rede" defaultMessage="Setar Rede" />
                            </button>
                            <button type="button" onClick={closeModal}>
                                <FormattedMessage id="modal.fechar" defaultMessage="Fechar" />
                            </button>
                        </div>
                    </S.ModalContent>
                </S.ModalOverlay>
            )}

            <Footer />
        </S.Container>
    );
}

export default Marketdo;
