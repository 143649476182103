import React, { Component } from 'react';
import * as S from './styles';
import { FormattedMessage } from 'react-intl';
import secureStorage from '../../utils/SecureStorage';
import BuscaRedes from '../../services/Marketdo/BuscaRedes';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: secureStorage.getItem("nomeUser"),
      isRede: secureStorage.getItem("isRede"),
      RedeMaster: process.env.REACT_APP_API_BASE_43,
      idRede: secureStorage.getItem("id_r"),
      rede: "",
      resultadoRede: [],
    };
  }

  componentDidMount() {
    this.fetchRede();
  }

  async fetchRede() {
    const buscaRedes = new BuscaRedes();
    try {
      await buscaRedes.loadRede();
      const redes = buscaRedes.getResultadoRede();
      

      this.setState({ resultadoRede: redes }, this.findRede);
    } catch (err) {
      console.error("Erro ao buscar redes:", err);
    }
  }

  findRede() {
    const { resultadoRede, idRede } = this.state;

    if (resultadoRede.length > 0 && idRede) {
      
      const redeEncontrada = resultadoRede.find((rede) => rede._id.$oid === idRede);

      if (redeEncontrada) {
        
        this.setState({ rede: redeEncontrada.nome_fantasia });
      } else {
        console.warn("Nenhuma rede correspondente encontrada para idRede:", idRede);
      }
    }
  }

  render() {
    const { name, isRede, RedeMaster, rede } = this.state;

    // Ajuste: Condição baseada em isRede e idRede
    const showRede = isRede && isRede === RedeMaster;

    
    
    return (
      <S.Container>
        <S.SpanLeftAlign>
          <FormattedMessage id="footer.name" defaultMessage="Nome:" /> {name}
        </S.SpanLeftAlign>

        {/* Renderiza apenas se showRede for verdadeiro */}
        {showRede && (
          <S.SpanCenterAlign>
            {rede ? (
              <FormattedMessage id="footer.rede" defaultMessage={`Rede Set: ${rede}`} />
            ) : (
              <FormattedMessage id="footer.rede" defaultMessage="Carregando rede..." />
            )}
          </S.SpanCenterAlign>
        )}

        <S.SpanCenterAlign>
          <FormattedMessage id="footer.slogan" defaultMessage="MarketDo - Facilitando seu dia!" />
        </S.SpanCenterAlign>
        <S.SpanRightAlign>
          <FormattedMessage id="footer.version" defaultMessage="Versão: beta" />
        </S.SpanRightAlign>
      </S.Container>
    );
  }
}

export default Footer;
