import styled from 'styled-components';
import { TabList as ReactTabList, Tab } from 'react-tabs';

export const Container = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 60px;
  padding-bottom: 30px;

  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 600px;
  background: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #cf0505;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 15px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    padding: 10px;
  }
`;

export const Title = styled.h1`
  color: #cf0505;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
`;

export const StyledTabList = styled(ReactTabList)`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
`;

export const StyledTab = styled(Tab)`
  padding: 10px 20px;
  cursor: pointer;
  background: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px 4px 0 0;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.react-tabs__tab--selected {
    background: #cf0505;
    color: #fff;
    font-weight: bold;
  }
`;

StyledTab.tabsRole = 'Tab';

export const InputGroup = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const Button = styled.button`
  padding: 10px 20px;
  background: #003366;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background: #002244;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: 14px;
`;

export const BackButton = styled.button`
  position: absolute;
  top: 70px; /* Ajuste conforme necessário */
  left: 20px; /* Ajuste conforme necessário */
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #B22222;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: #800000;
  }
`;