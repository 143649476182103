import { useState } from 'react';
import api from '../api'; // Atualize o caminho para sua instância da API
import secureStorage from '../../utils/SecureStorage';

const useBuscaColaborador = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const apiUrl1 = process.env.REACT_APP_API_BASE_17;

  /**
   * Função para carregar registros de colaboradores
   * @param {string} idRede - ID da rede
   * @returns {Array} - Lista de colaboradores
   */
  const loadregistros = async (idRede) => {
    if (!idRede) {
      setError("ID da rede é obrigatório.");
      return [];
    }

    // Verifica se já existe cache para evitar nova requisição
    const cachedData = secureStorage.getItem(`colaboradores_${idRede}`);
    if (cachedData) {
      try {
        return JSON.parse(cachedData);
      } catch (e) {
        console.warn("Erro ao ler cache de colaboradores:", e);
        secureStorage.removeItem(`colaboradores_${idRede}`); // Remove cache corrompido
      }
    }

    setLoading(true);
    try {
      const response = await api.get(`${apiUrl1}${idRede}`);
      const colaboradores = response.data.cadastros || [];
      secureStorage.setItem(`colaboradores_${idRede}`, JSON.stringify(colaboradores)); // Armazena no cache
      setError(null);
      return colaboradores;
    } catch (err) {
      console.error("Erro ao buscar colaboradores:", err);
      setError("Não foi possível carregar os colaboradores.");
      return [];
    } finally {
      setLoading(false);
    }
  };

  return { loadregistros, loading, error };
};

export default useBuscaColaborador;
