import api from '../api';

const apiUrl = process.env.REACT_APP_API_BASE_56; // Base URL para criar módulos

// Função para atualizar um módulo existente (PUT)
const updateRotinaAtendimento = async (id, RotinaAtendimento) => {
  try {
    const response = await api.put(`${apiUrl}${id}`, RotinaAtendimento);
    return response.data; // Retorna os dados do backend
  } catch (error) {
    console.error('Erro ao atualizar o módulo:', error.response?.data || error.message);
    throw new Error(error.response?.data?.error || 'Erro ao atualizar o módulo');
  }
};

export default { updateRotinaAtendimento };
