import React, { useState } from 'react';
import Modal from 'react-modal';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import * as S from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { MascaraCpfCnpj, MascaraCep, MascaraTelefone, MascaraTelefoneFixo } from '../../utils/mask';
import ViaCepService from '../../services/Viacep/ViaCepService';
import RedeService from '../../services/Rede/RedeService';
import { MdArrowBack } from 'react-icons/md'; // Ícone de voltar
import { useNavigate } from 'react-router-dom';
import BuscaRedes from '../../services/Marketdo/BuscaRedes';

const CadastroRede = () => {
    const [rede, setRede] = useState({
        nome_fantasia: '',
        razao_social: '',
        cpf_cnpj: '',
        logo: '',
        blocked: false,
        inactive: false,
        endereco: {
            rua: '',
            numero: '',
            complemento: '',
            bairro: '',
            cep: '',
            cidade: '',
            estado: '',
        },
        contato: {
            telefone_fixo: '',
            celular: '',
            whatsapp: '',
            email: '',
        },
        precos_pagamento: {
            valor_servicos: 0,
            formas_pagamento: '',
            politica_cancelamento_reembolso: '',
            observacao: '',
        },
        id: null, // Adicionado para suporte ao PUT
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [redes, setRedes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    // Abrir modal e carregar redes
    const openModal = async () => {
        setIsModalOpen(true);
        try {
            const buscaRedes = new BuscaRedes();
            await buscaRedes.loadRede();
            setRedes(buscaRedes.getResultadoRede());
        } catch (error) {
            console.error('Erro ao buscar redes:', error);
        }
    };

    // Fechar modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSearchTerm('');
    };

    // Selecionar uma rede e preencher o formulário
    const selectRede = (redeSelecionada) => {
        setRede({
            ...redeSelecionada,
            id: redeSelecionada._id?.$oid || null, // Converte o `_id` para `id` no formato string
        });
        closeModal();
    };
    

    // Resetar o formulário
    const resetForm = () => {
        setRede({
            nome_fantasia: '',
            razao_social: '',
            cpf_cnpj: '',
            logo: '',
            blocked: false,
            inactive: false,
            endereco: {
                rua: '',
                numero: '',
                complemento: '',
                bairro: '',
                cep: '',
                cidade: '',
                estado: '',
            },
            contato: {
                telefone_fixo: '',
                celular: '',
                whatsapp: '',
                email: '',
            },
            precos_pagamento: {
                valor_servicos: 0,
                formas_pagamento: '',
                politica_cancelamento_reembolso: '',
                observacao: '',
            },
            id: null,
        });
    };

    // Atualizar os campos do formulário
    const handleChange = (e) => {
        let { name, value } = e.target;

        switch (name) {
            case 'cpf_cnpj':
                value = MascaraCpfCnpj(value);
                break;
            case 'endereco.cep':
                value = MascaraCep(value);
                break;
            case 'contato.telefone_fixo':
                value = MascaraTelefoneFixo(value);
                break;
            case 'contato.celular':
            case 'contato.whatsapp':
                value = MascaraTelefone(value);
                break;
            default:
                break;
        }

        if (name.includes('endereco.') || name.includes('contato.') || name.includes('precos_pagamento.')) {
            const [parentKey, childKey] = name.split('.');
            setRede((prevState) => ({
                ...prevState,
                [parentKey]: {
                    ...prevState[parentKey],
                    [childKey]: value,
                },
            }));
        } else {
            setRede((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    // Função para salvar ou atualizar a rede
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Remove a máscara do CPF/CNPJ antes de enviar
        const redeSemMascara = {
            ...rede,
            cpf_cnpj: rede.cpf_cnpj.replace(/\D/g, ''), // Remove tudo que não é número
        };
    
        try {
            if (rede.id) {
                // Realizar PUT para atualizar o cadastro existente
                await RedeService.updateRede(rede.id, redeSemMascara);
                alert('Rede atualizada com sucesso!');
            } else {
                // Realizar POST para criar um novo cadastro
                await RedeService.saveRede(redeSemMascara);
                alert('Rede cadastrada com sucesso!');
            }
    
            resetForm();
        } catch (error) {
            console.error('Erro ao salvar ou atualizar a rede:', error);
            alert('Erro ao salvar ou atualizar a rede.');
        }
    };
    

    // Buscar CEP e preencher o endereço
    const onBlurCep = async (e) => {
        const cep = e.target.value.replace(/\D/g, '');
        if (cep.length !== 8) return;

        try {
            const data = await ViaCepService.fetchAddress(cep);
            if (data.erro) {
                alert('CEP não encontrado.');
                return;
            }
            setRede((prevState) => ({
                ...prevState,
                endereco: {
                    ...prevState.endereco,
                    rua: data.logradouro,
                    bairro: data.bairro,
                    cidade: data.localidade,
                    estado: data.uf,
                    cep: e.target.value,
                },
            }));
        } catch (error) {
            alert('Erro ao buscar CEP: ' + error.message);
        }
    };

    // Voltar para a tela anterior
    const handleBack = () => {
        navigate('/Marketdo');
    };
    const handleSearch = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };
    
    return (
        <>
            <Header />
            <S.BackButton onClick={handleBack}>
                <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
                Voltar
            </S.BackButton>
            <S.Container>
                <S.Form onSubmit={handleSubmit}>
                    <S.Title>Cadastro de Rede</S.Title>
                    <Tabs>
                        <TabList>
                            <Tab>Dados da Rede</Tab>
                            <Tab>Endereço</Tab>
                            <Tab>Contato</Tab>
                            <Tab>Preços e Pagamento</Tab>
                        </TabList>

                        {/* Aba 1: Dados da Rede */}
                        <TabPanel>
                            <S.InputGroup>
                                <S.Label>Nome Fantasia:</S.Label>
                                <S.Input name="nome_fantasia" value={rede.nome_fantasia} onChange={handleChange} />
                            </S.InputGroup>
                            <S.InputGroup>
                                <S.Label>Razão Social:</S.Label>
                                <S.Input name="razao_social" value={rede.razao_social} onChange={handleChange} />
                            </S.InputGroup>
                            <S.InputGroup>
                                <S.Label>CPF/CNPJ:</S.Label>
                                <S.Input name="cpf_cnpj" value={rede.cpf_cnpj} onChange={handleChange} />
                            </S.InputGroup>
                        </TabPanel>

                        {/* Aba 2: Endereço */}
                        <TabPanel>
                            <S.InputGroup>
                                <S.Label>CEP:</S.Label>
                                <S.Input name="endereco.cep" value={rede.endereco.cep} onChange={handleChange} onBlur={onBlurCep} />
                            </S.InputGroup>
                            <S.InputGroup>
                                <S.Label>Rua:</S.Label>
                                <S.Input name="endereco.rua" value={rede.endereco.rua} onChange={handleChange} />
                            </S.InputGroup>
                            <S.InputGroup>
                                <S.Label>Número:</S.Label>
                                <S.Input name="endereco.numero" value={rede.endereco.numero} onChange={handleChange} />
                            </S.InputGroup>
                            <S.InputGroup>
                                <S.Label>Complemento:</S.Label>
                                <S.Input name="endereco.complemento" value={rede.endereco.complemento} onChange={handleChange} />
                            </S.InputGroup>
                            <S.InputGroup>
                                <S.Label>Bairro:</S.Label>
                                <S.Input name="endereco.bairro" value={rede.endereco.bairro} onChange={handleChange} />
                            </S.InputGroup>
                            <S.InputGroup>
                                <S.Label>Cidade:</S.Label>
                                <S.Input name="endereco.cidade" value={rede.endereco.cidade} onChange={handleChange} />
                            </S.InputGroup>
                            <S.InputGroup>
                                <S.Label>Estado:</S.Label>
                                <S.Input name="endereco.estado" value={rede.endereco.estado} onChange={handleChange} />
                            </S.InputGroup>
                        </TabPanel>

                        {/* Aba 3: Contato */}
                        <TabPanel>
                            <S.InputGroup>
                                <S.Label>Telefone Fixo:</S.Label>
                                <S.Input name="contato.telefone_fixo" value={rede.contato.telefone_fixo} onChange={handleChange} />
                            </S.InputGroup>
                            <S.InputGroup>
                                <S.Label>Celular:</S.Label>
                                <S.Input name="contato.celular" value={rede.contato.celular} onChange={handleChange} />
                            </S.InputGroup>
                            <S.InputGroup>
                                <S.Label>Whatsapp:</S.Label>
                                <S.Input name="contato.whatsapp" value={rede.contato.whatsapp} onChange={handleChange} />
                            </S.InputGroup>
                            <S.InputGroup>
                                <S.Label>Email:</S.Label>
                                <S.Input name="contato.email" value={rede.contato.email} onChange={handleChange} />
                            </S.InputGroup>
                        </TabPanel>

                        {/* Aba 4: Preços e Pagamento */}
                        <TabPanel>
                            <S.InputGroup>
                                <S.Label>Valor dos Serviços:</S.Label>
                                <S.Input
                                    name="precos_pagamento.valor_servicos"
                                    type="number"
                                    value={rede.precos_pagamento.valor_servicos}
                                    onChange={handleChange}
                                />
                            </S.InputGroup>
                            <S.InputGroup>
                                <S.Label>Formas de Pagamento:</S.Label>
                                <S.Input
                                    name="precos_pagamento.formas_pagamento"
                                    value={rede.precos_pagamento.formas_pagamento}
                                    onChange={handleChange}
                                />
                            </S.InputGroup>
                            <S.InputGroup>
                                <S.Label>Política de Cancelamento:</S.Label>
                                <S.Input
                                    name="precos_pagamento.politica_cancelamento_reembolso"
                                    value={rede.precos_pagamento.politica_cancelamento_reembolso}
                                    onChange={handleChange}
                                />
                            </S.InputGroup>
                            <S.InputGroup>
                                <S.Label>Observação:</S.Label>
                                <S.TextArea
                                    name="precos_pagamento.observacao"
                                    value={rede.precos_pagamento.observacao}
                                    onChange={handleChange}
                                    placeholder="Digite suas observações aqui..."
                                />
                            </S.InputGroup>
                        </TabPanel>
                    </Tabs>

                    <S.ButtonGroup>
                        <S.Button type="submit">Salvar</S.Button>
                        <S.Button type="reset" onClick={resetForm}>Limpar</S.Button>
                        <S.Button type="button" onClick={openModal}>Buscar Rede</S.Button>
                    </S.ButtonGroup>
                </S.Form>
            </S.Container>

            {/* Modal */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                ariaHideApp={false}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxHeight: '70vh',
                        overflowY: 'auto',
                    },
                }}
            >
                <S.ModalHeader>
                    <h2>Buscar Rede</h2>
                    <button onClick={closeModal}>Fechar</button>
                </S.ModalHeader>
                <S.SearchInput
                    type="text"
                    placeholder="Digite o nome da rede para buscar..."
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <S.TableContainer>
                    <S.Table>
                        <thead>
                            <tr>
                                <th>Nome Fantasia</th>
                                <th>Razão Social</th>
                                <th>CPF/CNPJ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {redes
                                .filter((rede) =>
                                    rede.nome_fantasia.toLowerCase().includes(searchTerm)
                                )
                                .map((rede) => (
                                    <tr key={rede.id} onClick={() => selectRede(rede)}>
                                        <td>{rede.nome_fantasia}</td>
                                        <td>{rede.razao_social}</td>
                                        <td>{rede.cpf_cnpj}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </S.Table>
                </S.TableContainer>
            </Modal>

            <Footer />
        </>
    );
};

export default CadastroRede;
