import React, { useEffect, useState } from 'react';
import api from '../api';

const ImageViewer = ({ fileId, idRede }) => {
  const apiUrl = process.env.REACT_APP_API_BASE_33;  
  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await api.get(`${apiUrl}${fileId}?id_rede=${idRede}`, {
          responseType: 'blob', // Para lidar com arquivos de imagem binários
        });
        const imageBlob = response.data;
        const imageObjectUrl = URL.createObjectURL(imageBlob);
        setImageUrl(imageObjectUrl);
      } catch (err) {
        console.error('Erro ao carregar imagem:', err);
        setError(true);
      }
    };

    fetchImage();
  }, [fileId, idRede, apiUrl]);

  if (error) {
    return <p>Erro ao carregar a imagem ou imagem não encontrada.</p>;
  }

  return (
    <div>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={`Imagem ${fileId}`}
          style={{ width: '200px', height: 'auto' }}
          onError={(e) => {
            e.target.src = 'https://via.placeholder.com/300?text=Imagem+não+encontrada';
            e.target.alt = 'Imagem não encontrada';
          }}
        />
      ) : (
        <p>Carregando imagem...</p>
      )}
    </div>
  );
};

export default ImageViewer;
