import React, { useState, useEffect } from 'react';
import { Tabs, TabPanel } from 'react-tabs';
import { useNavigate } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import * as S from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { toast } from 'react-toastify';
import DownloadEspelhoPonto from '../../services/ponto/DownloadEspelhoPonto';
import DownloadEspelhoempresa from '../../services/ponto/DownloadEspelhoempresa';
import DownloadEspelhorede from '../../services/ponto/DownloadEspelhorede';
import useBuscaEmpreCnpj from '../../services/ponto/BuscaEmpreCnpj';
import { FaUser, FaBuilding, FaNetworkWired } from 'react-icons/fa';
import useBuscaColaborador from '../../services/ponto/BuscaClolaborador';
import secureStorage from '../../utils/SecureStorage';
import { MdArrowBack } from 'react-icons/md'; // Ícone de voltar

function EspelhoPonto() {
  const [tabIndex, setTabIndex] = useState(0);
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [colaboradorSelecionado, setColaboradorSelecionado] = useState('');
  const [colaboradores, setColaboradores] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [empresaSelecionada, setEmpresaSelecionada] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const idRede = secureStorage.getItem('id_r');
  const { loadregistros, loading, error } = useBuscaColaborador();
  const navigate = useNavigate();
  const {loadEmpresas,resultadoEmpresas,loadingEmpresas, errorEmpresas,} = useBuscaEmpreCnpj(); 
  // Carregar colaboradores ao montar o componente
  useEffect(() => {
    const fetchColaboradores = async () => {
      if (!idRede) {
        toast.error('ID da rede é obrigatório.');
        return;
      }

      try {
        const colaboradoresAPI = await loadregistros(idRede);
        if (colaboradoresAPI && colaboradoresAPI.length > 0) {
          setColaboradores(colaboradoresAPI);
        }
      } catch (err) {
        toast.error('Erro ao carregar colaboradores.');
      }
    };

    fetchColaboradores();
  }, []);

  useEffect(() => {
    const fetchEmpresas = async () => {
      if (!idRede) {
        toast.error('ID da rede é obrigatório.');
        return;
      }
  
      try {
        
        await loadEmpresas(idRede); // Chama a função para carregar os dados
      } catch (err) {
        console.error("Erro ao carregar empresas:", err);
        toast.error("Erro ao carregar empresas.");
      }
    };
  
    fetchEmpresas();
  }, [idRede]); // Somente `idRede` como dependência
  
  useEffect(() => {
    if (resultadoEmpresas && resultadoEmpresas.length > 0) {
      
      setEmpresas(resultadoEmpresas); // Atualiza o estado local com os resultados
    } else if (!loadingEmpresas) {
      console.info("Nenhuma empresa encontrada.");
      toast.info('Nenhuma empresa encontrada.');
    }
  }, [resultadoEmpresas, loadingEmpresas]); // Reage à atualização de `resultadoEmpresas`
  
  
  
  

  const handleSubmitColaborador = async () => {
    if (!colaboradorSelecionado || !dataInicio || !dataFim) {
      toast.error('Por favor, selecione um colaborador e preencha as datas antes de continuar.');
      return;
    }

    try {
      setIsSubmitting(true);
      await DownloadEspelhoPonto({
        idRede,
        idColaborador: colaboradorSelecionado,
        dataInicio,
        dataFim,
      });
      toast.success('Espelho de ponto gerado com sucesso!');
    } catch (error) {
      toast.error(error.message || 'Erro ao gerar o espelho de ponto.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitEmpresa = async () => {
    if (!empresaSelecionada || !dataInicio || !dataFim) {
      toast.error('Por favor, selecione uma empresa e preencha as datas antes de continuar.');
      return;
    }

    try {
      setIsSubmitting(true);
      await DownloadEspelhoempresa({
        idRede,
        empresa: empresaSelecionada,
        dataInicio,
        dataFim,
      });
      toast.success('Espelho de ponto gerado com sucesso!');
    } catch (error) {
      toast.error(error.message || 'Erro ao gerar o espelho de ponto.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitRede = async () => {
    if (!dataInicio || !dataFim) {
      toast.error('Por favor, preencha as datas antes de continuar.');
      return;
    }

    try {
      setIsSubmitting(true);
      await DownloadEspelhorede({
        idRede,
        dataInicio,
        dataFim,
      });
      toast.success('Espelho de ponto gerado com sucesso!');
    } catch (error) {
      toast.error(error.message || 'Erro ao gerar o espelho de ponto.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    navigate('/Relatorio');
  };

  return (
    <S.Container>
      <Header />
      <S.BackButton onClick={handleBack}>
        <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
        Voltar
      </S.BackButton>
      <S.Form>
        <S.Title>Espelho de Ponto</S.Title>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <S.StyledTabList>
            <S.StyledTab>
              <FaUser size={20} />
              Colaborador
            </S.StyledTab>
            <S.StyledTab>
              <FaBuilding size={20} />
              Empresa
            </S.StyledTab>
            <S.StyledTab>
              <FaNetworkWired size={20} />
              Rede
            </S.StyledTab>
          </S.StyledTabList>

          {/* Aba Colaborador */}
          <TabPanel>
            <S.InputGroup>
              <S.Label>Selecione um Colaborador:</S.Label>
              {loading ? (
                <p>Carregando colaboradores...</p>
              ) : error ? (
                <p>{error}</p>
              ) : (
                <S.Select
                  value={colaboradorSelecionado}
                  onChange={(e) => setColaboradorSelecionado(e.target.value)}
                >
                  <option value="">Selecione...</option>
                  {colaboradores.map((colaborador) => (
                    <option key={colaborador._id.$oid} value={colaborador._id.$oid}>
                      {colaborador.nome}
                    </option>
                  ))}
                </S.Select>
              )}
            </S.InputGroup>
            <S.InputGroup>
              <S.Label>Data Início:</S.Label>
              <S.Input
                type="date"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </S.InputGroup>
            <S.InputGroup>
              <S.Label>Data Fim:</S.Label>
              <S.Input
                type="date"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </S.InputGroup>
            <S.ButtonGroup>
              <S.Button onClick={handleSubmitColaborador} disabled={isSubmitting}>
                {isSubmitting ? 'Gerando...' : 'Gerar Espelho de Ponto'}
              </S.Button>
            </S.ButtonGroup>
          </TabPanel>

          {/* Aba Empresa */}
          <TabPanel>
  <S.InputGroup>
    <S.Label>Nome da Empresa:</S.Label>
    {loadingEmpresas ? (
      <p>Carregando empresas...</p>
    ) : errorEmpresas ? (
      <p>{errorEmpresas}</p>
    ) : (
      <S.Select
        value={empresaSelecionada}
        onChange={(e) => setEmpresaSelecionada(e.target.value)}
      >
        <option value="">Selecione...</option>
        {empresas.map((empresa) => (
          <option key={empresa.cpf_cnpj} value={empresa.cpf_cnpj}>
            {empresa.nome_fantasia}
          </option>
        ))}
      </S.Select>
    )}
  </S.InputGroup>
  <S.InputGroup>
    <S.Label>Data Início:</S.Label>
    <S.Input
      type="date"
      value={dataInicio}
      onChange={(e) => setDataInicio(e.target.value)}
    />
  </S.InputGroup>
  <S.InputGroup>
    <S.Label>Data Fim:</S.Label>
    <S.Input
      type="date"
      value={dataFim}
      onChange={(e) => setDataFim(e.target.value)}
    />
  </S.InputGroup>
  <S.ButtonGroup>
    <S.Button onClick={handleSubmitEmpresa} disabled={isSubmitting}>
      {isSubmitting ? 'Gerando...' : 'Gerar Espelho de Ponto'}
    </S.Button>
  </S.ButtonGroup>
</TabPanel>


          {/* Aba Rede */}
          <TabPanel>
            <S.InputGroup>
              <S.Label>Data Início:</S.Label>
              <S.Input
                type="date"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </S.InputGroup>
            <S.InputGroup>
              <S.Label>Data Fim:</S.Label>
              <S.Input
                type="date"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </S.InputGroup>
            <S.ButtonGroup>
              <S.Button onClick={handleSubmitRede} disabled={isSubmitting}>
                {isSubmitting ? 'Gerando...' : 'Gerar Espelho de Ponto'}
              </S.Button>
            </S.ButtonGroup>
          </TabPanel>
        </Tabs>
      </S.Form>
      <Footer />
    </S.Container>
  );
}

export default EspelhoPonto;
