import api from '../api';


const RemoverPontoService = {
    Removerponto: async (clienteData) => {
      const apiUrl = process.env.REACT_APP_API_BASE_28 ; // Ajuste a URL conforme necessário
      
      try {
        await api.put(apiUrl, clienteData);
        alert("Batida removida com sucesso!");
      } catch (error) {
        //console.error("Erro ao salvar o ajuste:", error);
        if (error.response && error.response.data && error.response.data.error) {
          alert(`Erro ao remover o ajuste: ${error.response.data.error}`);
        } else {
          alert("Erro ao remover o ajuste.");
        }
      }
    }
  };
  

export default RemoverPontoService;
