import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BuscaRedes from '../../services/Marketdo/BuscaRedes';
import BuscarRotinaPonto from '../../services/RotinaPonto/BuscarRotinaPonto';
import ServiceRotinaPonto from '../../services/RotinaPonto/ServiceRotinaPonto';

function RotinaPonto() {
    const [redes, setRedes] = useState([]);
    const [redeSelecionada, setRedeSelecionada] = useState('');
    const [cadastros, setCadastros] = useState([]);
    const [idRotinaAtendimento, setIdRotinaAtendimento] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchRedes = async () => {
            try {
                const buscaRedes = new BuscaRedes();
                await buscaRedes.loadRede();
                const redesResultado = buscaRedes.getResultadoRede();

                if (redesResultado && Array.isArray(redesResultado)) {
                    const redesFormatadas = redesResultado.map((rede) => ({
                        _id: rede._id.$oid || rede._id, // Garante que o ID seja tratado como string
                        nome_fantasia: rede.nome_fantasia,
                    }));
                    setRedes(redesFormatadas);

                    const rotinaAtendimento = await BuscarRotinaPonto();
                    if (rotinaAtendimento && Array.isArray(rotinaAtendimento)) {
                        const cadastrosComNomes = rotinaAtendimento.map((cadastro) => ({
                            ...cadastro,
                            _id: cadastro._id.$oid || cadastro._id, // Trata o ID do cadastro como string
                            redes: cadastro.redes.map((redeId) => {
                                const redeEncontrada = redesFormatadas.find((rede) => rede._id === redeId);
                                return redeEncontrada
                                    ? { _id: redeEncontrada._id, nome_fantasia: redeEncontrada.nome_fantasia }
                                    : { _id: redeId, nome_fantasia: `Rede ${redeId}` };
                            }),
                        }));

                        setCadastros(cadastrosComNomes);

                        if (rotinaAtendimento[0]?._id) {
                            setIdRotinaAtendimento(String(rotinaAtendimento[0]._id.$oid || rotinaAtendimento[0]._id)); // Converte o ID para string
                        }
                    }
                }
            } catch (error) {
                console.error('Erro ao buscar redes ou cadastros:', error);
            }
        };

        fetchRedes();
    }, []);

    const handleAdicionarRede = () => {
        if (!redeSelecionada) {
            alert('Por favor, selecione uma rede antes de adicionar.');
            return;
        }

        const rede = redes.find((r) => r._id === redeSelecionada);
        if (!rede) {
            alert('Rede não encontrada.');
            return;
        }

        setCadastros((prevState) => {
            const cadastroExistente = prevState.find((cadastro) => cadastro._id === idRotinaAtendimento);

            if (cadastroExistente) {
                const redesAtualizadas = [...cadastroExistente.redes];
                if (!redesAtualizadas.some((r) => r._id === rede._id)) {
                    redesAtualizadas.push({ _id: rede._id, nome_fantasia: rede.nome_fantasia });
                }
                return prevState.map((cadastro) =>
                    cadastro._id === idRotinaAtendimento
                        ? { ...cadastro, redes: redesAtualizadas }
                        : cadastro
                );
            } else {
                return [
                    ...prevState,
                    {
                        _id: idRotinaAtendimento,
                        redes: [{ _id: rede._id, nome_fantasia: rede.nome_fantasia }],
                    },
                ];
            }
        });
        setRedeSelecionada('');
    };

    const handleRemoverCadastro = (cadastroId, redeId) => {
        setCadastros((prevState) =>
            prevState.map((cadastro) =>
                cadastro._id === cadastroId
                    ? { ...cadastro, redes: cadastro.redes.filter((rede) => rede._id !== redeId) }
                    : cadastro
            ).filter((cadastro) => cadastro.redes.length > 0)
        );
    };

    const handleSalvar = async () => {
        try {
            if (!idRotinaAtendimento) {
                alert('ID de Rotina Atendimento não encontrado.');
                return;
            }

            const cadastroPrincipal = cadastros.find((cadastro) => cadastro._id === idRotinaAtendimento);
            if (!cadastroPrincipal) {
                alert('Cadastro principal não encontrado.');
                return;
            }

            const listaIds = cadastroPrincipal.redes.map((rede) => rede._id);
            await ServiceRotinaPonto.updateRotinaPonto(idRotinaAtendimento, { redes: listaIds });

            alert('Cadastro atualizado com sucesso!');
            window.location.reload()
        } catch (error) {
            console.error('Erro ao salvar os dados:', error);
            alert('Ocorreu um erro ao salvar os dados. Tente novamente.');
        }
    };

    return (
        <>
            <Header />
            <S.BackButton onClick={() => navigate('/Marketdo')}>
                <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
                Voltar
            </S.BackButton>
            <S.Container>
                <S.Form>
                    <S.Title>Cadastro Rotina Ponto</S.Title>

                    <S.InputGroup>
                        <S.Label>Selecione uma Rede:</S.Label>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <S.Select value={redeSelecionada} onChange={(e) => setRedeSelecionada(e.target.value)}>
                                <option value="">Selecione...</option>
                                {redes.map((rede) => (
                                    <option key={rede._id} value={rede._id}>
                                        {rede.nome_fantasia}
                                    </option>
                                ))}
                            </S.Select>
                            <S.Button type="button" onClick={handleAdicionarRede}>
                                Adicionar
                            </S.Button>
                        </div>
                    </S.InputGroup>

                    {cadastros.length > 0 && (
                        <S.ListContainer>
                            <S.Label>Lista de Cadastros:</S.Label>
                            <ul>
                                {cadastros.map((cadastro) => (
                                    <li key={cadastro._id}>
                                        <ul>
                                            {cadastro.redes.map((rede) => (
                                                <li key={rede._id}>
                                                    <span>{rede.nome_fantasia}</span>
                                                    <S.Button
                                                        type="button"
                                                        onClick={() => handleRemoverCadastro(cadastro._id, rede._id)}
                                                    >
                                                        Remover
                                                    </S.Button>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </S.ListContainer>
                    )}

                    <S.ButtonGroup>
                        <S.Button type="button" onClick={handleSalvar}>
                            Salvar
                        </S.Button>
                    </S.ButtonGroup>
                </S.Form>
            </S.Container>
            <Footer />
        </>
    );
}

export default RotinaPonto;
