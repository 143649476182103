import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import * as S from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import useBuscaEstabelecimento from '../../services/Estabelecimento/useBuscaEstabelecimento';
import useBuscaClientes from '../../services/Cliente/useBuscaClientes';
import BuscaProduto from '../../services/Produto/BuscaProdutoCliente';
import AtendimentoBaseServices from '../../services/AtendimentoBase/AtendimentoBaseServices';
import secureStorage from '../../utils/SecureStorage';
import moment from 'moment-timezone';
import { MdArrowBack } from 'react-icons/md';


function AtendimentoBase({ mode }) {
  const { id } = useParams();
  const [erro, setErro] = useState('');
  const [selectedEstabelecimento, setSelectedEstabelecimento] = useState(null);
  const [selectedClientes, setSelectedClientes] = useState([]);
  const [isEstabelecimentoModalOpen, setIsEstabelecimentoModalOpen] = useState(false);
  const [isClienteModalOpen, setIsClienteModalOpen] = useState(false);
  const [isProdutoModalOpen, setIsProdutoModalOpen] = useState(false);
  const [currentClienteIndex, setCurrentClienteIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEstabelecimentos, setFilteredEstabelecimentos] = useState([]);
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [filteredProdutos, setFilteredProdutos] = useState([]);
  const navigate = useNavigate();
  const [tempSelectedProdutos, setTempSelectedProdutos] = useState([]);
  const [visibleLists, setVisibleLists] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cadastros, setCadastros] = useState([]);
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [error, setError] = useState(""); // Estado de erro


  const toggleListVisibility = (index) => {
    setVisibleLists((prev) => ({
      ...prev,
      [index]: !prev[index], // Alterna o estado da lista específica
    }));
  };

  // Estado inicial do formulário
  const [formValues, setFormValues] = useState({
    descricao_visita: '',
    data: moment().tz("America/Sao_Paulo").format('YYYY-MM-DD'), // Inicializando com a data atual no fuso horário SP
    hora: '',
    repetir_dias: [],
    repetir_meses: 0,
  });



  const { searchResults: searchResultsEstabelecimento, loadregistros: loadregistrosEstabelecimento, loading: loadingEstabelecimento, error: errorEstabelecimento } = useBuscaEstabelecimento();
  const { searchResults: searchResultsClientes, loadregistros: loadregistrosClientes, loading: loadingClientes, error: errorClientes } = useBuscaClientes();
  const { searchResultsprodutos: searchResultsProdutos, loadprodutos: loadregistrosProdutos, loading: loadingProdutos, error: errorProdutos } = BuscaProduto();

  useEffect(() => {
    
    if (id) {
      AtendimentoBaseServices.getVisitaById(id)
        .then((data) => {
          
          setSelectedEstabelecimento(data.estabelecimento || null);
          setSelectedClientes(
            data.clientes?.map((cliente) => ({
              cliente,
              produtos: cliente.produtos.map((produto) => ({
                ...produto,
                gtin: produto.gtin || 'N/A',
                descricao: produto.descricao || "N/A",
                marca: produto.marca || "N/A",
              })) || [],
            })) || []
          );
          setFormValues((prev) => ({
            ...prev,
            _id: data._id, // Captura o `_id` do registro para updates futuros
          }));
        })
        .catch((error) => {
          setErro("Erro ao carregar dados do atendimento para edição.");
          console.error(error);
        });
    }
  }, [id]);


  useEffect(() => {
    if (searchTerm) {
      const filtered = searchResultsEstabelecimento.filter(estabelecimento =>
        (estabelecimento.nome_fantasia && estabelecimento.nome_fantasia.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (estabelecimento.razao_social && estabelecimento.razao_social.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredEstabelecimentos(filtered);
    } else {
      setFilteredEstabelecimentos(searchResultsEstabelecimento);
    }
  }, [searchTerm, searchResultsEstabelecimento]);

  const handleTempSelectProduto = (produto) => {
    setTempSelectedProdutos((prev) => {
      if (prev.some(p => p._id === produto._id)) {
        // Se já existir, removê-lo
        return prev.filter(p => p._id !== produto._id);
      } else {
        // Caso contrário, adicioná-lo
        return [...prev, produto];
      }
    });
  };


  useEffect(() => {
    if (searchTerm) {
      const filtered = searchResultsClientes.filter(cliente =>
        (cliente.nome && cliente.nome.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (cliente.cpf_cnpj && cliente.cpf_cnpj.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredClientes(filtered);
    } else {
      setFilteredClientes(searchResultsClientes);
    }
  }, [searchTerm, searchResultsClientes]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = searchResultsProdutos.filter(produto =>
        (produto.brand?.name && produto.brand.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (produto.description && produto.description.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredProdutos(filtered);
    } else {
      setFilteredProdutos(searchResultsProdutos);
    }
  }, [searchTerm, searchResultsProdutos]);



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedEstabelecimento) {
      setErro("Você precisa informar o estabelecimento.");
      return;
    }

    if (selectedClientes.length === 0) {
      setErro("Você precisa informar pelo menos um cliente.");
      return;
    }

    for (let i = 0; i < selectedClientes.length; i++) {
      if (!selectedClientes[i].produtos || selectedClientes[i].produtos.length === 0) {
        setErro(`O cliente ${selectedClientes[i].cliente.nome} não possui produtos.`);
        return;
      }
    }

    const AtendimentoBase = {
      _id: formValues.id,
      clientes: selectedClientes.map(clienteData => ({
        _id: clienteData.cliente._id?.$oid || clienteData.cliente._id, // Ensure proper access to _id
        nome: clienteData.cliente.nome,
        cpf_cnpj: clienteData.cliente.cpf_cnpj,
        endereco: {
          rua: clienteData.cliente.endereco.rua,
          numero: clienteData.cliente.endereco.numero,
          complemento: clienteData.cliente.endereco.complemento,
          bairro: clienteData.cliente.endereco.bairro,
          cidade: clienteData.cliente.endereco.cidade,
          estado: clienteData.cliente.endereco.estado,
          cep: clienteData.cliente.endereco.cep
        },
        contato: {
          telefone_fixo: clienteData.cliente.contato.telefone_fixo,
          celular: clienteData.cliente.contato.celular,
          email: clienteData.cliente.contato.email,
          WhatsApp: clienteData.cliente.contato.WhatsApp
        },
        observacoes: clienteData.cliente.observacoes,
        produtos: clienteData.produtos.map(produto => ({
          _id: produto._id?.$oid || produto._id, // Ensure proper access to _id
          gtin: produto.gtin || 'N/A',
          descricao: produto.descricao,
          marca: produto.marca,
          thumbnail: produto.thumbnail || 'N/A' // Certifique-se de que o campo thumbnail está aqui
        }))
      })),
      estabelecimento: {
        _id: selectedEstabelecimento._id?.$oid || selectedEstabelecimento._id, // Ensure proper access to _id
        nome_fantasia: selectedEstabelecimento.nome_fantasia,
        Razao_Social: selectedEstabelecimento.Razao_Social, // Ensure correct capitalization
        cpf_cnpj: selectedEstabelecimento.cpf_cnpj,
        contato: {
          telefone_fixo: selectedEstabelecimento.contato.telefone_fixo,
          celular: selectedEstabelecimento.contato.celular,
          email: selectedEstabelecimento.contato.email,
          WhatsApp: selectedEstabelecimento.contato.WhatsApp,
          observacoes: selectedEstabelecimento.contato.observacoes // Ensure contact notes are included
        },
        endereco: {
          rua: selectedEstabelecimento.endereco.rua,
          numero: selectedEstabelecimento.endereco.numero,
          complemento: selectedEstabelecimento.endereco.complemento,
          bairro: selectedEstabelecimento.endereco.bairro,
          cidade: selectedEstabelecimento.endereco.cidade,
          estado: selectedEstabelecimento.endereco.estado,
          cep: selectedEstabelecimento.endereco.cep,
          latitude: selectedEstabelecimento.endereco.latitude,
          longitude: selectedEstabelecimento.endereco.longitude
        },
        observacoes: selectedEstabelecimento.observacoes // Ensure establishment notes are included
      }
    };

    try {
      if (formValues.id) {
        
        await AtendimentoBaseServices.updateVisita(AtendimentoBase);
        
      } else {
        
        await AtendimentoBaseServices.createVisita(AtendimentoBase);
        
      }
      clearForm(); // Limpa os dados após salvar
    } catch (error) {
      setErro("Erro ao salvar os dados.");
      console.error(error);
    }
  };



  // Verifica se todos os produtos estão selecionados
  const areAllProdutosSelected = () =>
    filteredProdutos.length > 0 &&
    filteredProdutos.every(produto =>
      tempSelectedProdutos.some(p => p._id === produto._id)
    );

  // Seleciona ou desmarca todos os produtos
  const handleSelectAll = () => {
    if (!areAllProdutosSelected()) {
      const allProdutos = [...new Set([...tempSelectedProdutos, ...filteredProdutos])];
      setTempSelectedProdutos(allProdutos);
    } else {
      const remainingProdutos = tempSelectedProdutos.filter(
        (p) => !filteredProdutos.some((produto) => produto._id === p._id)
      );
      setTempSelectedProdutos(remainingProdutos);
    }
  };



  const clearForm = () => {
    setFormValues({
      descricao_visita: '',
      data: '',
      hora: '',
      repetir_dias: [],
      repetir_meses: 0,
    });
    setSelectedEstabelecimento(null);
    setSelectedClientes([]);
    setErro('');
  };

  const openEstabelecimentoModal = async () => {
    setIsEstabelecimentoModalOpen(true);
    const idrede = secureStorage.getItem("id_r");
    if (idrede) {
      await loadregistrosEstabelecimento(idrede);
    }
  };

  const closeEstabelecimentoModal = () => {
    setIsEstabelecimentoModalOpen(false);
  };

  const handleSelectEstabelecimento = (estabelecimento) => {
    setSelectedEstabelecimento(estabelecimento);
    closeEstabelecimentoModal();
  };


  const openClienteModal = async () => {
    setIsClienteModalOpen(true);
    const idrede = secureStorage.getItem("id_r");
    if (idrede) {
      await loadregistrosClientes(idrede);
    }
  };

  const closeClienteModal = () => {
    setIsClienteModalOpen(false);
  };

  const handleSelectCliente = (cliente) => {
    setSelectedClientes([...selectedClientes, { cliente, produtos: [] }]);
    closeClienteModal();
  };

  const openProdutoModal = async (index) => {
    
    setIsProdutoModalOpen(true);
    setCurrentClienteIndex(index);

    const idrede = secureStorage.getItem("id_r");
    

    if (idrede) {
      const cliente = selectedClientes[index]?.cliente;
     

      // Garantir que o ID seja extraído como string simples
      const id_Cliente =
        cliente?._id?.$oid || // MongoDB ObjectId no formato {$oid: "id"}
        cliente?._id || // MongoDB ObjectId como string
        cliente?.id?.$oid || // Outra variante de $oid
        cliente?.id; // ID como string simples

      

      if (id_Cliente) {
        try {
          
          await loadregistrosProdutos(idrede, id_Cliente.toString()); // Convertendo para string
          
        } catch (error) {
          console.error("Erro ao carregar produtos:", error);
          setErro("Erro ao buscar produtos para o cliente selecionado.");
        }
      } else {
        console.error("ID do Cliente não encontrado no cliente selecionado.");
        setErro("ID do cliente não encontrado. Verifique se o cliente foi corretamente selecionado.");
      }
    } else {
      console.error("ID da rede não encontrado.");
      setErro("ID da rede não encontrado.");
    }
  };






  const closeProdutoModal = () => {
    setIsProdutoModalOpen(false);
  };

  const handleSelectProduto = (produto) => {
    const updatedClientes = [...selectedClientes];
    updatedClientes[currentClienteIndex] = {
      ...updatedClientes[currentClienteIndex],
      produtos: [...updatedClientes[currentClienteIndex].produtos, {
        ...produto,
        gtin: produto.gtin || 'N/A',
        descricao: produto.description || 'N/A',
        marca: produto.brand?.name || 'N/A',
        thumbnail: produto.thumbnail || 'N/A', // Adiciona o campo thumbnail aqui, mas não será mostrado na interface
      }]
    };
    setSelectedClientes(updatedClientes);
    closeProdutoModal();
  };


  const handleRemoveCliente = (index) => {
    const updatedClientes = [...selectedClientes];
    updatedClientes.splice(index, 1);
    setSelectedClientes(updatedClientes);
  };

  const handleRemoveProduto = (clienteIndex, produtoIndex) => {
    const updatedClientes = [...selectedClientes];
    updatedClientes[clienteIndex].produtos.splice(produtoIndex, 1);
    setSelectedClientes(updatedClientes);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const confirmSelectedProdutos = () => {
    const updatedClientes = [...selectedClientes];
    updatedClientes[currentClienteIndex] = {
      ...updatedClientes[currentClienteIndex],
      produtos: [
        ...updatedClientes[currentClienteIndex].produtos,
        ...tempSelectedProdutos.map(produto => ({
          _id: produto._id?.$oid || produto._id,
          gtin: produto.gtin || 'N/A',
          descricao: produto.description || 'N/A',
          marca: produto.brand?.name || 'N/A',
          thumbnail: produto.thumbnail || 'N/A', // Adiciona o campo thumbnail aqui
        })),
      ],
    };
    setSelectedClientes(updatedClientes);
    setTempSelectedProdutos([]);
    closeProdutoModal();
  };

  const openCadastroModal = async () => {
    setIsModalOpen(true); // Isso deve abrir o modal
    setLoading(true);
    setError("");

    try {
      const registros = await AtendimentoBaseServices.getAtendimentobaseById();
      setCadastros(registros); // Atualiza os registros
      
    } catch (err) {
      console.error("Erro ao carregar cadastros:", err);
      setError("Erro ao carregar cadastros.");
    } finally {
      setLoading(false);
    }
  };



  const closeCadastroModal = () => {
    setIsModalOpen(false); // Fecha o modal
  };



  const handleSelectCadastro = (cadastro) => {
    if (!cadastro) {
      setError("Cadastro inválido. Tente novamente.");
      return;
    }

    const id = cadastro._id?.$oid || cadastro._id;
    
    // Atualiza o estado com o id convertido
    setFormValues((prev) => ({
      ...prev,
      id: id, // Adiciona o `id` como string ao estado
    }));
    // Atualiza o estado com os dados do estabelecimento
    setSelectedEstabelecimento({
      _id: cadastro.estabelecimento.id,
      nome_fantasia: cadastro.estabelecimento.nome_fantasia,
      Razao_Social: cadastro.estabelecimento.Razao_Social,
      cpf_cnpj: cadastro.estabelecimento.cpf_cnpj,
      contato: {
        telefone_fixo: cadastro.estabelecimento.contato.telefone_fixo || "Não informado",
        celular: cadastro.estabelecimento.contato.celular || "Não informado",
        WhatsApp: cadastro.estabelecimento.contato.WhatsApp || "Não informado",
        observacoes: cadastro.estabelecimento.contato.observacoes || "Sem observações",
      },
      endereco: {
        rua: cadastro.estabelecimento.endereco.rua || "",
        numero: cadastro.estabelecimento.endereco.numero || "",
        complemento: cadastro.estabelecimento.endereco.complemento || "",
        bairro: cadastro.estabelecimento.endereco.bairro || "",
        cidade: cadastro.estabelecimento.endereco.cidade || "",
        estado: cadastro.estabelecimento.endereco.estado || "",
        cep: cadastro.estabelecimento.endereco.cep || "",
      },
    });

    // Atualiza os clientes relacionados ao cadastro
    setSelectedClientes(
      cadastro.clientes.map((cliente) => ({
        cliente: {
          _id: cliente.id,
          nome: cliente.nome,
          cpf_cnpj: cliente.cpf_cnpj,
          endereco: cliente.endereco || {},
          contato: cliente.contato || {},
          observacoes: cliente.observacoes || "Sem observações",
        },
        produtos: (cliente.produtos || []).map((produto) => ({
          _id: produto.id,
          gtin: produto.gtin,
          descricao: produto.descricao,
          marca: produto.marca,
          thumbnail: produto.thumbnail,
        })),
      }))
    );

    closeCadastroModal();
  };


  const handleUsarComoBase = () => {
    setFormValues((prev) => ({
      ...prev,
      id: "", // Define o ID como null para criar um novo registro
    }));
    setErro(''); // Limpa mensagens de erro, se houver
  
    // Exibe a mensagem
    window.alert("Atendimento foi descaracterizado pode ser usado para um novo cadastro");

  };
  


  const handleDelete = async () => {
    if (formValues.id) {
      await AtendimentoBaseServices.deleteVisita(formValues.id);
      clearForm();

      // Redirecionar ou atualizar o estado após a exclusão, conforme necessário
    } else {
      alert('ID do atendimento não encontrado para exclusão.');
    }
  };

  return (
    <S.Container>
      <Header />
      <S.BackButton onClick={() => navigate('/home')}>
        <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
        Voltar
      </S.BackButton>
      <S.Form onSubmit={handleSubmit}>
        <S.Title>
          <FormattedMessage id="form.titleAtendimentoBase" defaultMessage={"Cadastro de Atendimento Base"} />
        </S.Title>
        <Tabs defaultIndex={0}>
          <S.StyledTabList>
            <S.StyledTab>
              <FormattedMessage id="tabs.estabelecimento" defaultMessage="Estabelecimento" />
            </S.StyledTab>
            <S.StyledTab>
              <FormattedMessage id="tabs.produto_cliente" defaultMessage="Produto/Cliente" />
            </S.StyledTab>
          </S.StyledTabList>
          <TabPanel>
            <S.ButtonGroup>
              <S.SmallButtonBuscar type="button" onClick={openEstabelecimentoModal}>
                <FormattedMessage id="form.buscar_cadastros" defaultMessage="Buscar Cadastros" />
              </S.SmallButtonBuscar>
            </S.ButtonGroup>
            {selectedEstabelecimento && (
              <>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.nome_fantasia" defaultMessage="Nome Fantasia:" />
                  </S.Label>
                  <S.LabelGray>{selectedEstabelecimento.nome_fantasia}</S.LabelGray>
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.razao_social" defaultMessage="Razão Social:" />
                  </S.Label>
                  <S.LabelGray>{selectedEstabelecimento.Razao_Social}</S.LabelGray>
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.endereco" defaultMessage="Endereço:" />
                  </S.Label>
                  <S.LabelGray>{`${selectedEstabelecimento.endereco.rua}, ${selectedEstabelecimento.endereco.numero}, ${selectedEstabelecimento.endereco.bairro}, ${selectedEstabelecimento.endereco.cidade}, ${selectedEstabelecimento.endereco.estado}, ${selectedEstabelecimento.endereco.cep}`}</S.LabelGray>
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.contato" defaultMessage="Contato:" />
                  </S.Label>
                  <S.LabelGray>{`Telefone: ${selectedEstabelecimento.contato.telefone_fixo}, Celular: ${selectedEstabelecimento.contato.celular}, Email: ${selectedEstabelecimento.contato.email}, WhatsApp: ${selectedEstabelecimento.contato.WhatsApp}`}</S.LabelGray>
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.observacoes" defaultMessage="Observações:" />
                  </S.Label>
                  <S.LabelGray>{selectedEstabelecimento.contato.observacoes}</S.LabelGray>
                </S.InputGroup>
              </>
            )}
            <S.StyledModal
              isOpen={isEstabelecimentoModalOpen}
              onRequestClose={closeEstabelecimentoModal}
              contentLabel="Buscar Estabelecimentos"
              ariaHideApp={false}
            >
              <S.ModalContent>
                <S.SmallButton onClick={closeEstabelecimentoModal}>Fechar</S.SmallButton>
                <S.Input type="text" placeholder="Buscar Estabelecimentos" value={searchTerm} onChange={handleSearchChange} />
                {loadingEstabelecimento && <p>Carregando...</p>}
                {errorEstabelecimento && <p>{errorEstabelecimento}</p>}
                <S.StyledTable>
                  <thead>
                    <tr>
                      <th>Nome Fantasia</th>
                      <th>Razão Social</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredEstabelecimentos.map((estabelecimento, index) => (
                      <tr key={index}>
                        <td>{estabelecimento.nome_fantasia}</td>
                        <td>{estabelecimento.Razao_Social}</td>
                        <td>
                          <S.SmallButton type="button" onClick={() => handleSelectEstabelecimento(estabelecimento)}>
                            Selecionar
                          </S.SmallButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </S.StyledTable>
              </S.ModalContent>
            </S.StyledModal>
          </TabPanel>

          <TabPanel>
            <S.ButtonGroup>
              <S.SmallButtonBuscar type="button" onClick={openClienteModal}>
                <FormattedMessage id="form.buscar_cliente" defaultMessage="Buscar Cliente" />
              </S.SmallButtonBuscar>
            </S.ButtonGroup>

            {selectedClientes.map((clienteData, index) => (
              <S.ClienteContainer key={index}>
                <S.ClienteHeader>
                  <h3>
                    {clienteData.cliente.nome}{" "}
                    <S.ButtonRemove type="button" onClick={() => handleRemoveCliente(index)}>X</S.ButtonRemove>
                  </h3>
                  <S.SmallButton type="button" onClick={() => openProdutoModal(index)}>
                    <FormattedMessage id="form.buscar_produto" defaultMessage="Buscar Produto" />
                  </S.SmallButton>
                </S.ClienteHeader>

                {/* Botão para alternar visibilidade da lista específica */}
                <S.SmallButton type="button" onClick={() => toggleListVisibility(index)}>
                  {visibleLists[index] ? 'Ocultar ' : 'Exibir '}
                </S.SmallButton>

                {/* Exibição condicional da lista específica */}
                {visibleLists[index] && (
                  <S.StyledTable>
                    <thead>
                      <tr>
                        <th>Marca</th>
                        <th>Descrição</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clienteData.produtos.map((produto, produtoIndex) => (
                        <tr key={produtoIndex}>
                          <td>{produto.marca || 'N/A'}</td>
                          <td>{produto.descricao || 'N/A'}</td>
                          <td>
                            <S.SmallButton
                              type="button"
                              onClick={() => handleRemoveProduto(index, produtoIndex)}
                            >
                              X
                            </S.SmallButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </S.StyledTable>
                )}
              </S.ClienteContainer>
            ))}

            {/* Modal de Clientes */}
            <S.StyledModal
              isOpen={isClienteModalOpen}
              onRequestClose={closeClienteModal}
              contentLabel="Buscar Clientes"
              ariaHideApp={false}
            >
              <S.ModalContent>
                <S.SmallButton onClick={closeClienteModal}>Fechar</S.SmallButton>
                <S.Input
                  type="text"
                  placeholder="Buscar Clientes"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                {loadingClientes && <p>Carregando...</p>}
                {errorClientes && <p>{errorClientes}</p>}
                <S.StyledTable>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>CPF/CNPJ</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredClientes.map((cliente, index) => (
                      <tr key={index}>
                        <td>{cliente.nome}</td>
                        <td>{cliente.cpf_cnpj}</td>
                        <td>
                          <S.SmallButton type="button" onClick={() => handleSelectCliente(cliente)}>
                            Selecionar
                          </S.SmallButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </S.StyledTable>
              </S.ModalContent>
            </S.StyledModal>

            {/* Modal de Produtos */}
            <S.StyledModal
              isOpen={isProdutoModalOpen}
              onRequestClose={closeProdutoModal}
              contentLabel="Buscar Produtos"
              ariaHideApp={false}
            >
              <S.ModalContent>
                <S.SmallButton onClick={closeProdutoModal}>Fechar</S.SmallButton>
                <S.Input
                  type="text"
                  placeholder="Buscar Produtos"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                {loadingProdutos && <p>Carregando...</p>}
                {errorProdutos && <p>{errorProdutos}</p>}
                <S.StyledTable>
                  <thead>
                    <tr>
                      <th>Marca</th>
                      <th>Descrição</th>
                      <th>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <span>Ações</span>
                          <S.SmallButton
                            type="button"
                            onClick={() => handleSelectAll(!areAllProdutosSelected())}
                            style={{
                              fontSize: '12px', // Ajusta o tamanho do texto
                              padding: '4px 8px', // Reduz o preenchimento interno
                              marginTop: '8px', // Adiciona espaço entre o texto "Ações" e o botão
                            }}
                          >
                            {areAllProdutosSelected() ? 'Desmarcar Todos' : 'Selecionar Todos'}
                          </S.SmallButton>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredProdutos.map((produto, index) => (
                      <tr key={index}>
                        <td>{produto.brand?.name || 'N/A'}</td>
                        <td>{produto.description || 'N/A'}</td>
                        <td>
                          <S.SmallButton
                            type="button"
                            onClick={() => handleTempSelectProduto(produto)}
                            selected={tempSelectedProdutos.some((p) => p._id === produto._id)}
                          >
                            {tempSelectedProdutos.some((p) => p._id === produto._id)
                              ? 'Desmarcar'
                              : 'Selecionar'}
                          </S.SmallButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </S.StyledTable>
                <S.ButtonGroup>
                  <S.SmallButton type="button" onClick={confirmSelectedProdutos}>
                    Confirmar Seleção
                  </S.SmallButton>
                </S.ButtonGroup>
              </S.ModalContent>
            </S.StyledModal>


          </TabPanel>
        </Tabs>
        <S.StyledModal
          isOpen={isModalOpen}
          onRequestClose={closeCadastroModal}
          contentLabel="Buscar Cadastros"
          ariaHideApp={false}
        >
          <S.ModalContent>
            <S.SmallButton onClick={closeCadastroModal}>Fechar</S.SmallButton>
            <S.Input
              type="text"
              placeholder="Buscar Cadastros"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {loading ? (
              <p>Carregando...</p>
            ) : error ? (
              <p>{error}</p>
            ) : cadastros.length > 0 ? (
              <S.StyledTable>
                <thead>
                  <tr>
                    <th>Nome Fantasia</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {cadastros
                    .filter((cadastro) =>
                      cadastro.estabelecimento?.nome_fantasia
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .map((cadastro, index) => (
                      <tr key={index}>
                        <td>
                          {cadastro.estabelecimento?.nome_fantasia || "Não disponível"}
                        </td>
                        <td>
                          <S.SmallButton
                            type="button"
                            onClick={() => handleSelectCadastro(cadastro)}
                          >
                            Selecionar
                          </S.SmallButton>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </S.StyledTable>
            ) : (
              <p>Nenhum cadastro encontrado.</p>
            )}
          </S.ModalContent>
        </S.StyledModal>

        {erro && <S.Erro>{erro}</S.Erro>}
        <S.ButtonGroup>

          <S.Button type="button" onClick={handleUsarComoBase}>
            <FormattedMessage id="form.usar_como_base" defaultMessage="Usar como Base" />
          </S.Button>

          <S.Button type="submit">
            <FormattedMessage id="form.cadastrar" defaultMessage="Cadastrar" />
          </S.Button>

          {secureStorage.getItem("TipoVisita") === 3 && (
            <S.Button type="button" onClick={handleDelete}>
              <FormattedMessage id="form.deletar" defaultMessage="Deletar" />
            </S.Button>
          )}
          {secureStorage.getItem("TipoVisita") !== 1 && (
            <S.Button type="button" onClick={openCadastroModal}>
              <FormattedMessage id="form.buscar_cadastros" defaultMessage="Buscar Cadastros" />
            </S.Button>
          )}
          {secureStorage.getItem("TipoVisita") !== 1 && (
            <S.Button type="button" onClick={clearForm}>
              <FormattedMessage id="form.novo" defaultMessage="Novo" />
            </S.Button>
          )}
        </S.ButtonGroup>
      </S.Form>
      <Footer />
    </S.Container>
  );
}

export default AtendimentoBase;
