import React, { useEffect, useState } from 'react';
import api from '../api';

const BuscarImagem = ({ fileId, idRede, onImageLoad }) => {
  const apiUrl = process.env.REACT_APP_API_BASE_33;

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await api.get(`${apiUrl}/view/${fileId}?id_rede=${idRede}`, {
          responseType: 'blob', // Para lidar com arquivos binários
        });
        const imageBlob = response.data;
        const imageObjectUrl = URL.createObjectURL(imageBlob);
        onImageLoad(imageObjectUrl); // Passa a URL da imagem para o componente pai
      } catch (err) {
        console.error('Erro ao carregar imagem:', err);
      }
    };

    fetchImage();
  }, [fileId, idRede, apiUrl, onImageLoad]);

  return null; // Componente apenas busca a imagem e não exibe nada diretamente
};

export default BuscarImagem;
