import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Section = styled.div`
  width: 100%;
  padding: 20px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

export const HomeCardArea = styled.div`
  display: flex;
  justify-content: center; /* Centraliza os cards */
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  button {
    background: none;
    border: none;
    margin: 10px; /* Isso adiciona espaço ao redor de cada botão/card */
  }
`;

export const HomeCardContainer = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;

  /* Ajustes para centralizar e exibir o conteúdo */
  .home-card-icon {
    font-size: 50px;
    color: #001f3f;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px; /* Espaço abaixo do ícone */
  }

  .home-card-title {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
`;

// Estilos para o Modal
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;

  h2 {
    margin-bottom: 20px;
  }

  select {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  div {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  button {
    padding: 10px 20px;
    background-color: #cf0505;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #a10404;
    }
  }
`;
export const BackButton = styled.button`
    position: absolute;
    top: 100px;
    left: 20px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #B22222;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: #800000;
    }
`;