import React from 'react';
import api from '../api';

const DownloadImage = ({ fileId, idRede }) => {
    const apiUrl = process.env.REACT_APP_API_BASE_33;

    const handleDownload = async () => {


        try {
            // Realiza a solicitação para o endpoint de download
            const response = await api.get(`${apiUrl}/download/${fileId}?id_rede=${idRede}`, {
                responseType: 'blob', // Necessário para lidar com arquivos binários
            });

            // Cria um URL para o blob recebido
            const downloadUrl = URL.createObjectURL(response.data);


            // Cria um link temporário para realizar o download
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = `imagem_${fileId}.png`; // Nome do arquivo baixado


            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);


        } catch (error) {
            console.error('Erro ao tentar realizar o download:', error); // Log em caso de erro
        }
    };

    return (
        <div>
            <button onClick={handleDownload}>Download</button>
        </div>
    );
};

export default DownloadImage;
