class AddressService {
    static async fetchCepViaCEP(uf, cidade, logradouro) {
        try {
            // Codifica os parâmetros para evitar problemas com caracteres especiais
            const encodedUf = encodeURIComponent(uf.trim());
            const encodedCidade = encodeURIComponent(cidade.trim());
            const encodedLogradouro = encodeURIComponent(logradouro.trim());

            // Monta a URL com os valores codificados
            const url = `https://viacep.com.br/ws/${encodedUf}/${encodedCidade}/${encodedLogradouro}/json/`;
            

            // Faz a requisição
            const res = await fetch(url);

            // Verifica se a resposta foi bem-sucedida
            if (!res.ok) {
                throw new Error(`Erro ao buscar o CEP: ${res.status} - ${res.statusText}`);
            }

            // Processa a resposta
            const data = await res.json();

            // Valida se os dados retornados estão corretos
            if (Array.isArray(data) && data.length > 0) {
                
                return data; // Retorna todos os endereços encontrados
            }

            throw new Error('Nenhum endereço encontrado para os dados fornecidos.');
        } catch (error) {
            console.error('Erro ao consultar o ViaCEP:', error.message);
            throw error; // Lança o erro para o código chamador tratar
        }
    }
}

export default AddressService;
