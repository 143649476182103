import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Nossos Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HomeCard from '../../components/HomeCard';
import HomeCabecario from '../../components/HomeCabecario';
import HomeCabecario2 from '../../components/HomeCabecario2';
//icon
import Estabelecimento from '../../assets/Estabelecimento.png';
import Cliente from '../../assets/Cliente.png';
import Colaborador from '../../assets/Colaborador.png';
import Produto from '../../assets/Produto.png';
import Visita from '../../assets/Visitas.png';
import CockPit from '../../assets/CockPit.png';
import secureStorage from '../../utils/SecureStorage';
import Marketdo from '../../assets/network.png';


function Home() {
    const navigate = useNavigate();
    const [showRede, setShowRede] = useState(false);
    
    useEffect(() => {
        // Configurações para validar isRede e RedeMaster
        const RedeMaster = process.env.REACT_APP_API_BASE_43;
        const isRede = secureStorage.getItem("isRede");

        // Verificação para definir showRede
        const visibility =  isRede && isRede === RedeMaster;
        setShowRede(visibility);

        
        
    }, []);

    return (
        <S.Container>
            <Header />
            <HomeCabecario title="Cadastros" />
            <S.HomeCardArea>
               {/* Validação com showRede */}
               {showRede && (
                    <button type="button" onClick={() => navigate('/Marketdo')}>
                        <HomeCard
                            title={<FormattedMessage id="home.Marketdo" defaultMessage="Marketdo" />}
                            icon={Marketdo}
                            type="button"
                        />
                    </button>
                )}
                {secureStorage.getItem("isVisibleEstabelecimento") && (
                    <button type="button" onClick={() => navigate('/Estabelecimento')}>
                        <HomeCard title={<FormattedMessage id="home.estabelecimentos" defaultMessage="Estabelecimentos" />} icon={Estabelecimento} type="button" />
                    </button>
                )}
                {secureStorage.getItem("isVisibleCliente") && (
                    <button type="button" onClick={() => navigate('/Cliente')}>
                        <HomeCard title={<FormattedMessage id="home.clientes" defaultMessage="Clientes" />} icon={Cliente} type="button" />
                    </button>
                )}
                {secureStorage.getItem("isVisibleColaborador") && (
                    <button type="button" onClick={() => navigate('/colaborador')}>
                        <HomeCard title={<FormattedMessage id="home.colaboradores" defaultMessage="Colaboradores" />} icon={Colaborador} type="button" />
                    </button>
                )}
                {secureStorage.getItem("isVisibleProduto") && (
                    <button type="button" onClick={() => navigate('/produto')}>
                        <HomeCard title={<FormattedMessage id="home.produtos" defaultMessage="Produtos" />} icon={Produto} type="button" />
                    </button>
                )}
                {secureStorage.getItem("isVisibleVisita") && (
                    <button type="button" onClick={() => navigate('/AtendimentoBase')}>
                        <HomeCard title={<FormattedMessage id="home.AtendimentoBase" defaultMessage="Atendimento Base" />} icon={Visita} type="button" />
                    </button>
                )}
            </S.HomeCardArea>
            <HomeCabecario2 title="Acompanhamento" />
            <S.HomeCardArea>
               {secureStorage.getItem("isVisiblePonto") && (
                    <button type="button" onClick={() => navigate('/Ponto')}>
                        <HomeCard title={<FormattedMessage id="home.Registro_Ponto" defaultMessage="Registro Ponto" />} icon={Visita} type="button" />
                    </button>
                )}
                {secureStorage.getItem("isVisiblegestaoponto") && (
                    <button type="button" onClick={() => navigate('/GestaoPonto')}>
                        <HomeCard title={<FormattedMessage id="home.GestaoPonto" defaultMessage="Gestão Ponto" />} icon={Visita} type="button" />
                    </button>
                )}   
                {secureStorage.getItem("isVisibleVisita") && (
                    <button type="button" onClick={() => navigate('/Atendimento')}>
                        <HomeCard title={<FormattedMessage id="home.agendar_visitas" defaultMessage="Agendar Atendimentos" />} icon={Visita} type="button" />
                    </button>
                )}
                {secureStorage.getItem("isVisibleCockpit") && (
                    <button type="button" onClick={() => navigate('/Quadrodeatendimentos')}>
                        <HomeCard title={<FormattedMessage id="home.cockpit" defaultMessage="Quadro Atendimentos" />} icon={CockPit} type="button" />
                    </button>
                )}
                {secureStorage.getItem("isVisibleRelatorio") && (
                    <button type="button" onClick={() => navigate('/Relatorio')}>
                        <HomeCard title={<FormattedMessage id="home.Relatorio" defaultMessage="Relatorios" />} icon={CockPit} type="button" />
                    </button>
                )}
            </S.HomeCardArea>
            <Footer />
        </S.Container>
    );
}

export default Home;
