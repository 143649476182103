import api from '../api';
import secureStorage from '../../utils/SecureStorage';

class BuscaRedes {
  constructor() {
    this.resultadoRede = [];
    this.loadingRede = false;
    this.errorRede = null;
  }

  // Método para carregar redes
  async loadRede() {
    this.loadingRede = true;
    this.errorRede = null;

    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_44}`;
      
      const response = await api.get(apiUrl);

      if (response.data && Array.isArray(response.data.cadastros)) {
        if (response.data.cadastros.length > 0) {
          // Armazenar no secureStorage para reutilização futura
          secureStorage.setItem("datarede", JSON.stringify(response.data.cadastros));
          this.resultadoRede = response.data.cadastros;
          
        } else {
          console.warn("Nenhuma rede encontrada no servidor.");
          this.resultadoRede = [];
        }
      } else {
        console.error("Estrutura de resposta inválida:", response.data);
        throw new Error("Nenhuma rede encontrada.");
      }
    } catch (error) {
      console.error("Erro ao buscar rede:", error.message || error);
      this.errorRede = error.message || "Erro ao buscar rede.";
      this.resultadoRede = [];
    } finally {
      this.loadingRede = false;
    }
  }

  // Métodos para acessar os dados
  getResultadoRede() {
    return this.resultadoRede;
  }

  isLoading() {
    return this.loadingRede;
  }

  getErrorRede() {
    return this.errorRede;
  }
}

export default BuscaRedes;
