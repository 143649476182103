import { useState } from 'react';
import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const useBuscaEmpreCnpj = () => {
  const [resultadoEmpresas, setResultadoEmpresas] = useState([]);
  const [loadingEmpresas, setLoadingEmpresas] = useState(false);
  const [errorEmpresas, setErrorEmpresas] = useState(null);

  const loadEmpresas = async (idRede) => {
    setLoadingEmpresas(true);
    setErrorEmpresas(null);

    try {
      if (!idRede) {
        throw new Error("ID da rede é obrigatório.");
      }

      const apiUrl = `${process.env.REACT_APP_API_BASE_25}${idRede}`;
      
      const response = await api.get(apiUrl);

      if (response.data && Array.isArray(response.data.cadastros)) {
        if (response.data.cadastros.length > 0) {
          // Armazenar no secureStorage para reutilização futura
          secureStorage.setItem("dataredecnpj", JSON.stringify(response.data.cadastros));
          setResultadoEmpresas(response.data.cadastros);
          
        } else {
          console.warn("Nenhuma empresa encontrada no servidor.");
          setResultadoEmpresas([]);
        }
      } else {
        console.error("Estrutura de resposta inválida:", response.data);
        throw new Error("Nenhuma empresa encontrada.");
      }
    } catch (error) {
      console.error("Erro ao buscar empresas:", error.message || error);
      setErrorEmpresas(error.message || "Erro ao buscar empresas.");
      setResultadoEmpresas([]);
    } finally {
      setLoadingEmpresas(false);
    }
  };

  return { resultadoEmpresas, loadingEmpresas, errorEmpresas, loadEmpresas };
};

export default useBuscaEmpreCnpj;
