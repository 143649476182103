import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const BuscarModulo = async () => {
  const apiUrl = process.env.REACT_APP_API_BASE_47;

  try {
    const response = await api.get(`${apiUrl}`);
    

    if (response.data?.cadastros) {
      secureStorage.setItem("datarede", JSON.stringify(response.data.cadastros));
      return response.data.cadastros; // Retorna o array de cadastros
    } else {
      console.warn("Nenhum cadastro encontrado.");
      return []; // Retorna um array vazio em caso de ausência de dados
    }
  } catch (error) {
    console.error("Erro ao buscar cadastros:", error);
    return []; // Retorna um array vazio em caso de erro
  }
};

export default BuscarModulo;
