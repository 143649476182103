import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px; /* Adiciona espaço abaixo do header */

  @media (max-width: 768px) {
    padding-top: 120px; /* Ajuste adicional para dispositivos menores */
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 600px;
  background: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #cf0505;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 15px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    padding: 10px;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 120px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
  box-sizing: border-box;
  font-size: 14px;
`;

export const Title = styled.h1`
  color: #cf0505;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
`;

export const InputGroup = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #cf0505;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around; /* Distribui os botões uniformemente */
  gap: 10px; /* Espaçamento entre os botões */
  width: 100%;
  margin-top: 20px; /* Adiciona espaço acima dos botões */
`;

export const Button = styled.button`
  flex: 1; /* Cada botão ocupará a mesma largura */
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #003366;
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: #002244;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    flex: none; /* Os botões não ocuparão a mesma largura em dispositivos menores */
    width: 100%; /* Cada botão ocupará toda a largura */
    margin-bottom: 10px; /* Espaçamento entre os botões em dispositivos menores */

    &:last-child {
      margin-bottom: 0; /* Remove o espaçamento do último botão */
    }
  }
`;

export const Erro = styled.p`
  color: red;
`;

export const BackButton = styled.button`
  position: absolute;
  top: 80px; /* Ajuste conforme necessário */
  left: 20px; /* Ajuste conforme necessário */
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #B22222;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: #800000;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;

  h2 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }

  button {
    background: none;
    border: none;
    color: #b22222;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      color: #800000;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
  }

  th {
    background-color: #f0f0f0;
    color: #333;
  }

  tr:nth-child(odd) {
    background-color: #fafafa;
  }

  tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
`;

export const TableContainer = styled.div`
  max-height: 300px; /* Altura máxima da tabela */
  overflow-y: auto; /* Permite rolagem se necessário */
`;
