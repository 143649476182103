import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 150px;

    @media (max-width: 768px) {
        padding-top: 120px;
    }
`;

export const Form = styled.form`
    width: 100%;
    max-width: 600px;
    background: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #cf0505;
    margin: 0 auto;

    @media (max-width: 768px) {
        max-width: 90%;
        padding: 15px;
    }

    @media (max-width: 480px) {
        max-width: 100%;
        padding: 10px;
    }
`;

export const Title = styled.h1`
    color: #cf0505;
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
`;

export const InputGroup = styled.div`
    margin-bottom: 15px;
`;

export const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    color: #cf0505;
`;

export const Select = styled.select`
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
`;

export const Checkbox = styled.input`
    margin-right: 10px;
`;

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
`;

export const Button = styled.button`
    width: calc(50% - 5px);
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #003366;
    color: white;
    font-size: 16px;
    cursor: pointer;

    &:hover {
        background-color: #002244;
    }

    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const Fieldset = styled.fieldset`
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 20px;
`;

export const Legend = styled.legend`
    padding: 0 5px;
    color: #333;
    font-size: 14px;
`;

export const BackButton = styled.button`
    position: absolute;
    top: 80px;
    left: 20px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #B22222;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: #800000;
    }
`;
export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; /* Espaço entre o toggle e o nome do módulo */
`;

export const ToggleLabel = styled.span`
  font-size: 16px;
  color: #333;
`;

export const ToggleSwitch = styled.div`
  width: 40px;
  height: 20px;
  background-color: ${(props) => (props.isActive ? '#4caf50' : '#ccc')};
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;

  &:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    top: 2px;
    left: ${(props) => (props.isActive ? '22px' : '2px')};
    transition: left 0.3s;
  }
`;


export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 10px 20px;

    h2 {
        margin: 0;
        font-size: 18px;
        color: #cf0505;
    }

    button {
        background: none;
        border: none;
        color: #cf0505;
        font-size: 16px;
        cursor: pointer;

        &:hover {
            color: #800000;
        }
    }
`;

export const SearchInput = styled.input`
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

export const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;

    thead {
        background-color: #f5f5f5;

        tr {
            border-bottom: 2px solid #ddd;
        }

        th {
            text-align: left;
            padding: 10px;
            color: #333;
            font-size: 14px;
        }
    }

    tbody {
        tr {
            cursor: pointer;
            transition: background-color 0.2s;

            &:hover {
                background-color: #f9f9f9;
            }
        }

        td {
            padding: 10px;
            border-bottom: 1px solid #ddd;
            font-size: 14px;
        }
    }
`;