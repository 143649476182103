import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 50px; /* Adiciona espaçamento inferior */

    @media (max-width: 768px) {
        padding-top: 100px;
        padding-bottom: 30px; /* Ajusta o espaçamento inferior em telas menores */
    }
`;


export const Form = styled.form`
    width: 100%;
    max-width: 600px;
    background: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    border: 2px solid #cf0505; /* Adiciona a borda vermelha */
`;

export const Title = styled.h1`
    color: #cf0505;
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
`;

export const InputGroup = styled.div`
    margin-bottom: 15px;
`;

export const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    color: #333;
`;

export const Select = styled.select`
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
`;

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px; /* Espaço entre os botões */
    margin-top: 20px;
`;

export const Button = styled.button`
    width: calc(33.33% - 10px); /* Ajuste para três botões */
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #003366;
    color: white;
    font-size: 16px;
    cursor: pointer;

    &:hover {
        background-color: #002244;
    }

    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const BackButton = styled.button`
    position: absolute;
    top: 100px;
    left: 20px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #cf0505;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: #800000;
    }
`;

export const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;

    th, td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
    }

    th {
        background-color: #f4f4f4;
        font-weight: bold;
    }

    tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    tr:hover {
        background-color: #f1f1f1;
    }

    td button {
        padding: 5px 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    td button:hover {
        background-color: #0056b3;
    }
`;

export const Input = styled.input`
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-size: 16px;

    &:focus {
        outline: none;
        border-color: #cf0505;
    }
`;
