import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export const Content = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px;
`;

export const ButtonCard = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 10px;
    width: 150px;
    height: 150px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    }

    span {
        margin-top: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #333;
    }

    svg {
        color: #2D4A9D; /* Azul igual à imagem */
    }
`;
export const BackButton = styled.button`
    position: absolute;
    top: 100px;
    left: 20px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #cf0505;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: #800000;
    }
`;            