import React from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FaClipboardList, FaFileAlt } from 'react-icons/fa'; // Ícones para Espelho Ponto e Relatório Ponto

// Nossos Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { MdArrowBack } from 'react-icons/md';

function Relatorio() {
    const navigate = useNavigate();

    return (
        <S.Container>
            <Header />
            <S.BackButton onClick={() => navigate('/home')}>
                <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
                Voltar
            </S.BackButton>
            <S.Content>
                {/* Botão Espelho Ponto */}
                <S.ButtonCard onClick={() => navigate('/EspelhoPonto')}>
                    <FaClipboardList size={40} color="#2D4A9D" /> {/* Cor ajustada */}
                    <span>
                        <FormattedMessage id="button.espelhoPonto" defaultMessage="Espelho Ponto" />
                    </span>
                </S.ButtonCard>

                {/* Botão Relatório Ponto */}
                <S.ButtonCard onClick={() => navigate('/RelatorioPonto')}>
                    <FaFileAlt size={40} color="#2D4A9D" /> {/* Cor ajustada */}
                    <span>
                        <FormattedMessage id="button.relatorioPonto" defaultMessage="Relatório Ponto" />
                    </span>
                </S.ButtonCard>
            </S.Content>
            <Footer />
        </S.Container>
    );
}

export default Relatorio;
