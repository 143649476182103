import styled from 'styled-components';
import { TabList, Tab } from 'react-tabs';
import Modal from 'react-modal';

export const Container = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 60px;
  padding-bottom: 60px;

  @media (max-width: 768px) {
    padding-top: 100px;
    padding-bottom: 80px;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 800px;
  background: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #cf0505;
  margin: 0 auto 20px auto;

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 15px;
    margin-bottom: 30px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    padding: 10px;
    margin-bottom: 40px;
  }
`;

export const Title = styled.h1`
  color: #cf0505;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
`;

export const InputGroup = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #cf0505;
`;

export const LabelGray = styled.label`
  display: block;
  margin-bottom: 5px;
  color: gray;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  resize: vertical;
  min-height: 100px;
`;

export const Button = styled.button`
  width: calc(50% - 5px);
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #003366;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    background-color: #002244;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const SmallButton = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #003366;
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #002244;
  }
`;

export const SmallButtonBuscar = styled.button`
  width: auto;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #003366;
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    background-color: #002244;
  }
`;

export const ButtonRemove = styled.button`
  background-color: #cf0505;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #a00303;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px; /* Add margin-top to ensure space between data and buttons */
`;

export const StyledTabList = styled(TabList)`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  border-bottom: 1px solid #ccc;
`;

StyledTabList.tabsRole = 'TabList';

export const StyledTab = styled(Tab)`
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background: #f0f0f0;
  border: 1px solid #ccc;
  margin-right: -1px;

  &:last-of-type {
    margin-right: 0;
  }

  &.react-tabs__tab--selected {
    color: #cf0505;
    background-color: #f0f0f0;
    border-color: #ccc;
    border-bottom: none;
  }

  @media (max-width: 768px) {
    padding: 6px 12px;
  }

  @media (max-width: 480px) {
    padding: 4px 8px;
  }
`;

StyledTab.tabsRole = 'Tab';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border-radius: 8px;

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f0f0f0;
    color: #333;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
`;

export const GtinImageGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  & > div {
    flex: 1;
    margin-right: 10px;
  }

  & > div:last-child {
    margin-right: 0;
  }
`;

export const PriceGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  & > div {
    flex: 1;
    margin-right: 10px;
  }

  & > div:last-child {
    margin-right: 0;
  }
`;

export const DimensionGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  & > div {
    flex: 1;
    margin-right: 10px;
  }

  & > div:last-child {
    margin-right: 0;
  }
`;

export const WeightGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  & > div {
    flex: 1;
    margin-right: 10px;
  }

  & > div:last-child {
    margin-right: 0;
  }
`;

export const DateTimeGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  & > div {
    flex: 1;
    margin-right: 10px;
  }

  & > div:last-child {
    margin-right: 0;
  }
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  & > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > label {
      flex: 1 0 30%;
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      input {
        margin-right: 5px;
      }
    }
  }
`;

export const Erro = styled.p`
  color: red;
`;

export const StyledModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  max-width: 1000px; /* Adjusted for larger modal */
  width: 90%;
  background: #FFF;
  border-radius: 10px;
  border: none;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 95%;
    padding: 10px;
  }
`;

export const ModalContent = styled.div`
  max-height: 500px; /* Adjusted for larger modal */
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  & > button {
    align-self: flex-end;
    margin-bottom: 10px;
  }

  & > input {
    margin-bottom: 10px;
  }
`;

export const ClienteContainer = styled.div`
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
`;

export const ClienteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
  }
`;

export const GtinGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    flex: 1;
    margin-right: 10px;
  }

  & > div:last-child {
    margin-right: 0;
  }
`;

export const BackButton = styled.button`
    position: absolute;
    top: 100px;
    left: 20px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #cf0505;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: #800000;
    }
`;       