import api from '../api';

async function sendUpload(idRede, formData) {
    const apiUrl = process.env.REACT_APP_API_BASE_51; // URL base da API
    try {
        

        // Verifica se os parâmetros são válidos
        if (!idRede) {
            throw new Error('ID da rede ausente. O upload não pode ser concluído.');
        }

        if (!formData || !(formData instanceof FormData)) {
            throw new Error('FormData inválido ou ausente.');
        }

        // Envia o FormData para o endpoint do servidor
        const response = await api.post(`${apiUrl}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Define o tipo de conteúdo
            },
        });

        
        return response.data; // Retorna os dados do backend (supondo que inclui a URL do arquivo enviado)
    } catch (error) {
        if (error.response) {
            // Erros retornados pelo servidor
            console.error('Erro do servidor ao fazer upload:', error.response.data);
        } else if (error.request) {
            // Erros relacionados à falta de resposta do servidor
            console.error('Nenhuma resposta do servidor:', error.request);
        } else {
            // Erros de configuração ou execução
            console.error('Erro ao configurar o upload:', error.message);
        }
        throw error; // Relança o erro para ser tratado no componente
    }
}

export default sendUpload;
