import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import * as S from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import useBuscaEstabelecimento from '../../services/Estabelecimento/useBuscaEstabelecimento';
import AtendimentoBaseServices from '../../services/AtendimentoBase/AtendimentoBaseServices';
import useBuscaColaborador from '../../services/Colaborador/useBuscaClolaborador';
import useBuscaClientes from '../../services/Cliente/useBuscaClientes';
import BuscaProduto from '../../services/Produto/BuscaProdutoCliente';
import VisitaServices from '../../services/Visita/VisitaServices';
import secureStorage from '../../utils/SecureStorage';
import moment from 'moment-timezone';
import { MdArrowBack } from 'react-icons/md';


function Visita({ mode }) {
  const { id } = useParams();
  const [erro, setErro] = useState('');
  const [selectedEstabelecimento, setSelectedEstabelecimento] = useState(null);
  const [selectedColaborador, setSelectedColaborador] = useState(null);
  const [selectedClientes, setSelectedClientes] = useState([]);
  const [isEstabelecimentoModalOpen, setIsEstabelecimentoModalOpen] = useState(false);
  const [isColaboradorModalOpen, setIsColaboradorModalOpen] = useState(false);
  const [isClienteModalOpen, setIsClienteModalOpen] = useState(false);
  const [isProdutoModalOpen, setIsProdutoModalOpen] = useState(false);
  const [currentClienteIndex, setCurrentClienteIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEstabelecimentos, setFilteredEstabelecimentos] = useState([]);
  const [filteredColaboradores, setFilteredColaboradores] = useState([]);
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [filteredProdutos, setFilteredProdutos] = useState([]);
  const navigate = useNavigate();
  const [tempSelectedProdutos, setTempSelectedProdutos] = useState([]);
  const [visibleLists, setVisibleLists] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cadastros, setCadastros] = useState([]);
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [error, setError] = useState(""); // Estado de erro



  const toggleListVisibility = (index) => {
    setVisibleLists((prev) => ({
      ...prev,
      [index]: !prev[index], // Alterna o estado da lista específica
    }));
  };

  // Estado inicial do formulário
  const [formValues, setFormValues] = useState({
    descricao_visita: '',
    data: moment().tz("America/Sao_Paulo").format('YYYY-MM-DD'), // Inicializando com a data atual no fuso horário SP
    hora: '',
    repetir_dias: [],
    repetir_meses: 0,
  });



  const { searchResults: searchResultsEstabelecimento, loadregistros: loadregistrosEstabelecimento, loading: loadingEstabelecimento, error: errorEstabelecimento } = useBuscaEstabelecimento();
  const { searchResults: searchResultsColaborador, loadregistros: loadregistrosColaborador, loading: loadingColaborador, error: errorColaborador } = useBuscaColaborador();
  const { searchResults: searchResultsClientes, loadregistros: loadregistrosClientes, loading: loadingClientes, error: errorClientes } = useBuscaClientes();
  const { searchResultsprodutos: searchResultsProdutos, loadprodutos: loadregistrosProdutos, loading: loadingProdutos, error: errorProdutos } = BuscaProduto();

  useEffect(() => {
    if (mode === 'edit' && id) {
      VisitaServices.getVisitaById(id).then((data) => {
        setFormValues({
          descricao_visita: data.descricao_visita || '',
          data: data.data?.$date ? new Date(data.data.$date).toISOString().split('T')[0] : '',
          hora: data.hora || '',
          repetir_dias: data.repetir_dias || [],
          repetir_meses: data.repetir_meses || 0,
        });
        setSelectedEstabelecimento(data.estabelecimento || null);
        setSelectedColaborador(data.colaborador || null);
        setSelectedClientes(data.clientes?.map(cliente => ({
          cliente,
          produtos: cliente.produtos.map(produto => ({
            ...produto,
            gtin: produto.gtin || 'N/A',
            descricao: produto.descricao || 'N/A',
            marca: produto.marca || 'N/A',
          })) || [],
        })) || []);
      }).catch((error) => {
        setErro("Erro ao carregar dados do atendimento para edição.");
        console.error(error);
      });
    }
  }, [mode, id]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = searchResultsEstabelecimento.filter(estabelecimento =>
        (estabelecimento.nome_fantasia && estabelecimento.nome_fantasia.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (estabelecimento.razao_social && estabelecimento.razao_social.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredEstabelecimentos(filtered);
    } else {
      setFilteredEstabelecimentos(searchResultsEstabelecimento);
    }
  }, [searchTerm, searchResultsEstabelecimento]);

  const handleTempSelectProduto = (produto) => {
    setTempSelectedProdutos((prev) => {
      if (prev.some(p => p._id === produto._id)) {
        // Se já existir, removê-lo
        return prev.filter(p => p._id !== produto._id);
      } else {
        // Caso contrário, adicioná-lo
        return [...prev, produto];
      }
    });
  };


  useEffect(() => {
    if (searchTerm) {
      const filtered = searchResultsColaborador.filter(colaborador =>
        (colaborador.nome && colaborador.nome.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (colaborador.sobrenome && colaborador.sobrenome.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredColaboradores(filtered);
    } else {
      setFilteredColaboradores(searchResultsColaborador);
    }
  }, [searchTerm, searchResultsColaborador]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = searchResultsClientes.filter(cliente =>
        (cliente.nome && cliente.nome.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (cliente.cpf_cnpj && cliente.cpf_cnpj.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredClientes(filtered);
    } else {
      setFilteredClientes(searchResultsClientes);
    }
  }, [searchTerm, searchResultsClientes]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = searchResultsProdutos.filter(produto =>
        (produto.brand?.name && produto.brand.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (produto.description && produto.description.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredProdutos(filtered);
    } else {
      setFilteredProdutos(searchResultsProdutos);
    }
  }, [searchTerm, searchResultsProdutos]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      if (checked) {
        setFormValues(prevValues => ({
          ...prevValues,
          repetir_dias: [...prevValues.repetir_dias, name]
        }));
      } else {
        setFormValues(prevValues => ({
          ...prevValues,
          repetir_dias: prevValues.repetir_dias.filter(day => day !== name)
        }));
      }
    } else {
      const newValue = name === 'data'
        ? moment.tz(value, "America/Sao_Paulo").format('YYYY-MM-DD')
        : value;

      setFormValues(prevValues => ({
        ...prevValues,
        [name]: newValue
      }));
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedEstabelecimento) {
      setErro('Você precisa informar o estabelecimento.');
      return;
    }
    if (selectedClientes.length === 0) {
      setErro('Você precisa informar pelo menos um cliente.');
      return;
    }

    for (let i = 0; i < selectedClientes.length; i++) {
      if (!selectedClientes[i].produtos || selectedClientes[i].produtos.length === 0) {
        setErro(`O cliente ${selectedClientes[i].cliente.nome} não possui produtos.`);
        return;
      }
    }
    if (!selectedColaborador) {
      setErro('Você precisa informar o Colaborador.');
      return;
    }

    if (!formValues.data) {
      setErro('Você precisa informar a data.');
      return;
    }

    if (!formValues.hora) {
      setErro('Você precisa informar a hora.');
      return;
    }



    if (!selectedColaborador) {
      setErro('Você precisa informar o colaborador.');
      return;
    }



    const visita = {
      _id: id, // Use the provided id in the URL
      id_rede: secureStorage.getItem("id_r"),
      descricao_visita: formValues.descricao_visita,
      data: formValues.data,
      hora: formValues.hora,
      repetir_dias: formValues.repetir_dias,
      repetir_meses: formValues.repetir_meses,
      status: "0",
      clientes: selectedClientes.map(clienteData => ({
        _id: clienteData.cliente._id?.$oid || clienteData.cliente._id, // Ensure proper access to _id
        nome: clienteData.cliente.nome,
        cpf_cnpj: clienteData.cliente.cpf_cnpj,
        endereco: {
          rua: clienteData.cliente.endereco.rua,
          numero: clienteData.cliente.endereco.numero,
          complemento: clienteData.cliente.endereco.complemento,
          bairro: clienteData.cliente.endereco.bairro,
          cidade: clienteData.cliente.endereco.cidade,
          estado: clienteData.cliente.endereco.estado,
          cep: clienteData.cliente.endereco.cep
        },
        contato: {
          telefone_fixo: clienteData.cliente.contato.telefone_fixo,
          celular: clienteData.cliente.contato.celular,
          email: clienteData.cliente.contato.email,
          WhatsApp: clienteData.cliente.contato.WhatsApp
        },
        observacoes: clienteData.cliente.observacoes,
        produtos: clienteData.produtos.map(produto => ({
          _id: produto._id?.$oid || produto._id, // Ensure proper access to _id
          gtin: produto.gtin,
          descricao: produto.descricao,
          marca: produto.marca,
          thumbnail: produto.thumbnail || 'N/A' // Certifique-se de que o campo thumbnail está aqui
        }))
      })),
      colaborador: {
        _id: selectedColaborador._id?.$oid || selectedColaborador._id, // Ensure proper access to _id
        nome: selectedColaborador.nome,
        cpf_cnpj: selectedColaborador.cpf_cnpj,
        dadosprofissionais: {
          cargo: selectedColaborador.dadosprofissionais.cargo,
          departamento: selectedColaborador.dadosprofissionais.departamento,
          data_admissao: selectedColaborador.dadosprofissionais?.data_admissao?.$date
        },
        contato: {
          telefone_fixo: selectedColaborador.contato.telefone_fixo,
          celular: selectedColaborador.contato.celular,
          email: selectedColaborador.contato.email,
          WhatsApp: selectedColaborador.contato.WhatsApp,
          observacoes: selectedColaborador.contato.observacoes // Ensure contact notes are included
        },
        observacoes: selectedColaborador.observacoes // Ensure professional notes are included
      },
      estabelecimento: {
        _id: selectedEstabelecimento._id?.$oid || selectedEstabelecimento._id, // Ensure proper access to _id
        nome_fantasia: selectedEstabelecimento.nome_fantasia,
        Razao_Social: selectedEstabelecimento.Razao_Social, // Ensure correct capitalization
        cpf_cnpj: selectedEstabelecimento.cpf_cnpj,
        contato: {
          telefone_fixo: selectedEstabelecimento.contato.telefone_fixo,
          celular: selectedEstabelecimento.contato.celular,
          email: selectedEstabelecimento.contato.email,
          WhatsApp: selectedEstabelecimento.contato.WhatsApp,
          observacoes: selectedEstabelecimento.contato.observacoes // Ensure contact notes are included
        },
        endereco: {
          rua: selectedEstabelecimento.endereco.rua,
          numero: selectedEstabelecimento.endereco.numero,
          complemento: selectedEstabelecimento.endereco.complemento,
          bairro: selectedEstabelecimento.endereco.bairro,
          cidade: selectedEstabelecimento.endereco.cidade,
          estado: selectedEstabelecimento.endereco.estado,
          cep: selectedEstabelecimento.endereco.cep,
          latitude: selectedEstabelecimento.endereco.latitude,
          longitude: selectedEstabelecimento.endereco.longitude
        },
        observacoes: selectedEstabelecimento.observacoes // Ensure establishment notes are included
      }
    };


    if (mode === 'edit') {
      await VisitaServices.updateVisita(visita);
      navigate(`/Quadrodeatendimentos`);
    } else {

      await VisitaServices.createVisita(visita);
    }

    clearForm();
  };

  // Verifica se todos os produtos estão selecionados
  const areAllProdutosSelected = () =>
    filteredProdutos.length > 0 &&
    filteredProdutos.every(produto =>
      tempSelectedProdutos.some(p => p._id === produto._id)
    );

  // Seleciona ou desmarca todos os produtos
  const handleSelectAll = () => {
    if (!areAllProdutosSelected()) {
      const allProdutos = [...new Set([...tempSelectedProdutos, ...filteredProdutos])];
      setTempSelectedProdutos(allProdutos);
    } else {
      const remainingProdutos = tempSelectedProdutos.filter(
        (p) => !filteredProdutos.some((produto) => produto._id === p._id)
      );
      setTempSelectedProdutos(remainingProdutos);
    }
  };



  const clearForm = () => {
    setFormValues({
      descricao_visita: '',
      data: '',
      hora: '',
      repetir_dias: [],
      repetir_meses: 0,
    });
    setSelectedEstabelecimento(null);
    setSelectedColaborador(null);
    setSelectedClientes([]);
    setErro('');
  };

  const openEstabelecimentoModal = async () => {
    setIsEstabelecimentoModalOpen(true);
    const idrede = secureStorage.getItem("id_r");
    if (idrede) {
      await loadregistrosEstabelecimento(idrede);
    }
  };

  const closeEstabelecimentoModal = () => {
    setIsEstabelecimentoModalOpen(false);
  };

  const handleSelectEstabelecimento = (estabelecimento) => {
    setSelectedEstabelecimento(estabelecimento);
    closeEstabelecimentoModal();
  };

  const openColaboradorModal = async () => {
    setIsColaboradorModalOpen(true);
    const idrede = secureStorage.getItem("id_r");
    if (idrede) {
      await loadregistrosColaborador(idrede);
    }
  };

  const closeColaboradorModal = () => {
    setIsColaboradorModalOpen(false);
  };

  const handleSelectColaborador = (colaborador) => {
    setSelectedColaborador(colaborador);
    closeColaboradorModal();
  };

  const openClienteModal = async () => {
    setIsClienteModalOpen(true);
    const idrede = secureStorage.getItem("id_r");
    if (idrede) {
      await loadregistrosClientes(idrede);
    }
  };

  const closeClienteModal = () => {
    setIsClienteModalOpen(false);
  };

  const handleSelectCliente = (cliente) => {
    setSelectedClientes([...selectedClientes, { cliente, produtos: [] }]);
    closeClienteModal();
  };

  const openProdutoModal = async (index) => {
    
    setIsProdutoModalOpen(true);
    setCurrentClienteIndex(index);

    const idrede = secureStorage.getItem("id_r");
    

    if (idrede) {
      const cliente = selectedClientes[index]?.cliente;
      

      // Garantir que o ID seja extraído como string simples
      const id_Cliente =
        cliente?._id?.$oid || // MongoDB ObjectId no formato {$oid: "id"}
        cliente?._id || // MongoDB ObjectId como string
        cliente?.id?.$oid || // Outra variante de $oid
        cliente?.id; // ID como string simples

      

      if (id_Cliente) {
        try {
          
          await loadregistrosProdutos(idrede, id_Cliente.toString()); // Convertendo para string
          
        } catch (error) {
          console.error("Erro ao carregar produtos:", error);
          setErro("Erro ao buscar produtos para o cliente selecionado.");
        }
      } else {
        console.error("ID do Cliente não encontrado no cliente selecionado.");
        setErro("ID do cliente não encontrado. Verifique se o cliente foi corretamente selecionado.");
      }
    } else {
      console.error("ID da rede não encontrado.");
      setErro("ID da rede não encontrado.");
    }
  };






  const closeProdutoModal = () => {
    setIsProdutoModalOpen(false);
  };

  const handleSelectProduto = (produto) => {
    const updatedClientes = [...selectedClientes];
    updatedClientes[currentClienteIndex] = {
      ...updatedClientes[currentClienteIndex],
      produtos: [...updatedClientes[currentClienteIndex].produtos, {
        ...produto,
        gtin: produto.gtin || 'N/A',
        descricao: produto.description || 'N/A',
        marca: produto.brand?.name || 'N/A',
        thumbnail: produto.thumbnail || 'N/A', // Adiciona o campo thumbnail aqui, mas não será mostrado na interface
      }]
    };
    setSelectedClientes(updatedClientes);
    closeProdutoModal();
  };


  const handleRemoveCliente = (index) => {
    const updatedClientes = [...selectedClientes];
    updatedClientes.splice(index, 1);
    setSelectedClientes(updatedClientes);
  };

  const handleRemoveProduto = (clienteIndex, produtoIndex) => {
    const updatedClientes = [...selectedClientes];
    updatedClientes[clienteIndex].produtos.splice(produtoIndex, 1);
    setSelectedClientes(updatedClientes);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const confirmSelectedProdutos = () => {
    const updatedClientes = [...selectedClientes];
    updatedClientes[currentClienteIndex] = {
      ...updatedClientes[currentClienteIndex],
      produtos: [
        ...updatedClientes[currentClienteIndex].produtos,
        ...tempSelectedProdutos.map(produto => ({
          _id: produto._id?.$oid || produto._id,
          gtin: produto.gtin || 'N/A',
          descricao: produto.description || 'N/A',
          marca: produto.brand?.name || 'N/A',
          thumbnail: produto.thumbnail || 'N/A', // Adiciona o campo thumbnail aqui
        })),
      ],
    };
    setSelectedClientes(updatedClientes);
    setTempSelectedProdutos([]);
    closeProdutoModal();
  };




  const handleDelete = async () => {
    if (id) {
      await VisitaServices.deleteVisita(id);
      clearForm();
      navigate(`/Quadrodeatendimentos`);
      // Redirecionar ou atualizar o estado após a exclusão, conforme necessário
    } else {
      alert('ID do atendimento não encontrado para exclusão.');
    }
  };

  const openAtendimentoBaseModal = async () => {
    setIsModalOpen(true); // Isso deve abrir o modal
    setLoading(true);
    setError("");

    try {
      const registros = await AtendimentoBaseServices.getAtendimentobaseById();
      setCadastros(registros); // Atualiza os registros
     
    } catch (err) {
      console.error("Erro ao carregar cadastros:", err);
      setError("Erro ao carregar cadastros.");
    } finally {
      setLoading(false);
    }
  };

  const closeCadastroModal = () => {
    setIsModalOpen(false); // Fecha o modal
  };



  const handleSelectCadastro = (cadastro) => {
    if (!cadastro) {
      setError("Cadastro inválido. Tente novamente.");
      return;
    }


    // Atualiza o estado com os dados do estabelecimento
    setSelectedEstabelecimento({
      _id: cadastro.estabelecimento.id,
      nome_fantasia: cadastro.estabelecimento.nome_fantasia,
      Razao_Social: cadastro.estabelecimento.Razao_Social,
      cpf_cnpj: cadastro.estabelecimento.cpf_cnpj,
      contato: {
        telefone_fixo: cadastro.estabelecimento.contato.telefone_fixo || "Não informado",
        celular: cadastro.estabelecimento.contato.celular || "Não informado",
        WhatsApp: cadastro.estabelecimento.contato.WhatsApp || "Não informado",
        observacoes: cadastro.estabelecimento.contato.observacoes || "Sem observações",
      },
      endereco: {
        rua: cadastro.estabelecimento.endereco.rua || "",
        numero: cadastro.estabelecimento.endereco.numero || "",
        complemento: cadastro.estabelecimento.endereco.complemento || "",
        bairro: cadastro.estabelecimento.endereco.bairro || "",
        cidade: cadastro.estabelecimento.endereco.cidade || "",
        estado: cadastro.estabelecimento.endereco.estado || "",
        cep: cadastro.estabelecimento.endereco.cep || "",
      },
    });

    // Atualiza os clientes relacionados ao cadastro
    setSelectedClientes(
      cadastro.clientes.map((cliente) => ({
        cliente: {
          _id: cliente.id,
          nome: cliente.nome,
          cpf_cnpj: cliente.cpf_cnpj,
          endereco: cliente.endereco || {},
          contato: cliente.contato || {},
          observacoes: cliente.observacoes || "Sem observações",
        },
        produtos: (cliente.produtos || []).map((produto) => ({
          _id: produto.id,
          gtin: produto.gtin ,
          descricao: produto.descricao,
          marca: produto.marca,
          thumbnail: produto.thumbnail,
        })),
      }))
    );


    closeCadastroModal();
  };


  return (
    <S.Container>
      <Header />
      <S.BackButton onClick={() => navigate('/home')}>
        <MdArrowBack style={{ marginRight: '8px', fontSize: '20px' }} />
        Voltar
      </S.BackButton>
      <S.Form onSubmit={handleSubmit}>
        <S.Title>
          <FormattedMessage id="form.title_v" defaultMessage={mode === 'edit' ? "Editar Visita" : "Cadastro de Atendimento"} />
        </S.Title>
        <Tabs defaultIndex={0}>
          <S.StyledTabList>
            <S.StyledTab>
              <FormattedMessage id="tabs.estabelecimento" defaultMessage="Estabelecimento" />
            </S.StyledTab>
            <S.StyledTab>
              <FormattedMessage id="tabs.produto_cliente" defaultMessage="Produto/Cliente" />
            </S.StyledTab>
            <S.StyledTab>
              <FormattedMessage id="tabs.colaborador" defaultMessage="Colaborador" />
            </S.StyledTab>
            <S.StyledTab>
              <FormattedMessage id="tabs.agendamento" defaultMessage="Agendamento" />
            </S.StyledTab>
          </S.StyledTabList>


          <TabPanel>
            <S.ButtonGroup>
              <S.SmallButtonBuscar type="button" onClick={openEstabelecimentoModal}>
                <FormattedMessage id="form.buscar_cadastros" defaultMessage="Buscar Cadastros" />
              </S.SmallButtonBuscar>
            </S.ButtonGroup>
            {selectedEstabelecimento && (
              <>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.nome_fantasia" defaultMessage="Nome Fantasia:" />
                  </S.Label>
                  <S.LabelGray>{selectedEstabelecimento.nome_fantasia}</S.LabelGray>
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.razao_social" defaultMessage="Razão Social:" />
                  </S.Label>
                  <S.LabelGray>{selectedEstabelecimento.Razao_Social}</S.LabelGray>
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.endereco" defaultMessage="Endereço:" />
                  </S.Label>
                  <S.LabelGray>{`${selectedEstabelecimento.endereco.rua}, ${selectedEstabelecimento.endereco.numero}, ${selectedEstabelecimento.endereco.bairro}, ${selectedEstabelecimento.endereco.cidade}, ${selectedEstabelecimento.endereco.estado}, ${selectedEstabelecimento.endereco.cep}`}</S.LabelGray>
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.contato" defaultMessage="Contato:" />
                  </S.Label>
                  <S.LabelGray>{`Telefone: ${selectedEstabelecimento.contato.telefone_fixo}, Celular: ${selectedEstabelecimento.contato.celular}, Email: ${selectedEstabelecimento.contato.email}, WhatsApp: ${selectedEstabelecimento.contato.WhatsApp}`}</S.LabelGray>
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.observacoes" defaultMessage="Observações:" />
                  </S.Label>
                  <S.LabelGray>{selectedEstabelecimento.contato.observacoes}</S.LabelGray>
                </S.InputGroup>
              </>
            )}
            <S.StyledModal
              isOpen={isEstabelecimentoModalOpen}
              onRequestClose={closeEstabelecimentoModal}
              contentLabel="Buscar Estabelecimentos"
              ariaHideApp={false}
            >
              <S.ModalContent>
                <S.SmallButton onClick={closeEstabelecimentoModal}>Fechar</S.SmallButton>
                <S.Input type="text" placeholder="Buscar Estabelecimentos" value={searchTerm} onChange={handleSearchChange} />
                {loadingEstabelecimento && <p>Carregando...</p>}
                {errorEstabelecimento && <p>{errorEstabelecimento}</p>}
                <S.StyledTable>
                  <thead>
                    <tr>
                      <th>Nome Fantasia</th>
                      <th>Razão Social</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredEstabelecimentos.map((estabelecimento, index) => (
                      <tr key={index}>
                        <td>{estabelecimento.nome_fantasia}</td>
                        <td>{estabelecimento.Razao_Social}</td>
                        <td>
                          <S.SmallButton type="button" onClick={() => handleSelectEstabelecimento(estabelecimento)}>
                            Selecionar
                          </S.SmallButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </S.StyledTable>
              </S.ModalContent>
            </S.StyledModal>
          </TabPanel>

          <TabPanel>
            <S.ButtonGroup>
              <S.SmallButtonBuscar type="button" onClick={openClienteModal}>
                <FormattedMessage id="form.buscar_cliente" defaultMessage="Buscar Cliente" />
              </S.SmallButtonBuscar>
            </S.ButtonGroup>

            {selectedClientes.map((clienteData, index) => (
              <S.ClienteContainer key={index}>
                <S.ClienteHeader>
                  <h3>
                    {clienteData.cliente.nome}{" "}
                    <S.ButtonRemove type="button" onClick={() => handleRemoveCliente(index)}>X</S.ButtonRemove>
                  </h3>
                  <S.SmallButton type="button" onClick={() => openProdutoModal(index)}>
                    <FormattedMessage id="form.buscar_produto" defaultMessage="Buscar Produto" />
                  </S.SmallButton>
                </S.ClienteHeader>

                {/* Botão para alternar visibilidade da lista específica */}
                <S.SmallButton type="button" onClick={() => toggleListVisibility(index)}>
                  {visibleLists[index] ? 'Ocultar ' : 'Exibir '}
                </S.SmallButton>

                {/* Exibição condicional da lista específica */}
                {visibleLists[index] && (
                  <S.StyledTable>
                    <thead>
                      <tr>
                        <th>Marca</th>
                        <th>Descrição</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clienteData.produtos.map((produto, produtoIndex) => (
                        <tr key={produtoIndex}>
                          <td>{produto.marca || 'N/A'}</td>
                          <td>{produto.descricao || 'N/A'}</td>
                          <td>
                            <S.SmallButton
                              type="button"
                              onClick={() => handleRemoveProduto(index, produtoIndex)}
                            >
                              X
                            </S.SmallButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </S.StyledTable>
                )}
              </S.ClienteContainer>
            ))}

            {/* Modal de Clientes */}
            <S.StyledModal
              isOpen={isClienteModalOpen}
              onRequestClose={closeClienteModal}
              contentLabel="Buscar Clientes"
              ariaHideApp={false}
            >
              <S.ModalContent>
                <S.SmallButton onClick={closeClienteModal}>Fechar</S.SmallButton>
                <S.Input
                  type="text"
                  placeholder="Buscar Clientes"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                {loadingClientes && <p>Carregando...</p>}
                {errorClientes && <p>{errorClientes}</p>}
                <S.StyledTable>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>CPF/CNPJ</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredClientes.map((cliente, index) => (
                      <tr key={index}>
                        <td>{cliente.nome}</td>
                        <td>{cliente.cpf_cnpj}</td>
                        <td>
                          <S.SmallButton type="button" onClick={() => handleSelectCliente(cliente)}>
                            Selecionar
                          </S.SmallButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </S.StyledTable>
              </S.ModalContent>
            </S.StyledModal>

            {/* Modal de Produtos */}
            <S.StyledModal
              isOpen={isProdutoModalOpen}
              onRequestClose={closeProdutoModal}
              contentLabel="Buscar Produtos"
              ariaHideApp={false}
            >
              <S.ModalContent>
                <S.SmallButton onClick={closeProdutoModal}>Fechar</S.SmallButton>
                <S.Input
                  type="text"
                  placeholder="Buscar Produtos"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                {loadingProdutos && <p>Carregando...</p>}
                {errorProdutos && <p>{errorProdutos}</p>}
                <S.StyledTable>
                  <thead>
                    <tr>
                      <th>Marca</th>
                      <th>Descrição</th>
                      <th>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <span>Ações</span>
                          <S.SmallButton
                            type="button"
                            onClick={() => handleSelectAll(!areAllProdutosSelected())}
                            style={{
                              fontSize: '12px', // Ajusta o tamanho do texto
                              padding: '4px 8px', // Reduz o preenchimento interno
                              marginTop: '8px', // Adiciona espaço entre o texto "Ações" e o botão
                            }}
                          >
                            {areAllProdutosSelected() ? 'Desmarcar Todos' : 'Selecionar Todos'}
                          </S.SmallButton>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredProdutos.map((produto, index) => (
                      <tr key={index}>
                        <td>{produto.brand?.name || 'N/A'}</td>
                        <td>{produto.description || 'N/A'}</td>
                        <td>
                          <S.SmallButton
                            type="button"
                            onClick={() => handleTempSelectProduto(produto)}
                            selected={tempSelectedProdutos.some((p) => p._id === produto._id)}
                          >
                            {tempSelectedProdutos.some((p) => p._id === produto._id)
                              ? 'Desmarcar'
                              : 'Selecionar'}
                          </S.SmallButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </S.StyledTable>
                <S.ButtonGroup>
                  <S.SmallButton type="button" onClick={confirmSelectedProdutos}>
                    Confirmar Seleção
                  </S.SmallButton>
                </S.ButtonGroup>
              </S.ModalContent>
            </S.StyledModal>

          </TabPanel>


          <TabPanel>
            <S.ButtonGroup>
              <S.SmallButtonBuscar type="button" onClick={openColaboradorModal}>
                <FormattedMessage id="form.buscar_colaborador" defaultMessage="Buscar Colaborador" />
              </S.SmallButtonBuscar>
            </S.ButtonGroup>
            {selectedColaborador && (
              <>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.nome" defaultMessage="Nome:" />
                  </S.Label>
                  <S.LabelGray>{selectedColaborador.nome}</S.LabelGray>
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.sobrenome" defaultMessage="Sobrenome:" />
                  </S.Label>
                  <S.LabelGray>{selectedColaborador.sobrenome}</S.LabelGray>
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.contato" defaultMessage="Contato:" />
                  </S.Label>
                  <S.LabelGray>{`Telefone: ${selectedColaborador.contato.telefone_fixo}, Celular: ${selectedColaborador.contato.celular}, Email: ${selectedColaborador.contato.email}, WhatsApp: ${selectedColaborador.contato.WhatsApp}`}</S.LabelGray>
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.dados_profissionais" defaultMessage="Dados Profissionais:" />
                  </S.Label>
                  <S.LabelGray>{`Cargo: ${selectedColaborador.dadosprofissionais.cargo}, Departamento: ${selectedColaborador.dadosprofissionais.departamento}`}</S.LabelGray>
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.observacoes" defaultMessage="Observações:" />
                  </S.Label>
                  <S.LabelGray>{selectedColaborador.contato.observacoes}</S.LabelGray>
                </S.InputGroup>
              </>
            )}
            <S.StyledModal
              isOpen={isColaboradorModalOpen}
              onRequestClose={closeColaboradorModal}
              contentLabel="Buscar Colaboradores"
              ariaHideApp={false}
            >
              <S.ModalContent>
                <S.SmallButton onClick={closeColaboradorModal}>Fechar</S.SmallButton>
                <S.Input type="text" placeholder="Buscar Colaboradores" value={searchTerm} onChange={handleSearchChange} />
                {loadingColaborador && <p>Carregando...</p>}
                {errorColaborador && <p>{errorColaborador}</p>}
                <S.StyledTable>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Sobrenome</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredColaboradores.map((colaborador, index) => (
                      <tr key={index}>
                        <td>{colaborador.nome}</td>
                        <td>{colaborador.sobrenome}</td>
                        <td>
                          <S.SmallButton type="button" onClick={() => handleSelectColaborador(colaborador)}>
                            Selecionar
                          </S.SmallButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </S.StyledTable>
              </S.ModalContent>
            </S.StyledModal>
          </TabPanel>
          <TabPanel>
            <S.InputGroup>
              <S.Label>
                <FormattedMessage id="form.descricao_visita" defaultMessage="Descrição do Atendimento:" />
              </S.Label>
              <S.Textarea name="descricao_visita" value={formValues.descricao_visita} onChange={handleChange} />
            </S.InputGroup>
            <S.DateTimeGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.data" defaultMessage="Data:" />
                </S.Label>
                <S.Input
                  type="date"
                  name="data"
                  value={formValues.data}
                  onChange={handleChange}
                />
              </S.InputGroup>

              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.hora" defaultMessage="Hora:" />
                </S.Label>
                <S.Input type="time" name="hora" value={formValues.hora} onChange={handleChange} />
              </S.InputGroup>
            </S.DateTimeGroup>
            <S.CheckboxGroup>
              <S.Label>Repetir atendimentos:</S.Label>
              <div>
                <label><input type="checkbox" name="segunda-feira" onChange={handleChange} checked={formValues.repetir_dias.includes('segunda-feira')} /> Segunda-feira</label>
                <label><input type="checkbox" name="terça-feira" onChange={handleChange} checked={formValues.repetir_dias.includes('terça-feira')} /> Terça-feira</label>
                <label><input type="checkbox" name="quarta-feira" onChange={handleChange} checked={formValues.repetir_dias.includes('quarta-feira')} /> Quarta-feira</label>
                <label><input type="checkbox" name="quinta-feira" onChange={handleChange} checked={formValues.repetir_dias.includes('quinta-feira')} /> Quinta-feira</label>
                <label><input type="checkbox" name="sexta-feira" onChange={handleChange} checked={formValues.repetir_dias.includes('sexta-feira')} /> Sexta-feira</label>
                <label><input type="checkbox" name="sábado" onChange={handleChange} checked={formValues.repetir_dias.includes('sábado')} /> Sábado</label>
                <label><input type="checkbox" name="domingo" onChange={handleChange} checked={formValues.repetir_dias.includes('domingo')} /> Domingo</label>
              </div>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.meses" defaultMessage="Quantidade de meses (até 1 ano):" />
                </S.Label>
                <S.Input type="number" name="repetir_meses" min="0" max="12" value={formValues.repetir_meses} onChange={handleChange} />
              </S.InputGroup>
            </S.CheckboxGroup>
          </TabPanel>
        </Tabs>
        <S.StyledModal
          isOpen={isModalOpen}
          onRequestClose={closeCadastroModal}
          contentLabel="Buscar Cadastros"
          ariaHideApp={false}
        >
          <S.ModalContent>
            <S.SmallButton onClick={closeCadastroModal}>Fechar</S.SmallButton>
            <S.Input
              type="text"
              placeholder="Buscar Cadastros"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {loading ? (
              <p>Carregando...</p>
            ) : error ? (
              <p>{error}</p>
            ) : cadastros.length > 0 ? (
              <S.StyledTable>
                <thead>
                  <tr>
                    <th>Nome Fantasia</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {cadastros
                    .filter((cadastro) =>
                      cadastro.estabelecimento?.nome_fantasia
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .map((cadastro, index) => (
                      <tr key={index}>
                        <td>
                          {cadastro.estabelecimento?.nome_fantasia || "Não disponível"}
                        </td>
                        <td>
                          <S.SmallButton
                            type="button"
                            onClick={() => handleSelectCadastro(cadastro)}
                          >
                            Selecionar
                          </S.SmallButton>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </S.StyledTable>
            ) : (
              <p>Nenhum cadastro encontrado.</p>
            )}
          </S.ModalContent>
        </S.StyledModal>

        {erro && <S.Erro>{erro}</S.Erro>}
        <S.ButtonGroup>
          {mode === 'edit' ? secureStorage.getItem("TipoVisita") !== 1 && (
            <S.Button type="submit">
              <FormattedMessage id="form.salvar" defaultMessage="Salvar" />
            </S.Button>
          ) : (
            <S.Button type="submit">
              <FormattedMessage id="form.cadastrar" defaultMessage="Cadastrar" />
            </S.Button>
          )}
          {mode === 'edit' && secureStorage.getItem("TipoVisita") === 3 && (
            <S.Button type="button" onClick={handleDelete}>
              <FormattedMessage id="form.deletar" defaultMessage="Deletar" />
            </S.Button>
          )}
          {secureStorage.getItem("TipoVisita") !== 1 && (
            <S.Button type="button" onClick={clearForm}>
              <FormattedMessage id="form.novo" defaultMessage="Novo" />
            </S.Button>

          )}
          <S.Button type="button" onClick={openAtendimentoBaseModal}>
            <FormattedMessage id="form.buscar_AtendimentoBase" defaultMessage="Atendimento Base" />
          </S.Button>
        </S.ButtonGroup>
      </S.Form>
      <Footer />
    </S.Container>
  );
}

export default Visita;
